import { Switch } from "@material-ui/core";
import { DatePicker, Result,Drawer } from "antd";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import AppButton from "../../components/AppButton/AppButton";
import Navbar from "../../components/Navbar/Navbar";
import ApiService from "../../services/ApiService";
import { handleErrors, showNotification } from "../../services/HelperMethods";

import "./SettingsPayment.scss";
import Dot from "../../components/Dot/Dot";
import AppLoading from "../../components/AppLoading/AppLoading";
import { Table } from "react-bootstrap";
// import './SettingsPayment.scss';
export default function SettingsPayment() {

  const apiService = new ApiService();

  const [buildingDetails, setBuildingDetails] = useState(null as any);
  const [isManagementEnable, setIsManagementEnable] = useState(false);
  const [startFrequency, setStartFrequency] = useState(1);
  const [startOnDate, setStartOnDate] = useState(moment());
  const [chargePerSqf, setChargesPerSqf] = useState(0);
  const [tax, setTax] = useState(0);
  const [otherCharges, setOtherCharges] = useState(0);
  const [lateFeeDue, setLateFeeDue] = useState(0);
  const [managementFeesLateFeeType, setManagementFeesLateFeeType] = useState(0);
  const [managementFeesLateFeeAmount, setManagementFeesLateFeeAmount] = useState(0);
  const [isLateFeeEnable, setIsLateFeeEnable] = useState(false);
  const [managementFeesReminderBeforeDue, setManagementFeesReminderBeforeDue] = useState(0);
  const [managementFeesIsReminderActive, setManagementFeesIsReminderActive] = useState(false);
  const [MDRPaidBy, setMDRPaidBy] = useState(0);
  const [defaultDueDays, setDefaultDueDays] = useState(1)
  const [isLoading, setIsLoading] = useState(false);
  const [amenityBookingInvoiceTypeId, setAmenityBookingInvoiceTypeId] = useState(0);
  const [managementFeesInvoiceTypeId, setManagementFeesInvoiceTypeId] = useState(0);
  const [invoiceTypes, setInvoiceTypes] = useState([] as any)
  const [expenseTypes,setExpenseTypes] = useState([] as any)
  const [visible, setVisible] = useState(false);
  const [name,setName] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [showResults,setShowResults]  = useState(false);
  const [expenseTypeDrawerVisible,setExpenseTypeDrawerVisible] = useState(false);
  const [apartments, setApartments] = useState([] as any);
  const [expenseSections, setExpenseSections] = useState(0);
  const [newExpenseType, setNewExpenseType] = useState("");
  const [selectedInvoice,setSelectedInvoice] = useState<boolean>(false);
  const [selectedExpense,setSelectedExpense] = useState<boolean>(false);
  const [expenseId, setExpenseId] = useState("");
  const [id, setId] = useState("");
  useEffect(() => {
    if (buildingDetails) {

      setIsManagementEnable(buildingDetails.isManagementFeeEnable);

      if (buildingDetails.managementFeesRecurringDay) {
        setStartOnDate(moment.utc(buildingDetails.managementFeesRecurringDay).local());
      }

      setStartFrequency(buildingDetails.managementFeesRecurringStatus);
      setChargesPerSqf(buildingDetails.managementFeesChargePerFt);
      setTax(buildingDetails.managementFeesTaxPercentage);
      setOtherCharges(buildingDetails.managementFeesOtherChargesPercentage);
      setIsLateFeeEnable(buildingDetails.managementFeesIsLateFeesEnable);
      setLateFeeDue(buildingDetails.managementFeesLateFeeDueDate);
      setManagementFeesLateFeeAmount(buildingDetails.managementFeesLateFeeAmount);
      setManagementFeesIsReminderActive(buildingDetails.managementFeesIsReminderActive);
      setManagementFeesReminderBeforeDue(buildingDetails.managementFeesReminderBeforeDue);
      setMDRPaidBy(buildingDetails?.mdrPaidBy);
      setDefaultDueDays(buildingDetails?.defaultDueDate)
      setAmenityBookingInvoiceTypeId(buildingDetails?.amenityBookingInvoiceTypeId);
      setManagementFeesInvoiceTypeId(buildingDetails?.managementFeeInvoiceTypeId);
      setManagementFeesLateFeeType(buildingDetails.managementFeesLateFeeType);
    }
  }, [buildingDetails]);

  useEffect(() => {
    getDetails();
    getExpenseTypes();
  }, []);

  function checkDefaultDueDays(defaultDays) {
    if (defaultDays < 1) {
      showNotification("Min Default Due Days is 1", "Min Default Due Days is 1", "error");
      setDefaultDueDays(1)
    } else {
      setDefaultDueDays(defaultDays) 
    }
  }

  async function getDetails() {

    try {
      let data = (await apiService.getBuildingDetails()) as any;

      data = data?.data;

      setBuildingDetails(data?.building);
    } catch (error) {
      handleErrors(error);
    }

    try {

      await apiService.getInvoiceTypes().then((result) => {
        let data = result.data
        setInvoiceTypes(data?.expenseTypes);
      })
    } catch (error) {
      handleErrors(error)
    }
  }

  async function createNewInvoiceType() {

    if (name == "") {
        showNotification("Enter New Invoice Type", "Enter New Invoice", "error")
        return;
    }

    if(selectedInvoice){
      const isDuplicate = invoiceTypes.some(
        (invoiceType) => invoiceType.expense.toLowerCase() === name.toLowerCase()
        );
    
      if (isDuplicate) {
        showNotification("Error", "The invoice type already exists", "error");
        setName("");
        setIsChecked(false);
        return;
      }
      let data = {
        InvoiceTypeId:id,
        Type:name
      }

      apiService
      .UpdateInvoiceType(data)
      .then((result)=>{
        let data = result.data
  
        if (data?.isSuccessful) {
            showNotification("Updated Invoice Type", "Updated Invoice Type", "success");
            getDetails();
            setShowResults(false);
            setName("");
        }else{
          showNotification("Unexpected Error", "Unexpected Error", "error");
        }
      })
      .catch((err) => {
        showNotification("Unexpected Error", "Unexpected Error", "error");
      });
      setIsLoading(false);
      return;
  
    }
    //Check if the name already exists in the invoiceTypes
    const isDuplicate = invoiceTypes.some(
    (invoiceType) => invoiceType.expense.toLowerCase() === name.toLowerCase()
    );

  if (isDuplicate) {
    showNotification("Error", "The invoice type already exists", "error");
    setName("");
    setIsChecked(false);
    return;
  }

    let data = {
        ExpenseType: name,
        Status:true
    }
    
    await apiService.addInvoiceType(data).then((result) => {

        let data = result.data

        if (data?.isSuccessful) {
            showNotification("Added New Invoice Type", "Added New Invoice Type", "success");
            setName("");
            setIsChecked(false);
            getDetails();
        }
    });
  }
  function getExpenseTypes() {

    setIsLoading(true);

    apiService.getExpenseTypes().then((result) => {

        let data = result.data      
        setExpenseTypes(data?.expenseTypes)

        let apt = data?.apartments;

        apt.sort(function (a, b) {
            if (a.doorNumber < b.doorNumber) { return -1; }
            if (a.doorNumber > b.doorNumber) { return 1; }
            return 0;
        })

        apt.sort(function (a, b) {
            return a.floor - b.floor;
        })

        setApartments(apt)

        setExpenseSections(data?.sections)
    })

    setIsLoading(false);
  }
  function createNewExpense() {
    if (newExpenseType.trim() === "") {
      showNotification("Error", "Enter Expense Type", "error");
      return;
    }
  
    setIsLoading(true);
  
    if (selectedExpense) {
      // Update existing expense type
      let data = {
        ExpenseTypeId: expenseId,
        Type: newExpenseType
      }
  
      apiService.UpdateExpenseType(data)
        .then((result) => {
          let data = result.data;
  
          if (data?.isSuccessful) {
            showNotification("Updated Expense Type", "Updated Expense Type", "success");
            getExpenseTypes();
            setNewExpenseType("");
            setSelectedExpense(false);
            setExpenseId("");
          } else {
            showNotification("Unexpected Error", "Unexpected Error", "error");
          }
        })
        .catch((err) => {
          showNotification("Unexpected Error", "Unexpected Error", "error");
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      // Create new expense type
      const isDuplicate = expenseTypes.some(
        (expenseType) => expenseType.expense.toLowerCase() === newExpenseType.toLowerCase()
      );
  
      if (isDuplicate) {
        showNotification("Error", "The expense type already exists", "error");
        setIsLoading(false);
        return;
      }
  
      let data = {
        ExpenseType: newExpenseType,
        Status: true
      }
  
      apiService.addExpenseType(data).then((result) => {
        let data = result.data;
  
        if (data?.isSuccessful) {
          setNewExpenseType("");
          getExpenseTypes();
          showNotification("New Expense Type Added", "New Expense Type Added", "success");
        } else {
          showNotification("Error", "Error Adding Expense Type", "error");
        }
      }).finally(() => {
        setIsLoading(false);
      });
    }
  }

  async function handleInvoiceTypeStatusChange(invoiceType){
    const invoiceTypeId = invoiceType.id;
    setIsLoading(true);
    const updatedStatus = !invoiceType.status;

    let data = {
      InvoiceTypeId:invoiceTypeId,
      Status:updatedStatus
    }
    
    
    try {
      const response = await apiService.updateInvoiceTypeStatus(data);
      if (response.data.isSuccessful) {
        setInvoiceTypes(prevInvoiceTypes =>
          prevInvoiceTypes.map(invoice =>
            invoice.id === invoiceTypeId ? { ...invoice, status: updatedStatus } : invoice
          )
        );
        showNotification("Invoice Type Updated", "Invoice Type Status Updated", "success");
      } else {
        showNotification("Error", response.data.error.errorMessage, "error");
      }
    } catch (error) {
      handleErrors(error);
    }finally {
      setIsLoading(false);
    }
  }
  
  async function handleExpenseTypeStatusChange(expenseType){
    const expenseTypeId = expenseType.id;
    setIsLoading(true);
    const updatedStatus = !expenseType.status;

    let data = {
      expenseTypeId,
      Status:updatedStatus
    }
    try {
      const response = await apiService.updateExpenseTypeStatus(data);
      if (response.data.isSuccessful) {
        setExpenseTypes(prevExpenseTypes =>
          prevExpenseTypes.map(expense =>
            expense.id === expenseTypeId ? { ...expense, status: updatedStatus } : expense
          )
        );
        showNotification("Expense Type Updated", "Expense Type Status Updated", "success");
      } else {
        showNotification("Error", response.data.error.errorMessage, "error");
      }
    } catch (error) {
      handleErrors(error);
    }finally {
      setIsLoading(false);
    }
  }

  async function handleSave() {

    setIsLoading(true);

    try {
      await apiService.saveManagementFees({
        IsManagementFeeEnable: isManagementEnable,
        ManagementFeesRecurringFrequency: startFrequency,
        ManagementFeesRecurringDay: startOnDate,
        ManagementFeesChargePerFt: chargePerSqf,
        ManagementFeesTaxPercentage: tax,
        ManagementOtherChargesPercentage: otherCharges,
        ManagementFeesIsLateFeesEnable: isLateFeeEnable,
        ManagementFeesLateFeeDueDate: lateFeeDue,
        ManagementFeesLateFeeAmount: managementFeesLateFeeAmount,
        ManagementFeesLateFeeType: managementFeesLateFeeType,
        ManagementFeesIsReminderActive: managementFeesIsReminderActive,
        ManagementFeesReminderBeforeDue: managementFeesReminderBeforeDue,
        MDRPaidBy: MDRPaidBy,
        DefaultDueDays: defaultDueDays,
        amenityBookingInvoiceTypeId: amenityBookingInvoiceTypeId,
        managementFeesInvoiceTypeId: managementFeesInvoiceTypeId
      }).then((result) => {

        let data = result.data

        if (data?.isSuccess) {
          showNotification("Successfully Saved", "Successfully Saved", "success")
        }
      });

      getDetails();
    } catch (error) {
      handleErrors(error);
    }

    setIsLoading(false);
  }

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  const handleOnChange = ()=>{
    setIsChecked(!isChecked);
  }
  

  const showExpenseTypeDrawer = ()=>{
    setExpenseTypeDrawerVisible(true);
  } 
  const closeExpenseTypeDrawer = ()=>{
    setExpenseTypeDrawerVisible(false);
  }

  return (
    <div className="body settings payments">
      <h2>Settings</h2>

      <Navbar hideSettings={true} hideAdd={true} hideFilter={true}>
        <Link to="/settings/payment" className="active">Payments</Link>
        <Link to="/settings/untitled" >Property</Link>
        <Link to="/settings/support" >Support Tickets</Link>
      </Navbar>

      <div className="white-box">
        <div>
          <div className="row">
            <div className="col-auto pr-0">
              <div className="icon">
                <i className="fas fa-money-check"></i>
              </div>
            </div>
            <div className="col-auto">
              <div className="title">Settlement Account</div>
              <p className="description">
                Set up your settlements account to receive payments out of the
                system
              </p>
            </div>
          </div>

          <div className="white-box-inner mt-3" style={{ display: "flex", alignItems: "center" }}>
            {getLogoUI(buildingDetails?.bankName)}
            <div>
              <div>
                <span style={{ fontWeight: 500 }}>Bank Account</span>
              </div>
              <div style={{ opacity: 0.8 }}>{buildingDetails?.bankName}</div>
              <div style={{ opacity: 0.8 }}>{buildingDetails?.bankAccountNumber}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="white-box mt-4">

        <div className="row" style={{ alignItems: 'center' }}>
          <div className="col-auto pr-0">
            <div className="icon">
              <i className="fas fa-receipt"></i>
            </div>
          </div>
          <div className="col-auto">
            <div className="title">Fee Generation &amp; Reminders</div>
          </div>
        </div>

        <div className="white-box-inner">
          <div className="sub-title">Management Fees</div>
          <label>{isManagementEnable ? "Enabled" : "Disabled"}</label>
          <Switch checked={isManagementEnable} onChange={(e, isToggle) => { setIsManagementEnable(isToggle); }}
            inputProps={{ "aria-label": "primary checkbox" }} />
          {isManagementEnable
            ? (<div>
              <div className="row">
                <div className="col-auto">
                  <select onChange={(e) => { setStartFrequency(parseInt(e.target.value)); }} value={startFrequency}
                    className="form-control input-lbl">
                    <option value={1}>Monthly</option>
                    <option value={3}>Quarterly</option>
                    <option value={4}>Annually</option>
                  </select>
                </div>
                {startFrequency != 4
                  ? (<Fragment />)
                  : (<div className="col-auto">
                    <DatePicker className="form-control" value={startOnDate} picker={"date"}
                      onChange={(d) => { if (d) { setStartOnDate(d); } }} />
                  </div>)
                }
              </div>
              <div className="mt-3">
                <div>
                  <div className="row align-items-center mt-1">
                    <div className="col-auto">
                      <label>Per Sqt.ft</label>
                    </div>
                    <div className="col-auto">
                      <NumberFormat suffix=" LKR" thousandSeparator={true} value={chargePerSqf} min={0}
                        className="form-control" onValueChange={(e) => { setChargesPerSqf(parseFloat(e.value)); }} />
                    </div>
                  </div>
                </div>

                <div>
                  <div className="row align-items-center mt-1">
                    <div className="col-auto">
                      <label>Service Charge</label>
                    </div>
                    <div className="col-auto">
                      <NumberFormat suffix=" %" className="form-control" value={otherCharges} min={0}
                        onChange={(e) => { setOtherCharges(parseFloat(e.target.value)); }} />
                    </div>
                  </div>
                </div>

                <div>
                  <div className="row align-items-center mt-1">
                    <div className="col-auto">
                      <label>VAT/NBT</label>
                    </div>
                    <div className="col-auto">
                      <NumberFormat suffix=" %" className="form-control" value={tax} min={0}
                        onChange={(e) => { setTax(parseFloat(e.target.value)); }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>)
            : (<Fragment />)
          }
          <div className="mt-3">
            <div className="sub-title">Late Fee<div>
              <label>{isLateFeeEnable ? "Enabled" : "Disabled"}</label>
              <Switch checked={isLateFeeEnable} onChange={(e, isToggle) => { setIsLateFeeEnable(isToggle); }} />
            </div>
              {isLateFeeEnable
                ? (<div>
                  <div>
                    <div className="row align-items-center mt-1">
                      <div className="col-auto">
                        <label>From Due Date</label>
                      </div>
                      <div className="col-auto pr-0">
                        <input className="form-control" value={lateFeeDue} type="number" min={1}
                          onChange={(e) => {
                            setLateFeeDue(parseInt(e.target.value));
                          }} />
                      </div>
                      <div className="col-auto pl-1">Days After Due Date</div>
                    </div>
                  </div>
                  <div>
                    <div className="row align-items-center mt-1">
                      <div className="col-auto">
                        <select className="form-control input-lbl"
                          onChange={(e) => { setManagementFeesLateFeeType(parseInt(e.target.value)); }}>
                          <option value={0}>Fixed Amount</option>
                          <option value={1}>Percentage</option>
                        </select>
                      </div>
                      <div className="col-auto pr-0">
                        <NumberFormat thousandSeparator={managementFeesLateFeeType == 0 ? true : false} className="form-control"
                          suffix={managementFeesLateFeeType == 0 ? " LKR" : " %"} value={managementFeesLateFeeAmount}
                          onChange={(e) => { setManagementFeesLateFeeAmount(parseFloat(e.target.value)); }} />
                      </div>
                      <div className="col-auto pl-1">Per Day</div>
                    </div>
                  </div>
                </div>)
                : (<Fragment />)
              }
              <div className="mt-3">
                <div className="row align-items-center">
                  <div className="col-auto" style={{ minWidth: 150 }}>
                    <div className="sub-title">Reminders</div>
                    <div>
                      <label>{managementFeesIsReminderActive ? "Enabled" : "Disabled"}</label>
                      <Switch checked={managementFeesIsReminderActive}
                        onChange={(e, isToggle) => { setManagementFeesIsReminderActive(isToggle); }} />
                    </div>
                  </div>
                </div>
                {managementFeesIsReminderActive
                  ? (<div className="row align-items-center mt-1">
                    <div className="col-auto">
                      <label>Before Due Date</label>
                    </div>
                    <div className="col-auto pr-0">
                      <input type="number" min={1} value={managementFeesReminderBeforeDue} className="form-control"
                        onChange={(e) => { setManagementFeesReminderBeforeDue(parseInt(e.target.value)); }} />
                    </div>
                    <div className="col-auto pl-1">Days</div>
                  </div>)
                  : (<Fragment />)
                }
              </div>

              <div className="mt-3">
                <div className="row align-items-center">
                  <div className="col-auto">
                    <div className="sub-title">Pays MDR</div>
                    <div className="row">
                      <div className="col-auto">
                        <select onChange={(e) => { setMDRPaidBy(parseInt(e.target.value)); }} value={MDRPaidBy}
                          className="form-control input-lbl">
                          <option value={0}>Management</option>
                          <option value={1}>Resident</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-3">
                <div className="row align-items-center mt-1">
                  <div className="col-auto">
                    <label>Default Due Days</label>
                  </div>
                  <div className="col-auto pr-0">
                    <NumberFormat thousandSeparator={false} className="form-control" value={defaultDueDays} min={1}
                      onChange={(e) => { checkDefaultDueDays(parseFloat(e.target.value)); }} />
                  </div>
                  <div className="col-auto pl-1">Days</div>
                </div>
              </div>
              <div className="mt-3">
                <div className="row align-items-center justify-content-start">
                  <div className="col-auto pr-0" style={{ minWidth: 200 }}>
                    <div className="d-flex">
                      <div className="mr-3">
                      <AppButton isLoading={isLoading} onClick={showDrawer}>Add Invoice Type</AppButton>
                      </div>
                      <div>
                      <AppButton isLoading={isLoading} onClick={showExpenseTypeDrawer}>Add Expense Type</AppButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Drawer
                      title=" Add Invoice Type"
                      placement="right"
                      closable={false}
                      onClose={onClose}
                      visible={visible}
                      key="right"
              >
                <div className="white-box mt-4">
          {isLoading ? (
            <AppLoading isLoading={isLoading} />
          ) : (
            <div className="white-box-inner">
              <div>
                <div className="row align-items-center mt-1">
                  <div className="col-auto">
                    <label>Invoice Type</label>
                  </div>
                  <div className="d-flex align-items-center">
                  <div className="col-auto">
                    <input
                      className="form-control"
                      placeholder="Add Invoice Type"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                  <AppButton isLoading={isLoading} onClick={createNewInvoiceType}>
                      Save
                    </AppButton>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          )}
                </div>

          <div className="white-box mt-4">
          {isLoading ? (
            <AppLoading isLoading={isLoading} />
          ) : (
            <div className="white-box-inner">
              <div>
                <div>
                  <Table responsive="lg" borderless={true} id="support-tickets-table">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceTypes.map((invoice,index) => {
                        return (
                          <tr
                            className="cursor-pointer"
                            key={index}
                            onClick={() => {
                              setName(invoice.expense);
                              setSelectedInvoice(true);
                              setId(invoice.id);
                              setIsChecked(invoice?.status === true);
                              setShowResults(true);
                            }}
                          >
                            <td>{invoice?.expense}</td>
                            <td style={{ textAlign: 'left' }}>
                              {invoice?.status === true ? (
                                <>
                                  <Dot
                                    color={'green'}
                                    style={{
                                      marginRight: '0.5rem',
                                      color: '#E8A454',
                                    }}
                                  />
                                  {'Active'}
                                </>
                              ) : null}

                              {invoice?.status === false ? (
                                <>
                                  <Dot
                                    color={'red'}
                                    style={{
                                      marginRight: '0.5rem',
                                      color: '#E8A454',
                                    }}
                                  />
                                  {'Inactive'}
                                </>
                              ) : null}
                            </td>
                            <td>  
                              {isLoading?(
                                <AppLoading isLoading={isLoading} />
                              ):(
                                <AppButton style={{
                                   flex:1,
                                   margin: '0 5px', 
                                   marginBottom: 2, 
                                   background:invoice.status ? 'red' : 'green'
                                   }}
                                   onClick={()=>handleInvoiceTypeStatusChange(invoice)}>
                                  {invoice.status ? 'Inactive' : 'Active'}
                                </AppButton>)}                   
                                
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          )}
            </div>
              </Drawer>
              <Drawer
                title=" Add Expense Type"
                placement="right"
                closable={false}
                onClose={closeExpenseTypeDrawer}
                visible={expenseTypeDrawerVisible}
                key="expense-type-drawer"
              >
          <div className="white-box mt-4">
          {isLoading ? (
            <AppLoading isLoading={isLoading} />
          ) : (
            <div className="white-box-inner">
              <div>
                <div className="row align-items-center mt-1">
                  <div className="col-auto">
                    <label>Expense Type</label>
                  </div>
                  <div className="d-flex align-items-center">
                  <div className="col-auto">
                    <input
                      className="form-control"
                      placeholder="Add Expense Type"
                      value={newExpenseType}
                      onChange={(e) => {
                        setNewExpenseType(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <AppButton isLoading={isLoading} onClick={createNewExpense}>
                      Save
                    </AppButton>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          )}
                </div>
          <div className="white-box mt-4">
          {isLoading ? (
            <AppLoading isLoading={isLoading} />
          ) : (
            <div className="white-box-inner">
              <div>
                <div>
                  <Table responsive="lg" borderless={true} id="support-tickets-table">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {expenseTypes?.map((expense,index) => {
                        return (
                          <tr
                            className="cursor-pointer"
                            key={index}
                            onClick={() => {
                              setNewExpenseType(expense.expense)
                              setSelectedExpense(true);
                              setExpenseId(expense.id);
                              setIsChecked(expense?.status === true);
                              setShowResults(true);
                            }}
                          >
                            <td>{expense?.expense}</td>
                            <td style={{ textAlign: 'left' }}>
                              {expense?.status === true ? (
                                <>
                                  <Dot
                                    color={'green'}
                                    style={{
                                      marginRight: '0.5rem',
                                      color: '#E8A454',
                                    }}
                                  />
                                  {'Active'}
                                </>
                              ) : null}

                              {expense?.status === false ? (
                                <>
                                  <Dot
                                    color={'red'}
                                    style={{
                                      marginRight: '0.5rem',
                                      color: '#E8A454',
                                    }}
                                  />
                                  {'Inactive'}
                                </>
                              ) : null}
                            </td>
                            <td>      
                              {isLoading?(
                                <AppLoading isLoading={isLoading} />
                              ):(
                                <AppButton style={{
                                  flex:1,
                                  margin: '0 5px', 
                                  marginBottom: 2, 
                                  background:expense.status ? 'red' : 'green'
                                  }}
                                  onClick={()=>handleExpenseTypeStatusChange(expense)}>
                                 {expense.status ? 'Inactive' : 'Active'}
                               </AppButton>
                              )}                                                   
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          )}
                </div>
              </Drawer>
              <div className="mt-3">
                <div className="row align-items-center mt-1">
                  <div className="col-auto">
                    <div className="sub-title">Facility Booking Invoice Type</div>
                    <div className="row">
                      <div className="col-auto">
                        <select onChange={(e) => { setAmenityBookingInvoiceTypeId(parseInt(e.target.value)); }}
                          value={amenityBookingInvoiceTypeId} className="form-control input-lbl">
                          <option>{"Select Facility Booking Invoice Type"}</option>
                          {invoiceTypes
                          .filter(invoiceType=>invoiceType.status===true)
                          .map((invoiceType, index) => {
                            return (
                              <option key={index} value={invoiceType?.id}>{invoiceType?.expense}</option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-3">
                <div className="row align-items-center mt-1">
                  <div className="col-auto">
                    <div className="sub-title">Management Fees Invoice Type</div>
                    <div className="row">
                      <div className="col-auto">
                        <select onChange={(e) => { setManagementFeesInvoiceTypeId(parseInt(e.target.value)); }}
                          value={managementFeesInvoiceTypeId} className="form-control input-lbl">
                          <option>{"Select Management Fee Invoice Type"}</option>
                          {invoiceTypes.filter(invoiceType=>invoiceType.status===true).map((invoiceType, index) => {
                            return (
                              <option key={index} value={invoiceType?.id}>{invoiceType?.expense}</option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-3">
                <div className="row align-items-center justify-content-start">
                  <div className="col-auto pr-0" style={{ minWidth: 150 }}>
                    <AppButton isLoading={isLoading} onClick={handleSave}>Save</AppButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function getLogoUI(bankName) {
  if (bankName == "Sampath Bank") {
    return (
      <img style={{ width: 52, marginRight: 10 }} src="/images/banklogos/smapthlogo.png" />
    );
  }

  if (bankName == "Cargills Bank Ltd") {
    return (
      <img style={{ width: 52, marginRight: 10, border: "1px solid red", borderRadius: "50%", }} src="/images/banklogos/cbank.png" />
    );
  }

  if (bankName == "Hatton National Bank PLC") {
    return (
      <img style={{ width: 52, marginRight: 10, border: "1px solid white", borderRadius: "50%", }} src="/images/hnb.png" />
    );
  }

  return (
    <img style={{ width: 52, marginRight: 10 }} src="/images/banklogos/generic.png" />
  );
}