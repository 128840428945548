import React, { createContext, useState } from "react";

export const UserDetailsContext = createContext([] as any[]);
export const AdminSelectedCondoIdContext = createContext([] as any[]);
export const OpenAccountingPageContext = createContext([] as any[]);

export default function Store({ children }) {
    const [user, setUser] = useState(null);
    const [openAccountingPage, setOpenAccountingPage] = React.useState("invoices");
    const [adminSelectedCondoId, setAdminSelectedCondoId] = useState(0);
    return (
        <UserDetailsContext.Provider value={[user, setUser]}>
            <AdminSelectedCondoIdContext.Provider value={[adminSelectedCondoId, setAdminSelectedCondoId]}>
                <OpenAccountingPageContext.Provider value={[openAccountingPage, setOpenAccountingPage]}>
                    {children}
                </OpenAccountingPageContext.Provider>
            </AdminSelectedCondoIdContext.Provider>
        </UserDetailsContext.Provider>
    );
}
