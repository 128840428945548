import firebase from "firebase/app";
import "firebase/auth"; // for authentication
import "firebase/storage"; // for storage
import "firebase/database"; // for realtime database
import "firebase/firestore"; // for cloud firestore
import "firebase/messaging"; // for cloud messaging
import "firebase/functions"; // for cloud functions
import "firebase/analytics"; // for analytics


import { uuidv4 } from "./HelperMethods";
import { SupportTicket } from "./FirestoreTypes";
export default class FirebaseServices {
  async uploadDoc(url: string) {
    let storage = firebase.storage();
    let mainImageName = uuidv4();

    let file = (await this.getFileBlob(url)) as any;

    await storage.ref(`/images/${mainImageName}`).put(file);

    // get imageurl
    let uploadURI = await storage.ref("images").child(mainImageName).getDownloadURL();

    return uploadURI;
  }

  async createTicket(data: SupportTicket) {
    let firestore = firebase.firestore();
    return await firestore.collection("supportTickets").doc().set(data);
  }

  async updateTicket(id: string, data: SupportTicket) {
    let firestore = firebase.firestore();
    return await firestore.collection("supportTickets").doc(id).update(data);

  }

  async getUser(id: string) {
    let firestore = firebase.firestore();
    return await firestore.collection("users").doc(id).get();
  }
  

  async getNotifications(id: string, setNotification :CallableFunction) {
    let firestore = firebase.firestore();
    firestore.collection("users").doc(id).onSnapshot((snapshot) => {
      let data = snapshot.data();
      setNotification(data?.FirebaseNotification);
      
    })
    
  }

  async updateNotifications(id: string, notifications :any) {
    let firestore = firebase.firestore();
    await firestore.collection("users").doc(id).update({
      FirebaseNotification: notifications
    });
  }


  async getTickets() {
    const events = await firebase
      .firestore()
      .collection("tickets")
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs.map((doc) => {
          console.log("LOG 1", doc.data());
          return doc.data();
        });
      });
    console.log("LOG 2", events);
    return events;
  }

  private getFileBlob(url) {
    return new Promise(function (resolve, reject) {
      try {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.addEventListener("load", function () {
          resolve(xhr.response);
        });
        xhr.send();
      } catch (error) {
        reject(error);
      }
    });
  }

  initFirebase() {
    if (firebase.apps.length) {
      return;
    }
    const env = process.env.REACT_APP_ENV;
    let firebaseConfig = {
      apiKey: "AIzaSyBfD9YLeOWSiX53XdF4lcvXtw02lYEhSQo",
      authDomain: "liwe-staging.firebaseapp.com",
      projectId: "liwe-staging",
      storageBucket: "liwe-staging.appspot.com",
      messagingSenderId: "179090428107",
      appId: "1:179090428107:web:d5345d19ee676797d28b4e",
      measurementId: "G-DDXJ38EL2R"
    };
    if (env == "prod") {
      firebaseConfig = {
        apiKey: "AIzaSyB4sVpLH7OPYrIOk3MW3ht5xZhl-dBgezc",
        authDomain: "liwe-51c58.firebaseapp.com",
        projectId: "liwe-51c58",
        storageBucket: "liwe-51c58.appspot.com",
        messagingSenderId: "904424963566",
        appId: "1:904424963566:web:8681b3317979eeb5a4a2ff",
        measurementId: "G-S5QPK3QHL3",
      }
    }
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
  }

  getDb() {
    return firebase.firestore();
  }

  timeStamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }
}
