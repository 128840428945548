import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import { Table } from "react-bootstrap";
import moment from "moment";
import AppButton from "../../components/AppButton/AppButton";
import { DatePicker } from "antd";
import ApiService from "../../services/ApiService";
import { showNotification, splitWord } from "../../services/HelperMethods";
import AppLoading from "../../components/AppLoading/AppLoading";

import "./Reporting.scss";
import ExcelExport from "../../components/ExcelExport/ExcelExport";

export default function FacilityUsageReport() {

  let apiService = new ApiService();

  const [fromDate, setFromDate] = useState(moment().add("-1", "days"));
  const [toDate, setToDate] = useState(moment());
  const [isLoading, setIsLoading] = useState(false);
  const [facilityUsageData, setFacilityUsageData] = useState([] as any[])

  useEffect(() => {
    getFacilityUsageData();
  }, [])

  async function getFacilityUsageData() {

    setIsLoading(true)

    let data = {
      fromDate: fromDate,
      toDate: toDate
    }

    await apiService.getFacilityUsageReports(data).then((result) => {

      let data = result.data;

      setFacilityUsageData(data?.facilityUsage.sort((a, b) => {
        return moment(b?.bookedDate).diff(a?.bookedDate)
      }))
    }).catch((err) => {
      showNotification("Unexpected Error", "Unexpected Error", "error")
    })

    setIsLoading(false)
  }

  // Function to extract desired attributes from a booking object
  const extractAttributes = (booking) => {  
    const extractedFacility = {
      "Booked By": booking?.bookedBy + " - " + splitWord(booking?.userType),
      "Apartment": booking?.apartment,
      "Booked Facility": booking?.amenityName ,
      "Booking Status": booking?.bookingStatus,
      "Booked Date": moment.utc(booking?.bookedDate).local().format('MMMM Do YYYY'),
      "Booked Time": moment.utc(booking?.startTime).local().format('h:mm a') + " - "
      + moment.utc(booking?.endTime).local().format('h:mm a'),
    };
    return extractedFacility;
  };

  // Create a new list with objects containing only the desired attributes
  const modifiedFacilityList = facilityUsageData.map(booking => extractAttributes(booking));


  return (
    <div className="body ">
      <h2>Reports</h2>
      <div>
        <Navbar hideSettings={true} hideAdd={true} hideFilter={true}>
          <Link to="/reports/invoiceAgingReport">Invoice Aging Report</Link>
          <Link to="/reports/facilityUsageReport" className="active">Facility Usage Report</Link>
          <Link to="/reports/visitorReports">Visitor Reports</Link>
          {/* <Link to="/reports/securityRosterReports">Security Roster Reports</Link> */}
          {/* <Link to="/reports/appUsageReport">App Usage Report</Link> */}
          {/* <Link to="/reports/transactionReport">Transaction Report</Link> */}
          <Link to="/reports/supportTicketReport">Support Tickets Report</Link>
        </Navbar>
      </div>

      <div className="filter-area">
        <AppLoading isLoading={isLoading} />
        <div className="filter-area-item">
          <span>From : </span>
          <DatePicker defaultValue={moment().add("-1", "days")} onChange={(date) => { if (date) { setFromDate(date); } }}
            format={"DD/MM/YYYY"} allowClear={false} />
        </div>

        <div className="filter-area-item">
          <span>To : </span>
          <DatePicker defaultValue={moment()} onChange={(date) => { if (date) { setToDate(date); } }} format={"DD/MM/YYYY"}
            disabledDate={(date) => { let isd = moment(fromDate); return !date || date.isBefore(isd); }} allowClear={false} />
        </div>

        <div className="filter-area-item">
          <AppButton onClick={() => { getFacilityUsageData() }}>Filter</AppButton>
        </div>

        <div className="filter-area-item">
          <ExcelExport excelData={modifiedFacilityList} fileName={"FacilityUsageReport"} />
        </div>
      </div>

      <div style={{ maxWidth: "76rem", overflow: "auto" }}>
        <Table responsive="lg" borderless={true} id="table-to-xlx" className="table">
          <thead>
            <tr>
              <th>Booked By</th>
              <th>Apartment</th>
              <th>Booked Facility</th>
              <th>Booking Status</th>
              <th>Booked Date</th>
              <th>Booked Time</th>
            </tr>
          </thead>

          <tbody>
            {facilityUsageData?.length > 0
              ? (facilityUsageData?.map((booking, index) => {
                return (
                  <tr key={index}>
                    <td>{booking?.bookedBy + " - " + splitWord(booking?.userType)}</td>
                    <td>{booking?.apartment}</td>
                    <td>{booking?.amenityName}</td>
                    <td>{booking?.bookingStatus}</td>
                    <td>{moment.utc(booking?.bookedDate).local().format('MMMM Do YYYY')}</td>
                    <td>{moment.utc(booking?.startTime).local().format('h:mm a') + " - "
                      + moment.utc(booking?.endTime).local().format('h:mm a')}</td>
                  </tr>
                )
              }))
              : (<Fragment />)
            }
          </tbody>
        </Table>
      </div>
    </div>
  );
}
