import { Link } from "react-router-dom";
import React, { Fragment, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import DashboardIcon from "../../assets/images/icons/dashboard.svg";
import CompoundSecurityIcon from "../../assets/images/icons/shield.svg";
import ApartmentsIcon from "../../assets/images/icons/apartment.svg";
import FacilitiesIcon from "../../assets/images/icons/gym.svg";
import PaymentsIcon from "../../assets/images/icons/credit-card.svg";
import FeedbackIcon from "../../assets/images/icons/feedback.svg";
import SettingsIcon from "../../assets/images/icons/settings.svg";
import { useLocation } from "react-router-dom";
import { OpenAccountingPageContext, UserDetailsContext } from "../../store/Store";
import packageJson from '../../../package.json';
import moment from "moment";
import { FaAngleDown, FaAngleLeft, FaAngleRight, FaAngleUp, FaChevronDown, FaChevronRight, FaChevronUp } from "react-icons/fa6";

import "./Sidebar.scss";
import AppButton from "../AppButton/AppButton";

const appVersion = packageJson.version;

export default function Sidebar() {

  const [user] = useContext(UserDetailsContext);
  const [dropdownAccountingOpen, setDropdownAccountingOpen] = React.useState(false);
  const [openAccountingPage, setOpenAccountingPage] = useContext(OpenAccountingPageContext);

  return (
    <div className="sidebar-wrapper">
      <div className="sidenav">
        <div className="image-wrapper">
          <img className="logo-img" src="/images/logo.png" />
        </div>
        <div className="condo-name-wrapper">
          <p className="condo-name">{user?.buildingName}</p>
        </div>

        <nav className="side-menu">
          <SidebarLink to="/dashboard">
            <div className="flex items-center h-12 ml-7">
              <img src={DashboardIcon} alt="Dashboard" />
              <div className="text-slate-50 text-sm pl-4">Dashboard</div>
            </div>
          </SidebarLink>

          <SidebarLink to="/compound-security">
            <div className="flex items-center h-12 ml-7">
              <img src={CompoundSecurityIcon} alt="Compound Security" />
              <div className="text-slate-50 text-sm pl-4">Compound</div>
            </div>
          </SidebarLink>

          {user?.userType !== "Accountant" && user?.userType !== "Engineer" && user?.userType !== "HelpDesk" && user?.userType !== "Administration" && (
            <SidebarLink to="/apartments">
              <div className="flex items-center h-12 ml-7">
                <img src={ApartmentsIcon} alt="Apartments" />
                <div className="text-slate-50 text-sm pl-4">Apartments/Users</div>
              </div>
            </SidebarLink>
          )}

          {user?.userType !== "Accountant" && user?.userType !== "Engineer" && (
            <SidebarLink to="/facility/manage">
              <div className="flex items-center h-12 ml-7">
                <img src={FacilitiesIcon} alt="Facilities" />
                <div className="text-slate-50 text-sm pl-4">Facilities</div>
              </div>
            </SidebarLink>
          )}

          {user?.userType !== "Engineer" && user?.userType !== "HelpDesk" && (
            <div >
              <div className="relative">
                <div onClick={() => {
                  setDropdownAccountingOpen(!dropdownAccountingOpen);
                }}>
                  <SidebarLink to="/accounting/">
                  <div className="flex items-center justify-between h-12 ml-7">
                    <div className="flex items-center">
                      <img src={PaymentsIcon} alt="Accounting" />
                      <div className="text-slate-50 text-sm pl-4">Accounting</div>
                    </div>
                  </div>
                  </SidebarLink>
                </div>
                <button onClick={() => { setDropdownAccountingOpen(!dropdownAccountingOpen) }}
                  className="absolute border-2 border-transparent top-2 right-3 p-2 rounded-full glow-2 text-slate-50">
                  {dropdownAccountingOpen ? <FaAngleDown /> : <FaAngleRight />}
                </button>
              </div>
              {dropdownAccountingOpen && (
                <div className="mt-[-10px]">
                  <SidebarLink to="/accounting/">
                    <button className={"block w-100 hover:bg-[#8acb87] " + (openAccountingPage == "invoices" ? "submenu-active" : "")} onClick={() => { setOpenAccountingPage("invoices") }}>
                      <div className="pl-4">
                        <div className="flex items-center h-12 ml-7">
                          <div className="text-slate-50 text-sm pl-4">Invoices</div>
                        </div>
                      </div>
                    </button>
                    <button className={"block w-100 hover:bg-[#8acb87] " + (openAccountingPage == "utilities" ? "submenu-active" : "")} onClick={() => { setOpenAccountingPage("utilities") }}>
                      <div className="pl-4">
                        <div className="flex items-center h-12 ml-7">
                          <div className="text-slate-50 text-sm pl-4">Utility bills</div>
                        </div>
                      </div>
                    </button>
                    <button className={"block w-100 hover:bg-[#8acb87] " + (openAccountingPage == "management-fees" ? "submenu-active" : "")} onClick={() => { setOpenAccountingPage("management-fees") }}>
                      <div className="pl-4">
                        <div className="flex items-center h-12 ml-7">
                          <div className="text-slate-50 text-sm pl-4">Management Fees</div>
                        </div>
                      </div>
                    </button>
                    <button className={"block w-100 hover:bg-[#8acb87] " + (openAccountingPage == "payments" ? "submenu-active" : "")} onClick={() => { setOpenAccountingPage("payments") }}>
                      <div className="pl-4">
                        <div className="flex items-center h-12 ml-7">
                          <div className="text-slate-50 text-sm pl-4">Customer Payment</div>
                        </div>
                      </div>
                    </button>
                    <button className={"block w-100 hover:bg-[#8acb87] " + (openAccountingPage == "expenses" ? "submenu-active" : "")} onClick={() => { setOpenAccountingPage("expenses") }}>
                      <div className="pl-4">
                        <div className="flex items-center h-12 ml-7">
                          <div className="text-slate-50 text-sm pl-4">Expenses</div>
                        </div>
                      </div>
                    </button>
                    <button className={"block w-100 hover:bg-[#8acb87] " + (openAccountingPage == "dashboard" ? "submenu-active" : "")} onClick={() => { setOpenAccountingPage("dashboard") }}>
                      <div className="pl-4">
                        <div className="flex items-center h-12 ml-7">
                          <div className="text-slate-50 text-sm pl-4">Reports</div>
                        </div>
                      </div>
                    </button>
                  </SidebarLink>
                </div>
              )}

            </div>
          )}

          {user?.userType !== "Accountant" && (
            <SidebarLink to="/notices/public">
              <div className="flex items-center h-12 ml-7">
                <img src={FeedbackIcon} alt="Notices" />
                <div className="text-slate-50 text-sm pl-4">Notices</div>
              </div>
            </SidebarLink>
          )}

          {user?.userType !== "Engineer" && user?.userType !== "HelpDesk" && user?.userType !== "Administration" && (
            <SidebarLink to="/settings/payment">
              <div className="flex items-center h-12 ml-7">
                <img src={SettingsIcon} alt="Settings" />
                <div className="text-slate-50 text-sm pl-4">Settings</div>
              </div>
            </SidebarLink>
          )}

          {user?.userType !== "HelpDesk" && (
            <SidebarLink to="/reports/invoiceAgingReport">
              <div className="flex items-center h-12 ml-7">
                <i className="far fa-file-alt text-slate-50 text-xl"></i>
                <div className="text-slate-50 text-sm pl-4">Reports</div>
              </div>
            </SidebarLink>
          )}

          <SidebarLink to="/support-tickets">
            <div className="flex items-center h-12 ml-7">
              <i className="fas fa-ticket-alt text-slate-50 text-xl"></i>
              <div className="text-slate-50 text-sm pl-4">Support Tickets</div>
            </div>
          </SidebarLink>
        </nav>

        <div className="bottom-0 p-4 text-slate-50 text-xs">
          <div className="text-sm font-mono">
            <span className="text-center align-middle flex items-center gap-1">
              <span className="">Copyright</span> <span className="text-xl">&copy;</span> 2024 LIWE <br />
            </span>
            <span>
              All Rights Reserved
            </span>
          </div>
          {/* <div className="text-xs mt-1">
            V {appVersion}
          </div> */}
        </div>
      </div>
    </div>
  );
  
}

export function SidebarLink(props: { to?; children; className?}) {
  let active = "";
  const location = useLocation();

  if (location.pathname == props.to) {
    active = "active";
  }

  return (
    <Link to={props.to} className={"nav-link " + active + ` ${props.className}`}>
      {props.children}
    </Link>
  );
}
