import React from "react";
import { CSSProperties } from "react";
import ClipLoader from "react-spinners/ClipLoader";
export default function AppButton(props: {
  isLoading?: boolean;
  onClick?: CallableFunction;
  children?: any;
  className?: string;
  style?: CSSProperties;
  disable?: boolean;
  loadingColor?: string;
  variant?: "danger"
}) {


  let cls = "green-button";
  if(props.variant == "danger"){
    cls = "btn-danger";
  }


  return (
    <button
      style={props.style}
      disabled={props.isLoading || props.disable}
      onClick={() => {
        props.onClick?.();
      }}
      className={"btn " + cls + " " + props.className}
    >
      {props.isLoading ? (
        <ClipLoader
          size={20}
          color={props.loadingColor ? props.loadingColor : "white"}
        />
      ) : (
        props.children
      )}
    </button>
  );
}
