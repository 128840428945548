import moment from "moment";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import CreateExpense from "../../components/CreateExpense/CreateExpense";
import Drawer from "../../components/Drawer/Drawer";
import Navbar from "../../components/Navbar/Navbar";
import ApiService from "../../services/ApiService";
import { formatMoney, showNotification, splitWord } from "../../services/HelperMethods";
import DrawerHeader from "../../components/Drawer/DrawerHeader/SideDrawerHeader";
import RedArrow from "../../assets/images/icons/red-arrow.svg";

import "./Payment.scss";
import AppButton from "../../components/AppButton/AppButton";
import { UserDetailsContext } from "../../store/Store";
import AppLoading from "../../components/AppLoading/AppLoading";
import FirebaseServices from "../../services/FirebaseServices";
import PaymentFileInput from "../../components/FileInput/PaymentFileInput";

export default function Bills() {

  const apiService = new ApiService();
  const firebaseServices = new FirebaseServices();

  const history = useHistory();

  const [showCreateExpense, setShowCreateExpense] = useState(false);
  const [expenses, setExpenses] = useState([]);
  const [searchedExpenses, setSearchedExpenses] = useState([] as any[]);
  const [selectedExpense, setSelectedExpense] = useState(null as any)
  const [isLoading, setIsLoading] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState(1);
  const [paymentRefNumber, setPaymentRefNumber] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("0");
  const [paymentRemarks, setPaymentRemarks] = useState("");
  const [paymentImage, setPaymentImage] = useState("");

  const [user, setUser] = useContext(UserDetailsContext);

  useEffect(() => {
    getExpenses();
  }, []);

  async function getExpenses() {

    setIsLoading(true);

    await apiService.getExpense().then((result) => {

      let data = result.data

      setExpenses(data?.expenses)
      setSearchedExpenses(data?.expenses)
    }).catch(() => {

    })

    setIsLoading(false);
  }

  function makePayment() {

    setIsLoading(true);

    if (selectedExpense != null) {

      let data = {
        invoiceId: selectedExpense.id,
        userId: user?.id,
        paymentMethod: paymentMethod,
        paymentRefNumber: paymentRefNumber,
        paymentRemarks: paymentRemarks,
        paymentAmount: parseFloat(paymentAmount),
        paymentImage: paymentImage
      }

      apiService.payExpense(data).then((result) => {

        let data = result.data

        if (data?.isSuccess) {

          showNotification("Payment", "Payment Successful", "success")

          setPaymentMethod(1);
          setPaymentRefNumber("");
          setPaymentAmount("0");
          setPaymentRemarks("");
          setPaymentImage("");

          getExpenses();
        }
      })
    }

    setIsLoading(false);
  }

  function handleFilter(array: any[], searchedWord: string) {
    let searchedArray = [] as any[];
    array.forEach((expense) => {
      if (expense?.refNumber.toString().toLowerCase().includes(searchedWord.toLowerCase())
        || moment.utc(expense?.issuedDate).local().format("MMMM DD, YYYY").toString().toLowerCase().includes(searchedWord.toLowerCase())
        || splitWord(expense?.expenseType).toLowerCase().includes(searchedWord.toLowerCase())) {
        searchedArray.push(expense);
      }
    });
    return searchedArray;
  }

  function handleSearch(searchedWord: string) {
    setIsLoading(true);
    if (searchedWord.trim() === "") {
      setSearchedExpenses(expenses);
      setIsLoading(false);
      return;
    }

    let searchedArray = handleFilter(expenses, searchedWord);
    setSearchedExpenses(searchedArray);
    setIsLoading(false);
  }

  // Function to extract desired attributes from a expenses object
  const extractAttributes = (expense) => {
    const extractedExpense = {
        "RefNumber": expense?.refNumber,
        "Created By": expense?.createdBy,
        "Description": expense?.description,
        "Issued Date": expense?.issuedDate?.split('T')[0],
        "Expense Type": expense?.expenseType,
        "Expense Section": expense?.expenseSection,
        "Billed Amount": "LKR " + formatMoney(expense?.billAmount),
        "Amount Paid": "LKR " + formatMoney(expense?.paidAmount),
        "Amount Payable": expense?.billAmount - expense?.paidAmount > 0 ? "LKR " + formatMoney(expense?.billAmount - expense?.paidAmount) : "Paid",
    };
    return extractedExpense;
  };

  // Create a new list with objects containing only the desired attributes
  const modifiedInvoiceList = searchedExpenses.map(user => extractAttributes(user));

  return (
    <div className="body payment-page">
      <AppLoading isLoading={isLoading} />
      <h2>Accounting</h2>

      <Navbar showSearch hideSettings={true} hideFilter={true} onAddButtonClick={() => { setShowCreateExpense(true) }}
        onSearchChange={handleSearch}
        downloadExcel={true}
        fileName="Expenses"
        excelData={modifiedInvoiceList}>
        <Link to="/payment">Invoices</Link>
        {/* <Link to="/payment/settlements">Settlements</Link> */}
        {/* <Link to="/payment/payroll">Payroll</Link> */}
        <Link className="active" to="/payment/bills">Expenses</Link>
      </Navbar>

      <Table responsive="lg" borderless={true}>
        <thead>
          <tr>
            <th>Ref No</th>
            <th>Issued Date</th>
            <th>Expense Type</th>
            <th>Expense Section</th>
            <th>Billed Amount</th>
            <th>Amount Paid</th>
            <th>Amount Payable</th>
          </tr>
        </thead>
        <tbody className="wrapper-payments-table">
          {searchedExpenses.length > 0
            ? (searchedExpenses.map((expense: any, index) => {
              return (<tr key={index} onClick={() => { setSelectedExpense(expense) }}>
                <td>{expense?.refNumber}</td>
                <td>{moment.utc(expense?.issuedDate).local().format("MMMM DD, YYYY")}</td>
                <td>{expense?.expenseType}</td>
                <td>{expense?.expenseSection}</td>
                <td>{"LKR "}{formatMoney(expense?.billAmount)}</td>
                <td>{"LKR "}{formatMoney(expense?.paidAmount)}</td>
                <td>
                  {expense?.billAmount - expense?.paidAmount > 0
                    ? ("LKR " + formatMoney(expense?.billAmount - expense?.paidAmount))
                    : ("Paid")
                  }
                </td>
              </tr>)
            }))
            : (<Fragment />)
          }
        </tbody>
      </Table>

      <Drawer className="payment-info-drawer" isOpen={selectedExpense != null}>
        <DrawerHeader profileImage={false} onClose={() => { history.push("/payment/bills"); setSelectedExpense(null); }}>
          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
            <div className="icon-cir" style={{
              backgroundColor: "#E7F9E8", borderRadius: "5rem",
              width: "7rem", height: "7rem", display: "flex", justifyContent: "center", alignItems: "center"
            }}>
              <img className="icon-cir-img" style={{ width: "5rem", height: "5rem" }} src={RedArrow}></img>
            </div>
            <div style={{ color: "#707076", margin: "1rem 0rem 0.5rem 0rem" }}>#{selectedExpense?.refNumber}</div>
            <div>{moment.utc(selectedExpense?.issuedDate).local().format("LL")}</div>
          </div>
        </DrawerHeader>
        <div style={{ overflow: "auto" }}>
          <div className="amount">
            LKR {formatMoney(selectedExpense?.billAmount)}
          </div>
          <div style={{}} className="ii-inner">
            <div className="payment-info mt-3">
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="label-heading">Expense Summary</div>
                  </div>
                </div>

                <div>
                  <div>
                    <div className="ii-top">
                      <div className="ii-lft">Description</div>
                      <div className="ii-rht">
                        {selectedExpense?.description}
                      </div>
                    </div>
                    {selectedExpense?.apartment.trim() != ""
                      ? (<div className="ii-top">
                        <div className="ii-lft">Apartment</div>
                        <div className="ii-rht">{selectedExpense?.apartment}</div>
                      </div>)
                      : (<Fragment />)
                    }
                    <div className="ii-top">
                      <div className="ii-lft">Expense Type</div>
                      <div className="ii-rht">{selectedExpense?.expenseType}</div>
                    </div>
                    <div className="ii-top">
                      <div className="ii-lft">Expense Section</div>
                      <div className="ii-rht">{selectedExpense?.expenseSection}</div>
                    </div>
                    <div className="ii-top">
                      <div className="ii-lft">Bill Amount</div>
                      <div className="ii-rht">
                        LKR {formatMoney(selectedExpense?.billAmount)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="label-heading">Expense Info</div>
                  </div>
                </div>

                <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
                  <div>
                    <div className="ii-top">
                      <div className="ii-lft">Created</div>
                      <div className="ii-rht">
                        {moment.utc(selectedExpense?.createdDate).local().format("LL")}
                      </div>
                    </div>
                    <div className="ii-top">
                      <div className="ii-lft">Issued</div>
                      <div className="ii-rht">
                        {moment.utc(selectedExpense?.issuedDate).local().format("LL")}
                      </div>
                    </div>
                    <div className="ii-top">
                      <div className="ii-lft">Actioned By</div>
                      <div className="ii-rht">
                        <Link to={"/users/" + selectedExpense?.createdById}>
                          {selectedExpense?.createdBy}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ marginBottom: "2rem" }}>
                {selectedExpense?.payments?.length > 0
                  ? (<div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="label-heading">Payment Summary</div>
                      </div>
                    </div>

                    {selectedExpense?.payments?.map((payment, index) => {
                      return (
                        <div key={index} style={{ display: "flex", flex: 1, flexDirection: "column", marginBottom: "0.6rem" }}>
                          {payment?.createdBy != null
                            ? (<div className="ii-top">
                              <div className="ii-lft">Payment Added By</div>
                              <div className="ii-rht">
                                <Link to={"/users/" + payment?.createdBy}>
                                  {payment?.createdByName}
                                </Link>
                              </div>
                            </div>)
                            : (<Fragment />)
                          }
                          {payment?.refNumber != ""
                            ? (<div className="ii-top">
                              <div className="ii-lft">Ref Number</div>
                              <div className="ii-rht">
                                {payment?.refNumber}
                              </div>
                            </div>)
                            : (<Fragment />)
                          }
                          <div className="ii-top">
                            <div className="ii-lft">Payment Date</div>
                            <div className="ii-rht">
                              {moment.utc(payment?.paidDate).local().format("LL")}
                            </div>
                          </div>
                          <div className="ii-top">
                            <div className="ii-lft">Amount</div>
                            <div className="ii-rht">
                              LKR {formatMoney(payment?.amount)}
                            </div>
                          </div>
                          <div className="ii-top">
                            <div className="ii-lft">Paid Via</div>
                            <div className="ii-rht">
                              {payment?.paymentMethod == "Card"
                                ? ("LIWE App")
                                : (payment?.paymentMethod == "POS"
                                  ? ("Card")
                                  : (splitWord(payment?.paymentMethod))
                                )
                              }
                            </div>
                          </div>
                          {payment?.remarks != ""
                            ? (<div className="ii-top">
                              <div className="ii-lft">Remarks</div>
                              <div className="ii-rht">
                                {payment?.remarks}
                              </div>
                            </div>)
                            : (<Fragment />)
                          }
                          <div className="ii-top">
                            <div className="ii-lft">Status</div>
                            <div className="ii-rht">
                              {splitWord(payment?.paymentStatus)}
                            </div>
                          </div>
                          {payment?.imageURL
                            ? (<div className="ii-top">
                              <div className="ii-lft">Attachments</div>
                              <div style={{ color: "#8acb87", fontWeight: 500, cursor: "pointer" }} className="ii-rht"
                                onClick={() => { window.open(payment?.imageURL) }}>
                                {"Click Here"}
                              </div>
                            </div>)
                            : (<Fragment />)
                          }
                        </div>
                      )
                    })}
                  </div>)
                  : (<Fragment />)
                }
              </div>

              <div>
                {(selectedExpense?.billAmount - selectedExpense?.paidAmount) > 0
                  ? (<div style={{ margin: "0.1rem" }} className="row make-payment">
                    <div style={{ marginBottom: "0.2rem" }} className="row">
                      <div className="col payee-lbl">Make Payment</div>
                    </div>
                    <div className="row">
                      <div style={{ marginBottom: "0.5rem" }} className="col-12">
                        <div className="lbl">Method</div>
                        <div>
                          <select className="form-control" onChange={(e) => { setPaymentMethod(parseInt(e.target.value)) }}
                            value={paymentMethod}>
                            <option value="1">Card</option>
                            <option value="2">Cash</option>
                            <option value="3">Bank Transfer</option>
                            <option value="4">Cheque</option>
                          </select>
                        </div>
                      </div>
                      <div style={{ marginBottom: "0.5rem" }} className="col-6">
                        <div className="lbl">Ref Number</div>
                        <div>
                          <input onChange={(e) => { setPaymentRefNumber(e.target.value) }} value={paymentRefNumber}
                            className="form-control" type="text" />
                        </div>
                      </div>
                      <div style={{ marginBottom: "0.5rem" }} className="col-6">
                        <div className="lbl">Amount</div>
                        <div>
                          <input onChange={(e) => { setPaymentAmount(e.target.value) }} value={paymentAmount}
                            className="form-control" type="number" />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="lbl">Remarks</div>
                        <div>
                          <textarea rows={5} className="form-control" onChange={(e) => { setPaymentRemarks(e.target.value); }}
                            value={paymentRemarks} maxLength={250} />
                        </div>
                      </div>
                      <div style={{ marginTop: "0rem" }} className="wrapper-bottom-set">
                        {paymentImage != ""
                          ? (<div style={{ marginBottom: "0.5rem", cursor: "pointer" }} className="other-image-wrap col-12">
                            <button onClick={() => { setPaymentImage(""); }} className="remove-button">
                              <i className="fas fa-times" />
                            </button>
                            <img style={{ cursor: "pointer" }} onClick={() => { window.open(paymentImage) }} src={paymentImage} />
                          </div>)
                          : (<Fragment />)
                        }
                        {paymentImage == ""
                          ? (<div style={{ marginBottom: "0.5rem" }} className="col-12">
                            <PaymentFileInput onChange={async (url) => {

                              var docURL = await firebaseServices.uploadDoc(url)

                              if (docURL) {
                                setPaymentImage(docURL)
                              }
                            }} />
                            <span className="other-image-upload-icon">
                              <i className="fas fa-plus" />
                            </span>
                          </div>)
                          : (<Fragment />)
                        }
                      </div>
                    </div>
                    <div style={{ margin: "0.1rem" }} className="row">
                      <div onClick={(() => { makePayment() })} style={{ margin: "0.8rem 0rem" }} className="sidebar-footer">
                        <AppButton>Make Payment</AppButton>
                      </div>
                    </div>
                  </div>)
                  : (<Fragment />)}
              </div>
            </div>
          </div>
        </div>
      </Drawer>

      <CreateExpense isShow={showCreateExpense} onClose={() => { setShowCreateExpense(false); }}
        onCreateExpense={() => { getExpenses(); setShowCreateExpense(false); }} />
    </div>
  );
}
