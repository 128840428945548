import { FormControlLabel, Grid } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import { DatePicker } from "antd";
import { spawn } from "child_process";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import ApiService from "../../services/ApiService";
import ordinalSuffixOf, { formatMoney, getUserType, handleErrors, showNotification, roundOff } from "../../services/HelperMethods";
import AppButton from "../AppButton/AppButton";
import Drawer from "../Drawer/Drawer";
import DrawerBody from "../Drawer/DrawerBody";
import DrawerFooter from "../Drawer/DrawerFooter";
import TickCircle from "../TickCircle/TickCircle";
import { Modal } from "react-bootstrap";

import "./CreateInvoice.scss";

export default function CreateInvoice(props: { isShow; onClose; onCreateInvoice; }) {

    const apiService = new ApiService();

    const [buildingUsers, setBuildingUsers] = useState([] as any[]);
    const [invoiceItems, setInvoiceItems] = useState([{ description: "", amount: 0 },] as any[]);
    const [isLoading, setIsLoading] = useState(false);

    //forum
    const [selectedUserId, setSelectedUserId] = useState(0);
    const [amount, setAmount] = useState(0);
    const [issuedDate, setIssuedDate] = useState(moment());
    const [dueDate, setDueDate] = useState(moment().add(7, "d"));
    const [vatPercentage, setVatPercentage] = useState(0);
    const [serviceChargesPercentage, setServiceCharges] = useState(0);
    const [discountPercentage, setDiscountPercentage] = useState(0);
    const [lateFeePercentage, setLateFeePercentage] = useState(0);
    const [recurringInvoiceType, setRecurringInvoiceType] = useState(0);
    // const [showInvoiceTypeModal, setShowInvoiceTypeModal] = useState(false);
    const [newInvoiceType, setNewInvoiceType] = useState("");
    const [invoiceType, setInvoiceType] = useState(0);
    const [invoiceTypes, setInvoiceTypes] = useState([] as any)
    useEffect(() => {
        getUsers();
        getInvoiceTypes();
    }, []);

    async function getUsers() {
        try {
            let result = await apiService.getBuildingUsers();
            setBuildingUsers(result?.data?.users);
        } catch (error) {
            handleErrors(error);
        }
    }

    async function getInvoiceTypes() {
        try {
            await apiService.getInvoiceTypes().then((result) => {

                let data = result.data

                const invoiceTypes = data?.expenseTypes;
                const filteredInvoice = invoiceTypes.filter(invoiceType=>invoiceType.status===true);
                setInvoiceTypes(filteredInvoice)
            })
        } catch (error) {
            handleErrors(error);
        }
    }

    useEffect(() => {
        getTotalInvoiceValue()
    }, [vatPercentage, serviceChargesPercentage, discountPercentage, invoiceItems])

    return (
        <div className="create-invoice-wrap">
            <Drawer isOpen={props.isShow}>
                <DrawerBody>
                    <div>
                        <div>
                            <div className="icon-close cursor-pointer mb-2" onClick={() => { props.onClose(); }}>
                                <TickCircle icon="close" large />
                            </div>

                            <h4>Create New Invoice</h4>

                            <div style={{ margin: "1rem 0rem" }}>
                                <select className="form-control"
                                    onChange={(e) => { setSelectedUserId(parseInt(e.target.value)); }} value={selectedUserId}>
                                    <option value={0}>Select User</option>
                                    {buildingUsers.map((u, i) => {
                                        return (
                                            <option value={u.id} key={i}>
                                                {u.firstName} {u.lastName}{" - "}
                                                {ordinalSuffixOf(u?.apartment?.floor)}
                                                {" Floor, "}
                                                {u?.apartment?.doorNumber}{" - "}
                                                {getUserType(u.userType)}
                                            </option>
                                        );
                                    })}
                                </select>

                                <div className="row mt-2">
                                    <div className="col">
                                        <label>Issued on</label>
                                        <DatePicker className="form-control" value={issuedDate}
                                            disabledDate={(d) => { let isd = moment(issuedDate); return d.isBefore(isd); }}
                                            onChange={(d) => { if (d) { setIssuedDate(d); } }} />
                                    </div>
                                    <div className="col">
                                        <label>Due on</label>
                                        <DatePicker className="form-control"
                                            disabledDate={(d) => { let isd = moment(issuedDate); return !d || d.isBefore(isd); }}
                                            value={dueDate} onChange={(d) => { if (d) { setDueDate(d); } }} />
                                    </div>
                                </div>
                            </div>

                            <div style={{ margin: "1rem 0rem" }}>
                                <div className="row mt-2">
                                    <div className="col">
                                        <label>Item</label>
                                    </div>
                                    <div className="col">
                                        <label>Amount (LKR)</label>
                                    </div>
                                </div>

                                <div>
                                    {invoiceItems.map((item, i) => {
                                        return (
                                            <div className="row mt-1" key={i}>
                                                <div className="col">
                                                    <input className="form-control" value={item.description}
                                                        placeholder="Description"
                                                        onChange={(e) => {
                                                            item.description = e.target.value;
                                                            setInvoiceItems([...invoiceItems,]);
                                                        }} />
                                                </div>
                                                <div className="col">
                                                    <input className="form-control" value={item.amount} placeholder="Amount"
                                                        type="number"
                                                        onChange={(e) => {
                                                            item.amount = parseFloat(e.target.value);
                                                            setInvoiceItems([...invoiceItems,]);
                                                        }} />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <button style={{
                                    backgroundColor: "transparent", border: "none",
                                    color: "#4bbb46", fontWeight: "bold", marginTop: 10,
                                }}
                                    onClick={() => {
                                        let existingItems = [...invoiceItems];
                                        existingItems.push({ description: "", amount: 0, });
                                        setInvoiceItems(existingItems);
                                    }}>
                                    + ADD ITEM
                                </button>
                            </div>

                            <div style={{ margin: "1rem 0rem" }}>
                                <div className="row mt-2">
                                    <div className="col">
                                        <label style={{ display: "flex", justifyContent: "space-between" }}>Invoice Type *
                                            {/* <span onClick={() => { setShowInvoiceTypeModal(true) }} style={{
                                                fontWeight: "bold", cursor: "pointer", fontSize: "1rem", color: "#4bbb46"
                                            }}>+</span> */}
                                        </label>
                                        <select className="form-control" value={invoiceType}
                                            onChange={(e) => { setInvoiceType(parseInt(e.target.value)) }}>
                                            <option value={"0"}>{"Select Invoice Type"}</option>
                                            {invoiceTypes?.map((invoice, index) => {
                                                return (
                                                    <option key={index} value={invoice?.id}>{invoice?.expense}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div style={{ margin: "1rem 0rem" }}>
                                <div className="row mt-2">
                                    <div className="col">
                                        <label>Service Charge (%)</label>
                                        <input value={serviceChargesPercentage} className="form-control" placeholder="Service Charge"
                                            onChange={(e) => {
                                                let val = parseFloat(e.target.value);

                                                if (!isNaN(val)) {
                                                    setServiceCharges(val);
                                                } else {
                                                    setServiceCharges(NaN);
                                                }

                                               
                                            }}
                                            type="number" />
                                    </div>
                                    <div className="col">
                                        <label>VAT/NBT (%)</label>
                                        <input value={vatPercentage} className="form-control" placeholder="VAT/NBT"
                                            onChange={(e) => {
                                                let val = parseFloat(e.target.value);

                                                if (!isNaN(val)) {
                                                    setVatPercentage(val);
                                                } else {
                                                    setVatPercentage(NaN);
                                                }

                                            }}
                                            type="number" />
                                    </div>
                                </div>
                            </div>
                            {/* <div style={{ margin: "1rem 0rem" }}>
                                <div className="row mt-2">
                                    <div className="col-6">
                                        <label>Discount (%)</label>
                                        <input value={discountPercentage} className="form-control"
                                            onChange={(e) => {
                                                let val = parseFloat(e.target.value);

                                                if (!val || 0 > val) {
                                                    val = 0;
                                                }

                                                setDiscountPercentage(val);
                                            }}
                                            type="number" />
                                    </div>
                                    <div className="col">
                                        <label>Late Fee (%)</label>
                                        <input value={lateFeePercentage} className="form-control"
                                            onChange={(e) => {
                                                let val = parseFloat(e.target.value);

                                                if (!val || 0 > val) {
                                                    val = 0;
                                                }

                                                setLateFeePercentage(val);
                                            }}
                                            type="number" />
                                    </div>
                                </div>
                            </div> */}
                            <div className="total-value-field">
                                <label>
                                    <small>Total Invoice Value</small>
                                </label>
                                <span style={{ fontSize: 30, marginLeft: 10, color: "#4bbb46", fontWeight: "bold", }}>
                                    <span style={{ fontWeight: "normal" }}>
                                        LKR
                                    </span>{" "}
                                    {formatMoney(amount)}
                                </span>
                            </div>
                        </div>
                    </div>
                </DrawerBody>
                <DrawerFooter>
                    {/* <div>
                        <Grid container alignItems="center">
                            <Grid item>Recurring Invoice</Grid>
                            <Grid item>
                                <Switch checked={recurringInvoiceType >= 1}
                                    value={recurringInvoiceType >= 1 ? true : false}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setRecurringInvoiceType(1);
                                        } else {
                                            setRecurringInvoiceType(0);
                                        }
                                    }}
                                    inputProps={{ "aria-label": "secondary checkbox", }}
                                />
                            </Grid>
                        </Grid>
                    </div> */}
                    {recurringInvoiceType >= 1
                        ? (<div>
                            <select onChange={(e) => setRecurringInvoiceType(parseInt(e.target.value))} value={recurringInvoiceType}
                                className="form-control">
                                <option value={1}>Monthly</option>
                                <option value={2}>Weekly</option>
                                <option value={4}>Quarterly</option>
                                <option value={3}>Annually</option>
                            </select>
                        </div>)
                        : (<Fragment />)
                    }
                    {getNextInvoiceTime()}
                    <div className="mt-1" style={{ display: 'flex', justifyContent: 'center' }}>
                        <AppButton style={{ flex:1,margin: '0 5px', marginBottom: 2, background: "red" }} onClick={handleClearButton}>
                            Clear
                        </AppButton>
                        <AppButton style={{flex:3, margin: '0 5px', marginBottom: 0 }} isLoading={isLoading} onClick={handleCreate}>
                            Create Invoice
                        </AppButton>
                    </div>
                    {/* <div className="mt-1">
                        <AppButton style={{
                            backgroundColor: "white", color: "#4BBB46",
                            border: "1px solid #4BBB46", marginBottom: 0,
                        }}
                            loadingColor={"#4BBB46"}
                            isLoading={isLoading}>
                            Save as draft
                        </AppButton>
                    </div> */}
                </DrawerFooter>
            </Drawer>

            {/* <Modal centered onHide={() => { setShowInvoiceTypeModal(false); }} show={showInvoiceTypeModal}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>Add New Invoice Type</div>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <input className="form-control" value={newInvoiceType}
                            onChange={(e) => { setNewInvoiceType(e.target.value); }} />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <AppButton isLoading={isLoading} onClick={() => { createNewInvoiceType() }}>
                        Save &amp; Submit
                    </AppButton>
                </Modal.Footer>
            </Modal> */}
        </div>
    );

    async function createNewInvoiceType() {

        if (newInvoiceType == "") {
            showNotification("Enter New Invoice Type", "Enter New Invoice", "error")
            return;
        }

        let data = {
            expenseType: newInvoiceType
        }

        await apiService.addInvoiceType(data).then((result) => {

            let data = result.data

            if (data?.isSuccessful) {
                showNotification("Added New Invoice Type", "Added New Invoice Type", "success");
                getInvoiceTypes();
                getUsers();
                setNewInvoiceType("");
                // setShowInvoiceTypeModal(false);
            }
        });
    }

    function getNextInvoiceTime() {
        let nextInv;

        if (recurringInvoiceType == 0) {
            return <Fragment />;
        } else if (recurringInvoiceType == 1) {
            nextInv = issuedDate.add(1, "month").format("dddd, Do MMM YYYY");
        } else if (recurringInvoiceType == 2) {
            nextInv = issuedDate.add(1, "week").format("dddd, Do MMM YYYY");
        } else if (recurringInvoiceType == 3) {
            nextInv = issuedDate.add(1, "year").format("dddd, Do MMM YYYY");
        } else if (recurringInvoiceType == 4) {
            nextInv = issuedDate.add(1, "quarter").format("dddd, Do MMM YYYY");
        }

        return (
            <span style={{ fontSize: 14, fontWeight: "normal" }}>
                Next Recurring Date:{" "}
                <span style={{ fontWeight: "bold" }}>{nextInv}</span>
            </span>
        );
    }

    function getTotalInvoiceValue() {

        let total = 0;

        invoiceItems.forEach((item) => {
            total += item.amount;
        });

        total += total * (serviceChargesPercentage / 100);
        total += total * (vatPercentage / 100);
        total -= total * (discountPercentage / 100)

        total = roundOff(total, 2)

        if (isNaN(total)) {
            setAmount(0);
        } else {
            setAmount(total);
        }

    }

    function clear() {
        setInvoiceItems([{ description: "", amount: 0 }]);
        setSelectedUserId(0);
        setAmount(0);
        setIssuedDate(moment());
        setDueDate(moment().add(7, "d"));
        setVatPercentage(0);
        setServiceCharges(0);
        setDiscountPercentage(0);
        setNewInvoiceType("");
    }

    async function handleCreate() {

        if (amount <= 0) {
            showNotification("Error", "Total can not be less than zero", "error");

            return;
        }

        if (selectedUserId == 0) {
            showNotification("Error", "Please select a user", "error");
            return;
        }
        if(invoiceType==0){
            showNotification("Error", "Please select an invoice type", "error");
            return;
        }

        setIsLoading(true);
        try {
            let data = {
                createdForId: selectedUserId,
                issuedDate: issuedDate,
                dueDate: dueDate,
                vatPercentage: vatPercentage,
                ServiceChargePercentage: serviceChargesPercentage,
                discountPercentage: discountPercentage,
                lateFeePercentage: lateFeePercentage,
                amount: amount,
                recurringStatus: recurringInvoiceType,
                invoiceItems: invoiceItems,
                invoiceTypeId: invoiceType
            };

            await apiService.createPaymentInvoice(data).then((result) => {

                if (result) {
                    showNotification("Success", "Invoice created", "success");
                    clear();
                }

            }).catch((err) => {
                showNotification("Error", "Error Creating Invoice", "error");
            });

            props.onCreateInvoice();

        } catch (error) {
            handleErrors(error);
        }

        setIsLoading(false);
    }
    function handleClearButton() {
        clear();
    }
}
