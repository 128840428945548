import moment from "moment";
import React from "react";
import { Fragment } from "react";
import { splitWord, stringToColor } from "../../services/HelperMethods";

import "./AppCalendar.scss";

export default function AppCalendar(props: { bookings?: any[]; weekNumber: number; onBookingClick?: CallableFunction; }) {
    return (
        <div>
            <div className="app-calendar">
                {getWeekDays(props.weekNumber).map((d, i) => {
                    return (
                        <div className="week-row" key={i}>
                            <div className={"title " + (d.isSame(moment(), "d") ? "same-day" : "")}>
                                {d.format("ddd DD")}
                            </div>
                            <div>{getBookingForDay(d)}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );

    function getBookingForDay(date: moment.Moment) {
        return (
            <div>
                {props.bookings
                    ? (props.bookings?.map((booking) => {
                        if (!date.isSame(moment.utc(booking?.bookedDate).local(), "d")) {
                            return <Fragment key={booking.id} />;
                        }

                        return (
                            <div onClick={() => { props.onBookingClick?.(booking); }} key={booking?.id}
                                className="booking-box cursor-pointer"
                                style={{
                                    backgroundColor: stringToColor(booking?.amenityName, true),
                                    color: stringToColor(booking?.amenityName),
                                }}>
                                <div className="amenity-name">{booking?.amenityName}</div>
                                <div style={{ whiteSpace: "nowrap" }} className={"booking-time"}>
                                    <i className="far fa-clock" />
                                    <span>
                                        {moment.utc(booking?.startTime).local().format("LT")}
                                        {" - "}
                                        {moment.utc(booking?.endTime).local().format("LT")}
                                    </span>
                                </div>
                                <div className="booked-by">
                                    <i className="fas fa-user" />
                                    <span style={{ whiteSpace: "nowrap" }}>{booking?.bookedBy?.name}</span>
                                </div>
                                <div className="amenity-type">
                                {booking?.payments?.[0]?.paymentStatus 
                                ? splitWord(booking.payments[0].paymentStatus) : "pending payment"}
                                </div>
                            </div>
                        );
                    }))
                    : (<Fragment />)
                }
            </div>
        );
    }

    function getWeekDays(weekNumber: number) {
        let weekStr = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

        let momentDates = [] as moment.Moment[];

        weekStr.forEach((d) => {
            momentDates.push(moment().day(d).week(weekNumber));
        });

        return momentDates;
    }
}
