import React, { useEffect, useState, useRef } from "react";
import "./Styles/AdminRefundTicketStyles.css";
import ApiService from "../../../services/ApiService";
import TickCircle from "../../../components/TickCircle/TickCircle";
import { handleErrors, showNotification } from "../../../services/HelperMethods";

function AdminRefundTickets() {
    const apiService = new ApiService();
    const [tickets, setTickets] = useState<any[]>([]);
    const [filteredTickets, setFilteredTickets] = useState<any[]>([]);
    const [selectedTicket, setSelectedTicket] = useState<any>(null);
    const [adminMessage, setAdminMessage] = useState<string>("");
    const [status, setStatus] = useState<string>("");
    const [orderIdFilter, setOrderIdFilter] = useState<string>("");
    const adminMessageRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        fetchRefundTickets();
    }, []);
    useEffect(() => {
        if (selectedTicket) {
            setAdminMessage(selectedTicket.adminMessage || "");
        }
    }, [selectedTicket]);

    async function fetchRefundTickets() {
        try {
            const result = await apiService.getRefundTickets();
            setTickets(result.data.allRefundTickets);
            setFilteredTickets(result.data.allRefundTickets);
            console.log(result.data.allRefundTickets);
        } catch (error) {
            handleErrors(error);
        }
    }

    async function update() {
        const updatedAdminMessage = adminMessageRef.current?.value || adminMessage || selectedTicket.adminMessage;
        const updatedStatus = status || (selectedTicket ? selectedTicket.refundStatus : "");

        if (selectedTicket) {
            try {
                const data = {
                    refundTicketId: selectedTicket.id,
                    refundTicketStatus: updatedStatus,
                    adminMessage: updatedAdminMessage,
                };

                const response = await apiService.updateRefundTickets(data);
                if (response.data?.error === null) {
                    showNotification("Update successful", "Ticket updated successfully", "success");
                    fetchRefundTickets();
                    setSelectedTicket(null);
                } else {
                    showNotification("Update Failed", "Update Failed", "error");
                }
            } catch (error) {
                handleErrors(error);
            }
        }
    }

    const filterTickets = () => {
        if (orderIdFilter) {
            const filtered = tickets.filter(ticket => ticket.orderId.toString().includes(orderIdFilter));
            setFilteredTickets(filtered);
        } else {
            setFilteredTickets(tickets);
        }
    };

    const clearInput = () => {
        if (adminMessageRef.current) {
            adminMessageRef.current.value = "";
        }
    };

    const TicketDrawer = ({ ticket, onClose }) => {
        return (
            <div className="drawer-container">
                <div className="drawer-header">
                    <h2>Ticket Details</h2>
                    <div className="icon-close cursor-pointer" onClick={onClose}><TickCircle icon="close" large /></div>
                </div>
                <div className="drawer-content">
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 20, backgroundColor: "#f2f2f2", padding: 20 }}>
                        <p><strong>Name:</strong> {ticket.userName}</p>
                        <p style={{ marginRight: 25 }}><strong>User Id:</strong> {ticket.userId}</p>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 20, backgroundColor: "#f2f2f2", padding: 20 }}>
                        <p style={{ display: "flex", flexDirection: "column" }}><strong>Phone Number:</strong> {ticket.phoneNumber}</p>
                        <p style={{ display: "flex", flexDirection: "column" }}><strong>Order ID:</strong> {ticket.orderId}</p>
                        <p style={{ display: "flex", flexDirection: "column", marginRight: 15 }}><strong>Status:</strong> {ticket.refundStatus === 0 ? "Active" : ticket.refundStatus === 2 ? "Canceled by user" : ticket.refundStatus === 1 ? "Rejected" : ticket.refundStatus === 3 ? "Completed" : ticket.refundStatus}</p>
                    </div>

                    <div style={{ marginBottom: 20, backgroundColor: "#f2f2f2", padding: 20 }}>
                        <p style={{ marginBottom: 10 }}><strong>Email:</strong> {ticket.email}</p>
                        <p><strong>Building:</strong> {ticket.building}</p>
                    </div>

                    <div style={{ marginBottom: 20, backgroundColor: "#f2f2f2", padding: 20, display: "flex", flexDirection: "column" }}>
                        <div style={{ marginBottom: 10, display: "flex", flexDirection: "column", whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                            <p><strong>Description:</strong> {ticket.description}</p>
                        </div>
                    </div>

                    <div style={{ marginBottom: 20, backgroundColor: "#f2f2f2", padding: 20, display: "flex", flexDirection: "column" }}>
                        <div style={{ marginBottom: 10, display: "flex", flexDirection: "column", whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                            <p><strong>Admin Message:</strong> {ticket.adminMessage}</p>
                        </div>
                    </div>

                    <div style={{ marginBottom: 20, backgroundColor: "#f2f2f2", padding: 20, display: "flex", flexDirection: "row" }}>
                        <div>
                            <p><strong>Item:</strong> {ticket.item}</p>
                        </div>
                    </div>

                    <div style={{ marginBottom: 20, backgroundColor: "#f2f2f2", padding: 20, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <select
                            onChange={(e) => setStatus(e.target.value)}
                            value={status}
                        >
                            <option value="">Keep current</option>
                            <option value="Active">Active</option>
                            <option value="Rejected">Rejected</option>
                            <option value="Completed">Completed</option>
                        </select>
                    </div>

                    <div style={{ backgroundColor: "#f2f2f2", display: "flex", flexDirection: "row", justifyContent: "center", alignContent: "center" }}>
                        <input
                            ref={adminMessageRef}
                            type="text"
                            style={{ justifyContent: "flex-start", alignItems: "center", borderRadius: 5, paddingTop: 15, paddingBottom: 15, paddingLeft: 40, paddingRight: 40 }}
                            placeholder="Admin message"
                            defaultValue={adminMessage}
                        />
                        <div>
                            <button onClick={clearInput}>
                                Clear
                            </button>
                        </div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginBottom: 20, backgroundColor: "#f2f2f2", padding: 20 }}>
                        <button onClick={update} style={{ width: "100%", marginLeft: 20, marginRight: 20, borderRadius: 5, height: 35, backgroundColor: "#FFBE46" }}>Update</button>
                    </div>
                </div>
            </div>
        );
    };

    const handleTicketClick = (ticket) => {
        setSelectedTicket(ticket);
    };

    const handleCloseDrawer = () => {
        setSelectedTicket(null);
    };

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 20 }}>
                <p>All Tickets</p>
                <div>
                    <input
                        type="text"
                        value={orderIdFilter}
                        onChange={(e) => setOrderIdFilter(e.target.value)}
                        placeholder="Filter by Order ID"
                        style={{ marginRight: 10 }}
                    />
                    <button onClick={filterTickets}>Filter</button>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Order ID</th>
                        <th>Phone Number</th>
                        <th>Reason</th>
                        <th>Item</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredTickets.map((ticket, index) => (
                        <tr key={index} onClick={() => handleTicketClick(ticket)}>
                            <td>{ticket.userName}</td>
                            <td>{ticket.orderId}</td>
                            <td>{ticket.phoneNumber}</td>
                            <td>{ticket.reason}</td>
                            <td>{ticket.item.length > 20 ? `${ticket.item.substring(0, 15)}...` : ticket.item}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {/* Render TicketDrawer if selectedTicket is not null */}
            {selectedTicket && <TicketDrawer ticket={selectedTicket} onClose={handleCloseDrawer} />}
        </div>
    );
}

export default  AdminRefundTickets;