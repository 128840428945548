import { toast } from "react-toastify";

export function handleErrors(err) {
  if (err?.response?.data?.error && !err?.response?.data?.error?.errorType) {
    showNotification(
      err?.response?.data?.error?.type,
      err?.response?.data?.error?.message,
      "error"
    );
  } else if (err?.response?.data?.errors) {
    for (const [key, value] of Object.entries(err.response?.data?.errors)) {
      let title = key as any;
      let message = (value as any)[0] as any;
      showNotification(title, message, "error");
    }
  } else if (err?.response?.data?.error?.errorType) {
    showNotification(
      "Error",
      err?.response?.data?.error?.errorMessage,
      "error"
    );
  } else if (err?.response?.status == 401) {
    showNotification(
      "Login Expired",
      "Your Login Session Expired! Please login again",
      "info"
    );
  } else if (err?.response?.status == 403) {
    showNotification(
      "Login Error",
      "You can not access this resource",
      "error"
    );
  } else {
    showNotification("Network Error", "Network error! try again", "error", {
      autoClose: false,
    });
  }

  console.error(err, err.response);
}

export function roundOff(num, decimalPlaces) {
  return Number(
    Math.round(parseFloat(num + "e" + decimalPlaces)) + "e-" + decimalPlaces
  );
}

export default function ordinalSuffixOf(i: number) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}

export function getDeviceCode() {
  let deviceCode = localStorage.getItem("deviceCode");
  if (deviceCode == null) {
    localStorage.setItem("deviceCode", uuidv4());
    deviceCode = localStorage.getItem("deviceCode");
  }
  return deviceCode!;
}

export function handleLoginErrorNavigation(error, email, router) {
  // save temporary token
  if (error.response?.data?.temporaryToken) {
    saveToken(error.response?.data?.temporaryToken);
  }

  if (error.response?.data?.error?.errorType == "user_number_confirmation") {
    router.push("verify-phone");
  } else if (
    error.response?.data?.error?.errorType == "user_device_confirmation"
  ) {
    showNotification(
      "Device Registration",
      "We sent an email to " + email,
      "info"
    );
    router.push("verify-account");
  } else if (
    error.response?.data?.error?.errorType == "user_condominium_required"
  ) {
    showNotification(
      "Please complete the registration though LIWE APP",
      error.response?.data?.error?.errorMessage,
      "info"
    );
    // router.push("select-apartment");
  } else if (
    error.response?.data?.error?.errorType == "user_document_required"
  ) {
    showNotification(
      "Please complete the registration though LIWE APP",
      error.response?.data?.error?.errorMessage,
      "info"
    );
    // router.push("passport-upload");
  } else if (
    error.response?.data?.error?.errorType == "active_building_required"
  ) {
    showNotification(
      "Please complete the registration though LIWE APP",
      error.response?.data?.error?.errorMessage,
      "info"
    );
    // router.push("select-condominium");
  } else if (
    error.response?.data?.error?.errorType == "user_building_not_active"
  ) {
    router.push("verification-result");
  } else if (error.response?.data?.error?.errorType == "multiple_apartment") {
    saveToken(error?.response.data?.token);//save temp token
    router.push("/start/select-condominium");
  } else {
    return false;
  }

  return true;
}

export function saveToken(token) {
  localStorage.setItem("jwt", token);
}

export function getToken() {
  return localStorage.getItem("jwt");
}

export function removeToken() {
  return localStorage.removeItem("jwt");
}

export var stringToColor = function (str, isLightColor = false) {
  var hash = 0;

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  var colour = "#";

  for (let i2 = 0; i2 < 3; i2++) {
    var value = (hash >> (i2 * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }

  if (isLightColor) {
    return colour + "0D";
  }

  return colour;
};

export function formatMoney(x) {
  if (x == null) {
    return "";
  }
  return parseFloat(x)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function uuidv4() {
  return "xxxx-4xxx-yxxx".replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function getNewTicketId() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : r & 0x3 || 0x8;
    return v.toString(16);
  });
}

export function showNotification(
  title,
  message,
  type: "success" | "error" | "info",
  options = {}
) {
  let tst =
    type == "success"
      ? toast.success
      : type == "error"
        ? toast.error
        : toast.info;

  tst(message, options);
}

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    }
  });

export function weekList() {
  return [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
}

export function splitWord(word = "") {
  let wordArr = word.split(/(?=[A-Z])/);

  let spiltWord = "";

  for (var i = 0; i < wordArr.length; i++) {
    spiltWord += " " + wordArr[i];
  }

  return spiltWord.trim();
}

export function monthsMin() {
  var mS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  return mS;
}

export function getUserType(type) {
  if (type == 0) {
    return "EntityAdmin";
  } else if (type == 1) {
    return "ApartmentOwner";
  } else if (type == 2) {
    return "Tenant";
  } else if (type == 3) {
    return "Child";
  } else if (type == 4) {
    return "User";
  } else if (type == 5) {
    return "Guard";
  } else if (type == 6) {
    return "Cleaning";
  } else if (type == 7) {
    return "Maintenance";
  } else if (type == 8) {
    return "Accountant";
  } else if (type == 9) {
    return "HelpDesk";
  } else if (type == 10) {
    return "Engineer";
  } else if (type == 11) {
    return "Administrator";
  } else if (type == 12) {
    return "LIWE Admin";
  } else if (type == 13) {
    return "RecreationalOfficer";
  } else if (type == 14) {
    return "CleaningSupervisor";
  }
  return "no active building";
}

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export function getApartmentName(tower: string, floor: string, doorNumber: string) {
  if (tower) {
    return tower + " - " + floor + " - " + doorNumber
  }

  return floor + " - " + doorNumber;
}