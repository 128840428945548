import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useHistory } from "react-router-dom";
import ApiService from "../../services/ApiService";
import { UserDetailsContext } from "../../store/Store";
import ProfileImage from "../ProfileImage/ProfileImage";
import { Drawer, message } from "antd";
import { showNotification } from "../../services/HelperMethods";
import moment, { duration } from "moment";
import FirebaseServices from "../../services/FirebaseServices";
import addNotification from "react-push-notification";
import "antd/dist/antd.css";
import "./Header.scss";

export default function Header() {
  const apiService = new ApiService();

  const firebaseServices = new FirebaseServices();

  const [user, setUser] = useContext(UserDetailsContext);
  const [firebaseNotification, setFirebaseNotification] = useState<any[]>([]);
  const history = useHistory();

  const [menuOpen, setMenuOpen] = useState("close");
  const [visible, setVisible] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [messageCount, setMessageCount] = useState(0);
  const [localNotificationCount, setLocalNotificationCount] = useState(0);
  const onClose = () => {
    setVisible(false);
  };

  function getMessageCount() {
      var count = 0; //If updated, it starts from 0 again
      firebaseServices
        .getDb()
        .collection("users")
        .doc(user?.firebaseId)
        .onSnapshot((snapshot) => {
          let data = snapshot.data();
          data?.UnreadMessagesCountByUser?.forEach((item) => {
            if (item.UnreadMessageCount > 0) {
              count += item.UnreadMessageCount;
            }
          });
          const notificationCount = data?.NotificationCount;
          setMessageCount(count);
          count = 0;
          setNotificationCount(notificationCount);
        });
  }

  const getFirebaseNotifications = () => {
    firebaseServices.getNotifications(user?.firebaseId, (data) => {
      if (data?.length != firebaseNotification?.length) {
        setFirebaseNotification(data);
        setLocalNotificationCount(data?.length);
      }
    });
  };

  const removeFirebaseNotifications = (id) => {
    let newNotifications = firebaseNotification.filter(
      (notification: any) => notification?.id != id
    );
    setFirebaseNotification(newNotifications);
    firebaseServices.updateNotifications(user?.firebaseId, newNotifications);
  };

  const removeAllNotifications = (data) => {
    let newNotifications = firebaseNotification.filter(
      (notification: any) => notification?.data != data
    );
    firebaseServices.updateNotifications(user?.firebaseId, newNotifications);
    setFirebaseNotification([]);
  };

  useEffect(() => {
    firebaseServices.initFirebase();
  },[]);

  useEffect(() => {
    if(!user?.firebaseId ){
      return;
    }
    getMessageCount();
    getFirebaseNotifications();
  },[user?.firebaseId])

  useEffect(() => {
    if (
      firebaseNotification?.length > 0 &&
      firebaseNotification?.length > localNotificationCount
    ) {
      addNotification({
        title: "Liwe Communities",
        subtitle: firebaseNotification[0]?.header,
        message: firebaseNotification[0]?.body,
        native: true,
        duration: 4000,
        onClick: () => {
          window.focus();
        },
      });
    }
  }, [firebaseNotification?.length]);

  useEffect(()=>{
    const handleClickOutside =(event)=>{
      const menu = document.querySelector(".toggle-menu");
      if(menu && !menu.contains(event.target)){
          setMenuOpen("close");
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return ()=>{
      document.removeEventListener("mousedown", handleClickOutside);
    }
  },[]);

  function getNotifications(isDrawerVisible) {
    if (isDrawerVisible) {
      firebaseServices
        .getDb()
        .collection("users")
        .doc(user?.firebaseId)
        .update({
          NotificationCount: 0,
        });

      apiService
        .getNotification()
        .then((result) => {
          let data = result.data;
          setNotifications(data?.unreadNotifications);
        })
        .catch(() => {
          showNotification("Unexpected Error", "Unexpected Error", "error");
        });
    }
  }

  function ClearAll(notification) {
    return (
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <button
          className="noti-clearbtn"
          style={{
            background: "transparent",
            borderRadius: 15,
            color: "#4BBB46",
            border: "none",
            fontSize: 15,
            fontWeight: "500",
          }}
          onClick={() => {
            removeAllNotifications(notification?.data);
          }}
        >
          Clear All
        </button>
      </div>
    );
  }

  return (
    <div id="header-wrap" className="header-wrap">
      
      <div className="search-wrap">
        {/* <i className="fas fa-search">dd</i>
                <input className="search" placeholder="Search the System" /> */}
      </div>
      <div className="logowrapper">
      {/* <div className="logo">
          <img src="/images/logo.png" />
        </div> */}

      <div className="profile-wrap">
        <Link to="/messages" className="cursor-pointer p-2 noti-btn">
          <i className="fas fa-envelope" />
          {messageCount > 0 ? (
            <div className="noti-dot">
              {messageCount > 9 ? "9+" : messageCount}
            </div>
          ) : (
            <Fragment />
          )}
        </Link>

        {/* bell icon */}
        <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
          {" "}
          <button
            className="noti-btn noti-bell"
            style={{ borderColor: "#f0f8ff00", background: "#f0f8ff00" }}
            onClick={() => {
              setVisible(true);
            }}
          >
            <i className="fas fa-bell" />
            {firebaseNotification?.length ||
            firebaseNotification?.length > 0 ? (
              <span className="noti-dot">
                {firebaseNotification?.length > 9
                  ? "9+"
                  : firebaseNotification?.length}
              </span>
            ) : (
              <Fragment />
            )}
          </button>
        </div>

        <div
          className="name-image cursor-pointer"
          onClick={() => {
            if (menuOpen == "open") {
              setMenuOpen("close");
            } else {
              setMenuOpen("open");
            }
          }}
        >
          <ProfileImage
            style={{ width: 40, height: 40 }}
            src={user?.profileImage}
          />

          <div className="name-wrap">
            <div className="name">
              {user?.firstName} {user?.lastName}
            </div>
            <i className="fas fa-angle-down"></i>
            <div className={"toggle-menu " + menuOpen}>
              <Link to="/start/login/true">
                <div className="menu-item">Logout</div>
              </Link>
              <Link to="/start/select-condominium">
                <div className="menu-item">Switch Apartment</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      </div>

      <Drawer
        title="Notifications"
        headerStyle={{ background: "#ffffff" }}
        drawerStyle={{ background: "#ffffff" }}
        closable={false}
        placement="right"
        onClose={onClose}
        visible={visible}
        afterVisibleChange={(isDrawerVisible) => {
          getNotifications(isDrawerVisible);
        }}
      >
        <ClearAll />

        {firebaseNotification?.length ? (
          firebaseNotification
            ?.sort((a, b) =>
              moment
                .utc(b.createdDate.seconds * 1000)
                .diff(moment.utc(a.createdDate.seconds * 1000))
            )
            .map((notification, index) => {
              return (
                <div
                  style={{
                    marginTop: 10,
                    backgroundColor: "transparent",
                    borderRadius: 5,
                    padding: 10,
                    display: "flex",
                    color: "#000",
                    justifyContent: "space-between",
                  }}
                  key={index}
                >
                  <div>
                    <div style={{ fontSize: "14px", fontWeight: "bold" }}>
                      {notification?.header}
                    </div>
                    <div style={{ fontSize: "12px" }}>{notification?.body}</div>
                    <div style={{ fontSize: "10px", fontWeight: "bold" }}>
                      {moment
                        .utc(notification?.createdDate.seconds * 1000)
                        .fromNow()}
                    </div>
                  </div>
                  <div
                    onClick={() =>
                      removeFirebaseNotifications(notification?.id)
                    }
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <i className="fas fa-times" />
                  </div>
                </div>
              );
            })
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#80808073",
              fontSize: "1rem",
            }}
          >
            No new activity
          </div>
        )}
      </Drawer>
    </div>
  );
}
