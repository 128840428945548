import { Link, useParams } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import ordinalSuffixOf, { getApartmentName, handleErrors, showNotification, splitWord, } from "../../services/HelperMethods";
import ApiService from "../../services/ApiService";
import Drawer from "../../components/Drawer/Drawer";
import AppButton from "../../components/AppButton/AppButton";
import moment from "moment";
import DrawerHeader from "../../components/Drawer/DrawerHeader/SideDrawerHeader";
import Navbar from "../../components/Navbar/Navbar";
import AppLoading from "../../components/AppLoading/AppLoading";
import DrawerBody from "../../components/Drawer/DrawerBody";
import DrawerFooter from "../../components/Drawer/DrawerFooter";
import PdfIcon from "../../assets/images/PDF.png"
import "./Users.scss";
import Filter from "../../components/Filter/Filter";
import PaymentFileInput from "../../components/FileInput/PaymentFileInput";
import FirebaseServices from "../../services/FirebaseServices";
import AddUserDrawer from "../../components/AddUser/AddUserDrawer";

export default function Users() {

  const apiService = new ApiService();
  const firebaseServices = new FirebaseServices();

  const [users, setUsers] = useState([] as any[]);
  const [selectedUser, setSelectedUser] = useState(null as any);
  const [isLoading, setIsLoading] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  // const [name, setName] = useState("All");
  // const [email, setEmail] = useState("All");
  const [userType, setUserType] = useState("All");
  const [userStatus, setUserStatus] = useState("All");
  const [userTypeList, setUserTypeList] = useState([] as any[])
  const [userStatusList, setUserStatusList] = useState([] as any[])
  const [isFiltered, setIsFiltered] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([] as any[]);
  const [searchedUsers, setSearchedUsers] = useState([] as any[]);
  const [showFilter, setShowFilter] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const [selectedDocument, setSelectedDocument] = useState(0);
  const [uploadedDocument, setUploadedDocument] = useState("");
  const [uploadedDocumentType, setUploadedDocumentType] = useState("");

  const [selectedItem, setSelectedItem] = useState(null);
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  
  const onClose = () => {
    setVisible(false);
  };
  const statusOrder = {
    PendingApproval: 1,
    RequestToDeleteAccount: 2,
    Active: 3,
    Disabled: 4,
    ApplicationReject: 5,
    PendingPayment: 6,
    DeletedByUserRequest: 7,
  };

  let { id } = useParams() as any;

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
  }, [searchedUsers]);

  useEffect(() => {
    if (id) {
      getUsers();
    }
  }, [id]);

  useEffect(() => {
    if (users.length > 0) {
      getLists();
    }
  }, [users]);

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  function handleItemClick(item: any){
    setSelectedItem(item);  
  }

  function getLists() {

    var userTypesArr = [] as any[];
    var userStatusArr = [] as any[];

    if (users.length > 0) {
      users.forEach(user => {

        if (user?.userType) {
          userTypesArr.push(user?.userType)
        }

        if (user?.status) {
          userStatusArr.push(user?.status)
        }
      });
    }

    setUserTypeList(userTypesArr.filter(onlyUnique))
    setUserStatusList(userStatusArr.filter(onlyUnique))
  }

  async function getUsers() {
    setIsLoading(true);

    try {
      let result = (await apiService.getUsersGroupByBuilding()) as any;

      result = result.data;
      setUsers(result.users);
      setSearchedUsers(result.users);

      if (id) {
        let user = result.users.find((u) => u.id == id);

        if (user) {
          setSelectedUser(user);
        }
      }

    } catch (error) {
      handleErrors(error);
    }

    setIsLoading(false);
  }

  function filter() {

    setIsFiltered(true)

    setShowFilter(false);

    var filtered = [] as any;

    if (userType == "All" && userStatus == "All") {

      filtered = users;

      setFilteredUsers(filtered)
      setSearchedUsers(filtered);
    } else {

      // if (name != "All") {
      //   filtered = users.filter(function (user) {
      //     return user?.name == name;
      //   });
      // }

      // if (email != "All") {
      //   if (filtered.length > 0) {
      //     filtered = filtered.filter(function (user) {
      //       return user?.email == email;
      //     });
      //   } else {
      //     filtered = users.filter(function (user) {
      //       return user?.email == email;
      //     });
      //   }
      // }

      if (userType != "All") {
        if (filtered.length > 0) {
          filtered = filtered.filter(function (user) {
            return user?.userType == userType;
          });
        } else {
          filtered = users.filter(function (user) {
            return user?.userType == userType;
          });
        }
      }

      if (userStatus != "All") {
        if (filtered.length > 0) {
          filtered = filtered.filter(function (user) {
            return user?.status == userStatus;
          });
        } else {
          filtered = users.filter(function (user) {
            return user?.status == userStatus;
          });
        }
      }

      setFilteredUsers(filtered)
      setSearchedUsers(filtered);
    }

    if (filtered.length == 0) {
      showNotification("Filter Returned No Results", "Filter Returned No Results", "error")
    }
  }

  function clear() {
    // setName("All")
    // setEmail("All")
    setUserType("All")
    setUserStatus("All")
    setFilteredUsers([])
    setIsFiltered(false);
    setShowFilter(false);
    getUsers();
  }

  async function uploadDocument() {
    try {
      setIsLoading(true);

      if (uploadedDocument == "") {
        showNotification("Please Upload Image", "Please Upload Image", "error");
        setIsLoading(false);
        return;
      }

      let data = {
        id: selectedUser?.id,
        url: uploadedDocument,
        type: selectedDocument
      }

      apiService.uploadImage(data).then((result) => {
        let data = result.data

        if (data?.isSuccessful) {
          showNotification("Document Saved", "Document Saved", "success");

          id = selectedUser?.id;
          setUploadedDocument("");

          getUsers();
        } else {
          showNotification("Unexpected Error", "Unexpected Error", "error");
        }
      })

      setIsLoading(false);
    } catch (error) {
      showNotification("Unexpected Error", "Unexpected Error", "error");
      setIsLoading(false);
    }
  }

  function handleFilter(array: any[], searchedWord: string) {
    let searchedArray = [] as any[];
    array.forEach((user) => {
      if (user?.floor?.toString().toLowerCase().includes(searchedWord.toLowerCase())
        || user?.name.toLowerCase().includes(searchedWord.toLowerCase())
        || user?.apartment?.doorNumber?.toString().toLowerCase().includes(searchedWord.toLowerCase())
        || user?.email.toLowerCase().includes(searchedWord.toLowerCase())
        || user?.phoneNumber?.toString().includes(searchedWord.toLowerCase())
        || splitWord(user?.status).toString().toLowerCase().includes(searchedWord.toLowerCase())
        || splitWord(user?.userType).toLowerCase().includes(searchedWord.toLowerCase())) {
        searchedArray.push(user);
      }
    });
    return searchedArray;
  }

  function handleSearch(searchedWord: string) {
    setIsLoading(true);
    if (searchedWord.trim() === "") {
      if (filteredUsers.length > 0 || isFiltered) {
        setSearchedUsers(filteredUsers);
      } else {
        setSearchedUsers(users);
      }
      setIsLoading(false);
      return;
    }

    let searchedArray = [] as any[];
    if (filteredUsers.length > 0 || isFiltered) {
      searchedArray = handleFilter(filteredUsers, searchedWord);
    } else {
      searchedArray = handleFilter(users, searchedWord);
    }
    setSearchedUsers(searchedArray);
    setIsLoading(false);
  }

  
  const sortedUsers = searchedUsers.sort((a, b) => {
    const statusA = statusOrder[a.status] || 0; // Use 0 as a default value if the status is not defined in statusOrder
    const statusB = statusOrder[b.status] || 0;

    // Compare the status values
    return statusA - statusB;
  });

 // Function to extract desired attributes from a user object
const extractAttributes = (user) => {
  const extractedUser = {
      "Name": user.name,
      "Phone Number": user.phoneNumber,
      "Email Address": user.email,
      "User Type": user.userType,
      "User Status": user.status,
      "Door Number": `${user.apartment.location} - ${user.apartment.floor} - ${user.apartment.doorNumber}`,
  };
  return extractedUser;
};

// Create a new list with objects containing only the desired attributes
const modifiedUserList = sortedUsers.map(user => extractAttributes(user));
  
  return (
    <div className="body">

      <h2>Apartments/Users</h2>

      <AppLoading isLoading={isLoading} />

      <Navbar downloadExcel={true} 
       excelData={modifiedUserList}
       fileName="users"
      showSearch hideSettings={true} hideAdd={true} hideFilter={false} onFilterButtonClick={() => { setShowFilter(!showFilter) }}
        onSearchChange={handleSearch} onAddButtonClick={showDrawer} addUser={true}>
        <Link to="/apartments">Apartments</Link>
        <Link className="active" to="/users">Residents</Link>
        <Link to="/staff">Staff</Link>
        {/* <div className="icon add d-flex justify-content-center" onClick={showDrawer}>
          <img src="" className="add-icon" alt="Add User" />
        </div> */}
        
      </Navbar>
      
      <AddUserDrawer visible={visible} onClose={onClose} />
      <Table borderless={true} className="users-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Door Number</th>
            <th>Email Address</th>
            <th>Telephone Number</th>
            <th>User Type</th>
            <th>User Status</th>
            <th>Last Login</th>
          </tr>
        </thead>
        <tbody>
          {sortedUsers.map((user, i) => {
            return (
              <tr 
                key={i} 
                onClick={() => { 
                  setSelectedUser(user); 
                  handleItemClick(user); 
                  setSelectedDocument(0); 
                }} 
                className="clickable" 
                style={{
                  backgroundColor: selectedItem === user ? '#e1fce1' : 'transparent',
                }}
              >
                <td>{user?.name}</td>
                <td>{getApartmentName(user?.apartment?.location, user?.apartment?.floor, user?.apartment?.doorNumber)}</td>
                <td>{user?.email}</td>
                <td>{user?.phoneNumber}</td>
                <td>{user?.userType == "EntityAdmin" ? "Management" : splitWord(user?.userType)}</td>
                <td>{splitWord(user?.status)}</td>
                <td className="font-blue">{user?.lastLogin ? moment.utc(user?.lastLogin).fromNow() : "-"}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Drawer isOpen={selectedUser} className="user-drawer">
        <DrawerHeader onClose={() => { 
          setSelectedUser(null); 
          setSelectedItem(null);
          }} 
          profileImage={selectedUser?.profileImagePath}>
          <div className="name-section">
            <p className="user-name">
              {selectedUser?.name}
            </p>
            <p style={{ fontSize: 14, color: "gray" }}>
              {selectedUser?.phoneNumber}
            </p>
            <p style={{ fontSize: 14, color: "gray" }}>{selectedUser?.email}</p>
          </div>
        </DrawerHeader>
        <DrawerBody>
          <div>
            <div className="apartment-info mt-2">
              {selectedUser?.apartment?.floor && selectedUser?.apartment?.doorNumber
                ? <h4 className="apartment-name">
                  {ordinalSuffixOf(selectedUser?.apartment?.floor)}
                  {" Floor, "}
                  {selectedUser?.apartment?.doorNumber}
                </h4>
                : null}
            </div>
            {/* Below code comment out for NIC Removal */}
            {/* <div>
              {selectedUser?.tenancyDocumentURL
                ? (<div className="document">
                  <i className="far fa-file-alt"></i>
                  <a className="file-name" href={selectedUser?.tenancyDocumentURL} target="_blank" rel="noreferrer">
                    Tenancy Policy
                    <div className="registered-date">
                      Uploaded on{" "}
                      {moment.utc(selectedUser?.registeredDate).local().format("DD MMM YYYY")}
                    </div>
                  </a>
                </div>)
                : (<div className="document">
                  <i className="far fa-file-alt"></i>
                  <div className="file-name">
                    Tenancy Policy
                    <div className="registered-date">No Tenancy Policy Uploaded</div>
                  </div>
                </div>)
              }

              {selectedUser?.passportDocumentURL
                ? (<div className="document">
                  <i className="fas fa-file-contract"></i>
                  <a className="file-name" href={selectedUser?.passportDocumentURL} target="_blank" rel="noreferrer">
                    NIC Scanned Copy
                    <div className="registered-date">
                      Uploaded on{" "}
                      {moment.utc(selectedUser?.registeredDate).local().format("DD MMM YYYY")}
                    </div>
                  </a>
                </div>)
                : (<div className="document">
                  <i className="fas fa-file-contract"></i>
                  <div className="file-name">
                    NIC Scanned Copy
                    <div className="registered-date">No NIC Copy Uploaded</div>
                  </div>
                </div>)
              }

              <div className="document" style={{ flexDirection: "column", alignItems: "baseline", marginTop: "1.5rem" }}>
                <h1 style={{ fontSize: "1rem", marginBottom: "1.5rem" }}>Upload Documents</h1>

                <select className="form-control"
                  onChange={(e) => { setSelectedDocument(parseInt(e.target.value)); }} value={selectedDocument}>
                  <option value={0}>Select Document Type</option>
                  <option value={1}>Tenancy Document</option>
                  <option value={2}>NIC Copy</option>
                </select>

                {selectedDocument != 0
                  ? (<div style={{ display: "flex" }}>
                    {selectedDocument == 1 && selectedUser?.tenancyDocumentURL
                      ? (<img style={{ cursor: "pointer", width: "8rem", height: "10rem", marginTop: "0.5rem", borderRadius: "8px" }}
                        onClick={() => { window.open(selectedUser?.tenancyDocumentURL) }} src={selectedUser?.tenancyDocumentURL} />)
                      : (<Fragment />)
                    }

                    {selectedDocument == 2 && selectedUser?.passportDocumentURL
                      ? (<img style={{ cursor: "pointer", width: "8rem", height: "10rem", marginTop: "0.5rem", borderRadius: "8px" }}
                        onClick={() => { window.open(selectedUser?.passportDocumentURL) }} src={selectedUser?.passportDocumentURL} />)
                      : (<Fragment />)
                    }

                    {uploadedDocument != ""
                      ? (<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <img style={{
                          cursor: "pointer", width: "8rem", height: "10rem", marginTop: "0.5rem",
                          borderRadius: "8px", marginLeft: "0.5rem"
                        }} onClick={() => {window.open(uploadedDocument) }} src={uploadedDocumentType == "pdf"? PdfIcon : uploadedDocument} />
                        <div style={{ cursor: "pointer", marginTop: "0.5rem" }} onClick={() => { setUploadedDocument(""); }}>
                          <i style={{
                            borderRadius: "2rem", background: "red", width: "2rem",
                            height: "2rem", display: "flex", alignItems: "center", marginRight: "0rem"
                          }} className="fas fa-times" />
                        </div>
                        <AppButton style={{ marginTop: "1rem" }} isLoading={isLoading} onClick={() => { uploadDocument(); }}>
                          Save
                        </AppButton>
                      </div>)
                      : (<Fragment />)
                    }

                    {uploadedDocument == ""
                      ? (<div style={{
                        margin: "0.5rem 0.5rem 0rem 0.5rem", display: "flex", background: "#efefef", borderRadius: "8px",
                        justifyContent: "space-evenly", alignItems: "center", width: "8rem", height: "10rem"
                      }}>
                        <span style={{ position: "relative", cursor: "pointer" }} className="other-image-upload-icon-mtp">
                          <PaymentFileInput onChange={async (url) => {
                            setIsLoading(true);
                            var type = url.split(';')[0].split('/')[1];
                            var docURL = await firebaseServices.uploadDoc(url);
                            setUploadedDocumentType(type)
                            setUploadedDocument(docURL);
                            setIsLoading(false);
                          }} />
                          <div style={{ fontSize: "0.8rem", textAlign: "center" }}>Upload New Document</div>
                        </span>
                      </div>)
                      : (<Fragment />)
                    }
                  </div>)
                  : (<Fragment />)
                }
              </div>

              
            </div> */}
            {getActionButtons(selectedUser?.userStatus, selectedUser)}
            <Modal centered onHide={() => { setShowModal(false); }} show={showModal}>
              <Modal.Header closeButton>
                <Modal.Title style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ fontSize: "18px", color: "#222" }}>{"Reason for Application Rejection"}</span>
                  </div>
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div>
                  <div>
                    <input className="form-control mb-1" placeholder="Reject Reason" value={rejectReason}
                      onChange={(e) => { setRejectReason(e.target.value); }} />
                  </div>
                </div>
                <div className="mt-4 text-right w-100">
                  <AppButton onClick={() => { handleApprove(selectedUser.id, 4); setShowModal(false); }}
                    style={{ width: 100, padding: 5, backgroundColor: "#4bbb46", color: "white", marginRight: "0.6rem" }}>
                    {"Reject"}
                  </AppButton>
                  <AppButton onClick={() => { setShowModal(false) }}
                    style={{ width: 100, padding: 5, backgroundColor: "#FEBE4D", color: "white", }}>
                    Cancel
                  </AppButton>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </DrawerBody>
        <DrawerFooter>
        </DrawerFooter>
      </Drawer>

      <Filter children={[
        // {
        //   label: "Name",
        //   type: <select style={{ flex: "2" }} className="form-control"
        //     onChange={(e) => { setName(e.target.value); }} value={name}>
        //     <option value={"All"}>All</option>
        //     {users?.map((user: any, index) => {
        //       return (
        //         <option value={user?.name} key={index}>
        //           {user?.name}
        //         </option>
        //       );
        //     })}
        //   </select>
        // },
        // {
        //   label: "Email",
        //   type: <select style={{ flex: "2" }} className="form-control"
        //     onChange={(e) => { setEmail(e.target.value); }} value={email}>
        //     <option value={"All"}>All</option>
        //     {users?.map((user: any, index) => {
        //       return (
        //         <option value={user?.email} key={index}>
        //           {user?.email}
        //         </option>
        //       );
        //     })}
        //   </select>
        // },
        {
          label: "User Type",
          type: <select style={{ flex: "2" }} className="form-control"
            onChange={(e) => { setUserType(e.target.value); }} value={userType}>
            <option value={"All"}>All</option>
            {userTypeList?.map((type: any, index) => {
              return (
                <option value={type} key={index}>
                  {type == "EntityAdmin" ? "Management" : splitWord(type)}
                </option>
              );
            })}
          </select>
        },
        {
          label: "User Status",
          type: <select style={{ flex: "2" }} className="form-control"
            onChange={(e) => { setUserStatus(e.target.value); }} value={userStatus}>
            <option value={"All"}>All</option>
            {userStatusList?.map((status: any, index) => {
              return (
                <option value={status} key={index}>
                  {splitWord(status)}
                </option>
              );
            })}
          </select>
        }
      ]} isOpen={showFilter} wrapperClass={"filter-area-user"} showClear={true} onFilter={() => { filter() }}
        onClear={() => { clear() }} />
    </div>
  );

  function getActionButtons(status, userBuilding) {
    if (status == 0 || status == 1 || status == 3) {
      return (
        <div className="action-buttons">
          <div className="btn-wrap">
            <div>
              <AppButton style={{ backgroundColor: "#737373", }} onClick={() => { setShowModal(true) }}>
                Reject Application
              </AppButton>
            </div>
            <AppButton onClick={() => { handleApprove(userBuilding.id, 2); }}>
              Approve User
            </AppButton>
          </div>
        </div>
      );
    }

    if (status == 4) {
      return (
        <div className="btn-wrap">
          <AppButton onClick={() => { handleApprove(userBuilding.id, 2); }}>
            Approve User
          </AppButton>
        </div>
      );
    }

    return (
      <AppButton style={{ backgroundColor: "transparent", color: "red", boxShadow: "none", }}
        onClick={() => { handleApprove(userBuilding.id, 3); }} isLoading={false}>
        Disable User
      </AppButton>
    );
  }

  async function handleApprove(id, status) {

    setIsLoading(true);

    try {
      await apiService.changeUserStatus({ userBuildingId: id, status: status, rejectReason });

      getUsers();

      setSelectedUser(null);

      showNotification("Success", "Updated!", "success");
    } catch (error) {
      handleErrors(error);
    }

    setIsLoading(false);
  }
}
