import React, { useCallback, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import AppLoading from "../../components/AppLoading/AppLoading";
import Navbar from "../../components/Navbar/Navbar";
import SideDrawerHeader from "../../components/Drawer/DrawerHeader/SideDrawerHeader";
import Drawer from "../../components/Drawer/Drawer";
import ApiService from "../../services/ApiService";
import { handleErrors, splitWord } from "../../services/HelperMethods";
import moment from "moment";

import "./CompoundSecurity.scss";
import { DatePicker } from "antd";
import AppButton from "../../components/AppButton/AppButton";
import Filter from "../../components/Filter/Filter";

function CompoundSecurity() {
  const apiService = new ApiService();

  const [isLoading, setIsLoading] = useState(false);
  const [securityLog, setSecurityLog] = useState([] as any[]);
  const [searchSecurityLog, setSearchSecurityLog] = useState([] as any[]);
  const [fromDate, setFromDate] = useState(moment().subtract(1, "day"));
  const [toDate, setToDate] = useState(moment());
  const [type, setType] = useState("All");
  const [types, setTypes] = useState([]);
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    getTypes();
    getSecurityLog();
  }, []);

  async function getTypes() {
    await apiService
      .getTypes()
      .then((result) => {
        let data = result.data;
        setTypes(data?.types);
      })
      .catch(() => {});
  }

  async function getSecurityLog() {
    setIsLoading(true);

    setShowFilter(false);

    try {
      let data = {
        fromDate: fromDate,
        toDate: toDate,
        eventType: type,
      };

      await apiService.getSecurityLog(data).then((result) => {
        const sortedArray = result.data?.eventLogs.sort((a, b) => {
          return moment(b?.generatedTime).diff(a?.generatedTime);
        });
        setSecurityLog(sortedArray);
        setSearchSecurityLog(sortedArray);
      });
    } catch (error) {
      handleErrors(error);
    }

    setIsLoading(false);
  }

  function handleSearch(searchedWord: String) {
    setIsLoading(true);

    if (searchedWord.trim() === "") {
      setSearchSecurityLog(securityLog);
      setIsLoading(false);
      return;
    }

    let searchedArray = [] as any[];

    securityLog.forEach((log) => {
      if (
        log?.description.toLowerCase().includes(searchedWord.toLowerCase()) ||
        splitWord(log?.eventType)
          .toLowerCase()
          .includes(searchedWord.toLowerCase()) ||
        moment
          .utc(log?.generatedTime)
          .local()
          .format("LLL")
          .toLowerCase()
          .includes(searchedWord.toLowerCase())
      ) {
        searchedArray.push(log);
      }
    });

    setSearchSecurityLog(searchedArray);
    setIsLoading(false);
  }

  return (
    <div className="body compound-security">
      <AppLoading isLoading={isLoading} />

      <h2>Compound</h2>

      <Navbar
        showSearch
        hideSettings={true}
        hideAdd={true}
        hideFilter={false}
        onFilterButtonClick={() => {
          setShowFilter(!showFilter);
        }}
        onSearchChange={handleSearch}
      >
        <Link className="active" to="/compound-security">
          Event Log
        </Link>
        {/* <Link to="/security-roster">Staff Roster</Link> */}
      </Navbar>
      <Table responsive="lg" borderless={true}>
        <thead>
          <tr>
            <th>Date</th>
            <th>Type</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {searchSecurityLog.map((log, i) => {
            return (
              <tr key={i}>
                <td>{moment.utc(log?.generatedTime).local().format("LLL")}</td>
                <td>{splitWord(log?.eventType)}</td>
                <td>{log?.description}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Filter
        children={[
          {
            label: "From",
            type: (
              <DatePicker
                allowClear={false}
                style={{ flex: "2", margin: "0rem 0.8rem" }}
                className="form-control"
                defaultValue={moment().subtract(1, "day")}
                value={fromDate}
                onChange={(date) => {
                  if (date) {
                    setFromDate(date);
                  }
                }}
              />
            ),
          },
          {
            label: "To",
            type: (
              <DatePicker
                allowClear={false}
                style={{ flex: "2", margin: "0rem 0.8rem" }}
                className="form-control"
                value={toDate}
                defaultValue={moment()}
                onChange={(date) => {
                  if (date) {
                    setToDate(date);
                  }
                }}
                disabledDate={(date) => {
                  let isd = moment(fromDate);
                  return !date || date.isBefore(isd);
                }}
              />
            ),
          },
          {
            label: "Type",
            type: (
              <select
                style={{ flex: "2", margin: "0rem 0.8rem" }}
                className="form-control"
                onChange={(e) => {
                  setType(e.target.value);
                }}
                value={type}
              >
                <option value={"All"}>All</option>;
                {types.map((type: any, index) => {
                  return (
                    <option value={type?.name} key={index}>
                      {splitWord(type?.name)}
                    </option>
                  );
                })}
              </select>
            ),
          },
        ]}
        isOpen={showFilter}
        wrapperClass={"filter-area-compound"}
        showClear={false}
        onFilter={() => {
          getSecurityLog();
        }}
      />
    </div>
  );
}
export default CompoundSecurity;