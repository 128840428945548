import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";

import "./Reporting.scss";

export default function SecurityRosterReport() {
  return (
    <div className="body ">
      <h2>Reports</h2>

      <div>
        <Navbar hideSettings={true} hideAdd={true} hideFilter={true}>
          <Link to="/reports/invoiceAgingReport"> Invoice Aging Report </Link>
          <Link to="/reports/facilityUsageReport">Facility Usage Report</Link>
          <Link to="/reports/visitorReports">Visitor Reports</Link>
          <Link to="#" className="active">Security Roster Reports</Link>
          <Link to="/reports/appUsageReport">App Usage Report</Link>
          <Link to="/reports/transactionReport">Transaction Report</Link>
          <Link to="/reports/supportTicketReport">Support Ticket Report</Link>
        </Navbar>
      </div>

      <div style={{ fontSize: "2rem", textAlign: "center", color: "#8acb87" }}>Coming Soon</div>
    </div>
  );
}
