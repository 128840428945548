import React, { useEffect, useState } from "react";
import AppLoading from "../../components/AppLoading/AppLoading";
import ApiService from "../../services/ApiService";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import { handleErrors } from "../../services/HelperMethods";
import PublicNoticeBox from "../../components/PublicNoticBox/PublicNoticeBox";

import "./PublicNoticesArchive.scss";

export default function PublicNoticesArchive({ history }) {
    const apiService = new ApiService();
    const [notices, setNotices] = useState([] as any[]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getNotices();
    }, []);

    async function getNotices() {
        setIsLoading(true);

        try {
            let result = await apiService.getPublicNotices();

            setNotices(result.data.notices.filter((notice) => {
                return notice?.publishedStatus == "Archived"
            }));
        } catch (error) {
            handleErrors(error);
        }

        setIsLoading(false);
    }

    return (
        <div className="body notices-wrapper">
            <AppLoading isLoading={isLoading} />

            <h2>Notices</h2>

            <Navbar hideSettings={true} hideAdd={true} hideFilter={true}>
                <Link to="/notices/public">Public Notices</Link>
                <Link className="active" to="/notices/archived">Archived</Link>
            </Navbar>

            <div className="row">
                {notices.map((notice, i) => {
                    return (
                        <div style={{ paddingTop: "1rem", paddingBottom: "1rem" }} className="col-md-6" key={i}>
                            <PublicNoticeBox notice={notice} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
