import { DatePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ApiService from "../../services/ApiService";
import { formatMoney, handleErrors, showNotification, roundOff } from "../../services/HelperMethods";
import AppButton from "../AppButton/AppButton";
import Drawer from "../Drawer/Drawer";
import DrawerBody from "../Drawer/DrawerBody";
import DrawerFooter from "../Drawer/DrawerFooter";
import TickCircle from "../TickCircle/TickCircle";

import "./CreateExpense.scss";

export default function CreateExpense(props: { isShow; onClose; onCreateExpense; }) {

    const apiService = new ApiService();

    const [isLoading, setIsLoading] = useState(false);
    // const [showModal, setShowModal] = useState(false);
    const [showExpenseSectionModal, setShowExpenseSectionModal] = useState(false);

    const [expenseTypes, setExpenseTypes] = useState([] as any);
    const [expenseSections, setExpenseSections] = useState([] as any);
    const [apartments, setApartments] = useState([] as any);
    const [issuedDate, setIssuedDate] = useState(moment());
    const [refNumber, setRefNumber] = useState("");
    const [description, setDescription] = useState("");
    const [apartmentId, setApartmentId] = useState(0);
    const [expenseType, setExpenseType] = useState(0);
    const [expenseSection, setExpenseSection] = useState(0);
    const [billAmount, setBillAmount] = useState("0");

    const [newExpenseType, setNewExpenseType] = useState("");
    const [newExpenseSection, setNewExpenseSection] = useState("");

    useEffect(() => {
        getExpenseTypes()
        generateUniqueRefNumber()
    }, [])

    // Function to generate a unique reference number
    function generateUniqueRefNumber() {
        // Generate a unique reference number based on current date/time
        const currentDate = moment().format("YYYYMMDD");
        const randomNumber = Math.floor(Math.random() * 10000);
        const uniqueRefNumber = currentDate + randomNumber.toString().padStart(4, '0');

        setRefNumber(uniqueRefNumber);
    }

    return (
        <div className="create-invoice-wrap">
            <Drawer isOpen={props.isShow}>
                <DrawerBody>
                    <div>
                        <div>
                            <div className="icon-close cursor-pointer mb-2" onClick={() => { props.onClose(); }}>
                                <TickCircle icon="close" large />
                            </div>

                            <h4>Create New Bill</h4>

                            <div style={{ margin: "1rem 0rem" }}>
                                <div className="row mt-2">
                                    <div className="col">
                                        <label>Description</label>
                                        <input value={description} className="form-control" placeholder="Description"
                                            onChange={(e) => { setDescription(e.target.value) }} type="text" />
                                    </div>

                                    <div className="col">
                                        <label>Door Number</label>
                                        <select className="form-control" onChange={(e) => { setApartmentId(parseInt(e.target.value)) }}
                                            value={apartmentId}>
                                            <option value={0}>{"Select Door Number"}</option>
                                            {apartments?.map((apartment, index) => {
                                                return (
                                                    <option key={index} value={apartment?.id}>
                                                        {"Floor " + apartment?.floor + " Door " + apartment?.doorNumber}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div style={{ margin: "1rem 0rem" }}>
                                <div className="row mt-2">
                                    <div className="col">
                                        <label>Issued Date *</label>
                                        <DatePicker className="form-control" value={issuedDate}
                                            onChange={(d) => { if (d) { setIssuedDate(d); } }} />
                                    </div>
                                    <div className="col">
                                        <label>Ref Number *</label>
                                        <input value={refNumber} className="form-control" placeholder="Ref Number"
                                            onChange={(e) => { setRefNumber(e.target.value) }} type="text" />
                                    </div>
                                </div>
                            </div>

                            <div style={{ margin: "1rem 0rem" }}>
                                <div className="row mt-2">
                                    <div className="col">
                                        <label style={{ display: "flex", justifyContent: "space-between" }}>Expense Type *
                                            {/* <span onClick={() => { setShowModal(true) }} style={{
                                                fontWeight: "bold", cursor: "pointer", fontSize: "1rem", color: "#4bbb46"
                                            }}>+</span> */}
                                        </label>
                                        <select className="form-control" onChange={(e) => { setExpenseType(parseInt(e.target.value)) }}
                                            value={expenseType}>
                                            <option value={"0"}>{"Select Expense Type"}</option>
                                            {expenseTypes?.map((expense, index) => {
                                                return (
                                                    <option key={index} value={expense?.id}>{expense?.expense}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div style={{ margin: "1rem 0rem" }}>
                                <div className="row mt-2">
                                    <div className="col">
                                        <label style={{ display: "flex", justifyContent: "space-between" }}>Expense Section *
                                            <span onClick={() => { setShowExpenseSectionModal(true) }} style={{
                                                fontWeight: "bold", cursor: "pointer", fontSize: "1rem", color: "#4bbb46"
                                            }}>+</span>
                                        </label>
                                        <select className="form-control" value={expenseSection}
                                            onChange={(e) => { setExpenseSection(parseInt(e.target.value)) }}>
                                            <option value={"0"}>{"Select Expense Section"}</option>
                                            {expenseSections?.map((expense, index) => {
                                                return (
                                                    <option key={index} value={expense?.id}>{expense?.section}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div style={{ margin: "1rem 0rem" }}>
                                <div className="row mt-2">
                                    <div className="col">
                                        <label>Bill Amount *</label>
                                        <input className="form-control" value={billAmount} placeholder="Bill Amount" type="number"
                                            onChange={(e) => { validateAmount(parseFloat(e.target.value), setBillAmount); }} />
                                    </div>
                                </div>
                            </div>

                            <div className="total-value-field">
                                <label>
                                    <small>Total Bill Value</small>
                                </label>
                                <span style={{ fontSize: 30, marginLeft: 10, color: "#4bbb46", fontWeight: "bold", }}>
                                    <span style={{ fontWeight: "normal" }}>
                                        LKR
                                    </span>{" "}
                                    {formatMoney(billAmount)}
                                </span>
                            </div>
                        </div>
                    </div>
                </DrawerBody>
                <DrawerFooter>
                    <div className="mt-1">
                        <AppButton style={{ marginBottom: 0 }} isLoading={isLoading} onClick={handleCreate}>
                            Create Bill
                        </AppButton>
                    </div>
                </DrawerFooter>
            </Drawer>

            {/* <Modal centered onHide={() => { setShowModal(false); }} show={showModal}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>Add New Expense Type</div>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <input className="form-control" value={newExpenseType}
                            onChange={(e) => { setNewExpenseType(e.target.value); }} />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <AppButton isLoading={isLoading} onClick={() => { createNewExpense() }}>
                        Save &amp; Submit
                    </AppButton>
                </Modal.Footer>
            </Modal> */}

            <Modal centered onHide={() => { setShowExpenseSectionModal(false); }} show={showExpenseSectionModal}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>Add New Expense Section</div>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <input className="form-control" value={newExpenseSection}
                            onChange={(e) => { setNewExpenseSection(e.target.value); }} />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <AppButton isLoading={isLoading} onClick={() => { createNewExpenseSection() }}>
                        Save &amp; Submit
                    </AppButton>
                </Modal.Footer>
            </Modal>
        </div>
    );

    function validateAmount(amount, setter) {
        if (isNaN(amount) || parseFloat(amount) < 0) {
            setter("0")
        } else {
            setter(amount)
        }
    }

    function getExpenseTypes() {

        setIsLoading(true);

        apiService.getExpenseTypes().then((result) => {

            let data = result.data
            const filteredExpenseTypes = data?.expenseTypes.filter((type)=>type.status===true);
            setExpenseTypes(filteredExpenseTypes)

            let apt = data?.apartments;

            apt.sort(function (a, b) {
                if (a.doorNumber < b.doorNumber) { return -1; }
                if (a.doorNumber > b.doorNumber) { return 1; }
                return 0;
            })

            apt.sort(function (a, b) {
                return a.floor - b.floor;
            })

            setApartments(apt)

            setExpenseSections(data?.sections)
        })

        setIsLoading(false);
    }

    function createNewExpense() {

        if (newExpenseType.trim() == "") {
            showNotification("Error", "Enter Expense Type", "error");

            return;
        }

        setIsLoading(true);

        let data = {
            expenseType: newExpenseType
        }

        apiService.addExpenseType(data).then((result) => {

            let data = result.data

            if (data?.isSuccessful) {
                setNewExpenseType("");
                getExpenseTypes();
                // setShowModal(false);

                showNotification("New Expense Type Added", "New Expense Type Added", "success");
            } else {
                showNotification("Error", "Error Adding Expense Type", "error");
            }
        })

        setIsLoading(false);
    }

    function createNewExpenseSection() {

        if (newExpenseSection.trim() == "") {
            showNotification("Error", "Enter Expense Section", "error");

            return;
        }

        setIsLoading(true);

        let data = {
            expenseType: newExpenseSection
        }

        apiService.addExpenseSection(data).then((result) => {

            let data = result.data

            if (data?.isSuccessful) {
                setNewExpenseType("");
                getExpenseTypes();
                setShowExpenseSectionModal(false);

                showNotification("New Expense Section Added", "New Expense Section Added", "success");
            } else {
                showNotification("Error", "Error Adding Expense Section", "error");
            }
        })

        setIsLoading(false);
    }

    async function handleCreate() {

        if (parseFloat(billAmount) <= 0) {
            showNotification("Error", "Total can not be less than zero", "error");

            return;
        }

        if (expenseType == 0) {
            showNotification("Error", "Please select an expense type", "error");

            return;
        }

        setIsLoading(true);

        try {
            let data = {
                issuedDate: issuedDate,
                refNumber: refNumber,
                description: description,
                apartmentId: apartmentId > 0 ? apartmentId : null,
                expenseTypeId: expenseType,
                expenseSectionId: expenseSection,
                billAmount: parseFloat(billAmount)
            };

            await apiService.createExpense(data).then((result) => {

                if (result) {
                    showNotification("Success", "Bill created", "success");
                }

            }).catch((err) => {
                showNotification("Error", "Error Creating Bill", "error");
            });

            props.onCreateExpense();

        } catch (error) {
            handleErrors(error);
        }

        setIsLoading(false);
    }
}
