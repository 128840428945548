import LiweIcon from "../../assets/images/liwe.png";
import "./Style.scss";
import { GiStaryu } from "react-icons/gi";

export default function TermsAndConditions() {
  return (
    <div>
      {/* company icon */}
      <div className="icon">
        <img className="company-icon" src={LiweIcon} />
      </div>
      {/* heading */}
      <div className="heading">Terms and Conditions</div>
      <div className="section">
        {/* section 1 */}
        <div className="section-1">
          <div className="section-heading">1. Introduction</div>
          <div>
            <p>
              These Terms and Conditions (the <strong>“Terms”</strong>) are
              provided by{" "}
              <strong>WE LIVE COMMUNITIES (PRIVATE) LIMITED,</strong> a company
              duly incorporated under the Companies Act 07 of 2007 and bearing
              registration <strong>No. PV00228354,</strong> with its registered
              office at No: 84 Srimath Anagarika Dharmapala Mawatha, Colombo 07,
              Sri Lanka (hereinafter referred to as the{" "}
              <strong>“Principal”</strong>), and the <strong>Client,</strong> a
              Management Corporation managing a Condominium Complex at the given
              address during the online registration, Sri Lanka (sometimes
              referred to as the Condominium Complex).
            </p>
          </div>
        </div>

        {/* section 2 */}
        <div className="section-2">
          <div className="section-heading">
            2. Definitions and Interpretation
          </div>
          <div>
            <ul>
              <li>
                <div className="sub-heading">2.1. Definitions</div>
                <ul>
                  <li>
                    1.1.1. <strong>Terms:</strong> These written terms and all
                    the attachments, appendices, and Schedules hereto.
                  </li>
                  <li>
                    1.1.2. <strong>The Services:</strong> The services specified
                    in Schedule 1 hereto and all other services incidental or in
                    furtherance thereto and such other services as may be agreed
                    to by the Parties from time to time pertaining to LIWE.
                  </li>
                  <li>
                    1.1.3. <strong>Fee:</strong> The Fee for the services
                    provided by Principal under these Terms as determined in
                    terms of Clause 3.
                  </li>
                  <li>
                    1.1.4. <strong>Materials:</strong> All the comprehensive ERP
                    system covering the invoicing, receipts, Dashboard
                    monitoring all affairs of the condominium, Bank
                    Reconciliation, Vendor Payment System, Payroll Management,
                    Expenses, Accounting Platform, Inventory Management,
                    reports, the specification, documents, papers, information,
                    data, and disks (in whatever form or medium or format),
                    wherever such Material is located or stored, and all copies
                    of the Materials.
                  </li>
                  <li>
                    1.1.5. <strong>Term:</strong> The term of these Terms as
                    determined in terms of Clause 10.1.
                  </li>
                  <li>
                    1.1.6. <strong>LIWE:</strong> The specific Mobile
                    Application/Website and other
                    instrument/application/services provided by the Principal to
                    the Client.
                  </li>
                </ul>
              </li>
              <li>
                <div className="sub-heading">2.2. Interpretation</div>
                <ul>
                  <li>
                    2.1.1. Recitals, headings, and underlining are for
                    convenience only and do not affect the interpretation of
                    these Terms.
                  </li>
                  <li>
                    2.1.2. Words importing the singular number include the
                    plural number and vice versa.
                  </li>
                  <li>
                    2.1.3. Words importing any gender include every gender.
                  </li>
                  <li>
                    2.1.4. Words importing persons include firms, companies, and
                    corporations and vice versa.
                  </li>
                  <li>
                    2.1.5. A reference to “Clause” shall mean a clause in these
                    Terms.
                  </li>
                  <li>
                    2.1.6. References to numbered clauses and schedules are
                    references to the relevant clause in or schedule to these
                    Terms.
                  </li>
                  <li>
                    2.1.7. A warranty, representation, covenant, or terms on the
                    part of two or more persons binds them jointly and
                    severally.
                  </li>
                  <li>
                    2.1.8. Reference to any statute or statutory provision
                    includes a reference to that statute or statutory provision
                    as from time to time amended, extended, or re-enacted and
                    includes all by-laws, instruments, orders, rules, and
                    regulations made thereunder.
                  </li>
                  <li>
                    2.1.9. Where the day on or by which anything is to be done
                    is a Business Day in the place in which that thing is to be
                    done, then that thing is to be done on the immediately
                    preceding Business Day.
                  </li>
                  <li>
                    2.1.10. Any terms, notice, consent, approval, disclosure, or
                    communication under or pursuant to these Terms must be in
                    writing.
                  </li>
                  <li>
                    2.1.11. “Parties” means the Principal and the Client and
                    “Party” shall mean either one of them.
                  </li>
                  <li>
                    2.1.12. Words denoting an obligation on a party to do an
                    act, matter, or thing include an obligation to procure that
                    it be done or words placing a party under a restriction
                    include an obligation not to permit an infringement of the
                    restriction.
                  </li>
                  <li>
                    2.1.13. The words “herein”, “hereinafter”, “hereinbefore”,
                    “hereof”, “hereunder”, and other words of similar import
                    refer to these Terms as a whole and not to any particular
                    provision.
                  </li>
                  <li>
                    2.1.14. Where the word “including” is used in these Terms,
                    it shall be understood as meaning “including without
                    limitation”.
                  </li>
                  <li>
                    2.1.15. Reference to a “document” includes references to any
                    sort of document whether paper or paperless and expressed or
                    described on any substance either with alphabets, figures,
                    symbols, and/or marks.
                  </li>
                  <li>
                    2.1.16. Words defined under “Definitions” in Clause 2.1 when
                    used in these Terms commencing with a capitalized letter as
                    it appears in Clause 2.1 shall bear the meanings stipulated
                    in Clause 2.1.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        {/* section 3 */}
        <div className="section-3">
          <div className="section-heading">3. The Services</div>
          <div>
            <ul>
              <li>
                <div className="sub-heading">3.1. Services Provided</div>
                <ul>
                  <li>
                    The Principal shall provide the Services to the Client
                    during the Term in consideration for the Fee as decided.
                  </li>
                </ul>
              </li>
              <li>
                <div className="sub-heading">
                  3.2. Specifications and Methodology
                </div>
                <ul>
                  <li>
                    The Client shall specify the details for the performance of
                    the Services and the methodology it intends to use in the
                    Services provided by the Principal.
                  </li>
                </ul>
              </li>
              <li>
                <div className="sub-heading">3.3. Best Endeavours</div>
                <ul>
                  <li>
                    The Principal shall use its best endeavors to carry out the
                    Services including the provision of the application, access
                    to websites, data, materials, or reports by the dates
                    stipulated by the Client.
                  </li>
                </ul>
              </li>

              <li>
                <div className="sub-heading">3.4. Support</div>
                <ul>
                  <li>
                    The Principal shall cause the Services to be provided to the
                    Client and shall provide the necessary support for the
                    Services only to such persons acceptable to, and approved in
                    writing by, the Client.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        {/* section 4 */}
        <div className="section-4">
          <div className="section-heading">4. Fee and Payment of Fee</div>
          <div>
            <ul>
              <li>
                <div className="sub-heading">
                  4.1. Subscription Fee for Additional Features
                </div>
                <ul>
                  <li>
                    Subscription fees are applicable for additional features
                    provided by the Principal. It is at the Client's discretion
                    to pursue these additional features or not.
                  </li>
                </ul>
              </li>

              <li>
                <div className="sub-heading">
                  4.2. Service Provider Discount Rate
                </div>
                <ul>
                  <li className="bullet">2.5% for VISA/Master card payments</li>
                  <li className="bullet">
                    3.7% for AMEX/Frimi/Diners/Discover
                  </li>
                  <li className="bullet">2.7% for Digital Payment Wallets</li>
                  <li className="bullet">
                    These rates are subject to change and will be informed prior
                    to change to the Client in writing.
                  </li>
                </ul>

                <li>
                  <div className="sub-heading">4.3 Additional Charges</div>
                  <ul>
                    <li>
                      The Principal reserves the right to charge additional fees
                      for any customizations requested by the Client. Such
                      charges will be determined at the Principal's discretion,
                      based on the time and resources required to fulfill the
                      customization requests.
                    </li>
                  </ul>
                </li>
              </li>
            </ul>
          </div>
        </div>

        {/* section 5 */}
        <div className="section-5">
          <div className="section-heading">
            5. Client’s Obligations / Residents Obligations
          </div>
          <div>
            <ul>
              <li>
                <div className="sub-heading">
                  5.1. Client's Responsibilities
                </div>
                <ul>
                  <li>
                    5.1.1. Co-operate with the Principal as the Principal
                    reasonably requires.
                  </li>
                  <li>
                    5.1.2. Provide the information and documentation that the
                    Principal reasonably requires.
                  </li>
                  <li>
                    5.1.3. Make available to the Principal such facilities as
                    the Principal reasonably requires.
                  </li>
                  <li>
                    5.1.4. Ensure that the Client’s staff, residents, and agents
                    co-operate with and assist the Principal.
                  </li>
                  <li>
                    5.1.5. Comply with the Principal's Terms of Service and
                    Privacy Policy in using the Services provided by The
                    Principal.
                  </li>
                </ul>
              </li>

              <li>
                <div className="sub-heading">5.2. Facilities</div>
                <ul>
                  <li>
                    The facilities for the purpose of Clause 5.1.3 means minimum
                    01 PC (Personal Computer) with an internet connection,
                    minimum 01 smart mobile phone with an internet connection
                    for the usage of the staff/guard application.
                    <ul>
                      <li className="bullet">Operating System Requirements:</li>
                      <ul>
                        <li className="circle">Android: 8 and above</li>
                        <li className="circle">IOS: 10 and above</li>
                      </ul>
                    </ul>
                  </li>
                </ul>
              </li>

              <li>
                <div className="sub-heading">5.3. Refunds</div>
                <ul>
                  <li>
                    The process for any refunds that will be facilitated by the
                    Principal for any Services booked and paid for through LIWE
                    by any Resident shall be as follows:
                  </li>
                  <li>
                    5.3.1. The Resident shall directly contact the Client with
                    any refund request for any Services booked and paid for
                    through LIWE.
                  </li>
                  <li>
                    5.3.2. Where the Client wishes to process a refund request
                    of any Resident, then the Client shall submit a written
                    refund approval to the Principal who will process the full
                    refund to the resident through the original payment method
                    used.
                  </li>
                  <li>
                    5.3.3. The Client agrees to bear the cost of the Service
                    Provider Discount Rate for each such refund processed by the
                    Principal. The Service Provider Discount Rate applicable on
                    each such transaction shall depend on the mode of payment
                    and shall be at the rates specified in Section 4.2 above.
                  </li>
                  <li>
                    5.3.4. The Principal shall invoice the Client for the
                    Service Provider Discount Rate of each processed refund on a
                    monthly basis and shall:
                    <ul>
                      <li className="bullet">
                        Deduct the equivalent from any due settlements from the
                        Principal to the Client; or
                      </li>
                      <li className="bullet">
                        Raise an invoice to be settled by the Client within ten
                        (10) days of receipt.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        {/* section 6 */}
        <div className="section-6">
          <div className="section-heading">
            6. Provision and Ownership of Reports
          </div>
          <div>
            <ul>
              <li>
                <div className="sub-heading">6.1. Reports</div>
                <ul>
                  <li>
                    The Principal will provide reports at the times and in the
                    format agreed by the Parties. Unless otherwise agreed by the
                    Parties, the copyright and database right and all other
                    intellectual property rights in the reports or any other
                    material, documentation or data, written, created, or
                    prepared by or for the Principal in performing the Services
                    (‘The Materials’) shall belong to the Principal unless the
                    information and details are specific to the Client in which
                    event such specific Materials shall be assigned and or
                    transferred to the Client.
                  </li>
                </ul>
              </li>

              <li>
                <div className="sub-heading">6.2. Creation of Materials</div>
                <ul>
                  <li>
                    The Materials created under these Terms shall be work
                    created by the Principal and shall:
                  </li>
                  <li>
                    6.2.1. Not include intellectual property owned by or
                    licensed to a third party except for intellectual property
                    which the Principal has the right to use (including the
                    right to use the intellectual property for the purposes of
                    these Terms).
                  </li>
                  <li>
                    6.2.2. Not subject the Client to any claim for the
                    infringement of any intellectual property rights of a third
                    party.
                  </li>
                </ul>
              </li>

              <li>
                <div className="sub-heading">6.3. Execution of Documents</div>
                <ul>
                  <li>
                    The Principal agrees at any time and from time to time on
                    the written request of the Client to execute and deliver
                    promptly to the Client any documents or instrument which the
                    Client considers desirable.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        {/* section 7 */}
        <div className="section-7">
          <div className="section-heading">7. Confidentiality</div>
          <div>
            <ul>
              <li>
                <div className="sub-heading">
                  7.1. Confidentiality Obligations
                </div>
                <ul>
                  <li>
                    The Principal shall keep the confidential information of the
                    Client confidential and secret, whether disclosed to or
                    received by the Principal. The Principal shall only use the
                    confidential information of the Client for the Purpose and
                    for performing the Principal’s obligations under these
                    Terms. The Principal shall inform its officers, employees,
                    and agents of the Principal’s obligations under the
                    provisions of this clause and ensure that the Principal’s
                    officers, employees – both permanent and temporary, and
                    agents conform to the said obligations.
                  </li>
                </ul>
              </li>
              <li>
                <div className="sub-heading">7.2. Exceptions</div>
                <ul>
                  <li>
                    The obligations of clause 7.1 shall not apply to any
                    information which:
                  </li>
                  <ul>
                    <li>
                      <strong>7.2.1.</strong> Was known or in the possession of
                      the Principal before it was provided to the Principal by
                      the Client.
                    </li>
                    <li>
                      <strong>7.2.2.</strong> Is, or becomes, publicly available
                      through no fault of the Principal.
                    </li>
                    <li>
                      <strong>7.2.3.</strong> Is provided to the Principal
                      without restriction or disclosure by a third party, who
                      did not breach any confidentiality obligations by making
                      such a disclosure.
                    </li>
                    <li>
                      <strong>7.2.4.</strong> Is required to be disclosed by
                      order of a court of competent jurisdiction.
                    </li>
                  </ul>
                </ul>
              </li>
              <li>
                <div className="sub-heading">7.3. Disclosure Restrictions</div>
                <ul>
                  <li>
                    The documents, applications, websites, software, and other
                    matters linked to these Terms including but not limited to
                    the aforementioned will not be passed under any
                    circumstance, nor disclosed to any third party without the
                    written consent of the Principal.
                  </li>
                </ul>
              </li>
              <li>
                <div className="sub-heading">7.4. Surviving Obligations</div>
                <ul>
                  <li>
                    The obligations under clause 7.1 shall survive the
                    termination of these Terms for whatever reason.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        {/* section 8 */}
        <div className="section-8">
          <div className="section-heading">8. The Principal’s Obligations</div>
          <div>
            <ul>
              <li>
                <div className="sub-heading">8.1. Warranty</div>
                <ul>
                  <li>
                    The Principal warrants that it has the necessary skills,
                    qualifications, and expertise to provide the Services and
                    shall perform the Services in the best interest of the
                    Client.
                  </li>
                </ul>
              </li>

              <li>
                <div className="sub-heading">8.2. Compliance</div>
                <ul>
                  <li>
                    The Principal shall comply with the timelines provided by
                    the Client and all other reasonable requirements requested
                    by the Client.
                  </li>
                </ul>
              </li>

              <li>
                <div className="sub-heading">8.3. Authority</div>
                <ul>
                  <li>
                    The Principal warrants that it has the full capacity and
                    authority to perform these Terms and that these Terms are
                    executed by a duly authorized representative of the
                    Principal.
                  </li>
                </ul>
              </li>

              <li>
                <div className="sub-heading">8.4. Licenses</div>
                <ul>
                  <li>
                    The Principal warrants that it has obtained and will
                    continue to hold all necessary licenses, consents, and
                    permits to enter into and perform its obligations under
                    these Terms.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        {/* section 9 */}
        <div className="section-9">
          <div className="section-heading">9. Non-Solicitation</div>
          <div>
            <ul>
              <li>
                Both the Principal and the Client undertake that during the term
                of these Terms and for a period of one (01) year after the
                termination of these Terms, they shall not:
              </li>
              <ul>
                <li className="bullet">
                  Solicit or attempt to solicit the services of any employee of
                  the other Party or solicit or attempt to solicit the customers
                  or suppliers of the other Party without the express written
                  consent of the other Party.
                </li>
              </ul>
            </ul>
          </div>
        </div>

        {/* section 10 */}
        <div className="section-10">
          <div className="section-heading">10. Term and Termination</div>
          <div>
            <ul>
              <li>
                <div className="sub-heading">
                  10.1. Effective Date and Duration
                </div>
                <ul>
                  <li>
                    These Terms shall come into effect from the date of the
                    Client's online registration and continue until terminated
                    in accordance with these Terms.
                  </li>
                </ul>
              </li>

              <li>
                <div className="sub-heading">10.2. Termination</div>
                <ul>
                  <li>
                    Either Party may terminate these Terms at any time upon 30
                    days' prior written notice to the other Party or if the
                    other Party:
                  </li>
                  <li className="bullet">
                    Commits a material breach of these Terms and fails to remedy
                    such breach within 30 days after receiving written notice of
                    the breach.
                  </li>
                  <li className="bullet">
                    Is unable to pay its debts as they fall due or goes into
                    liquidation, receivership, or bankruptcy.
                  </li>
                </ul>
              </li>
              <li>
                <div className="sub-heading">
                  10.3. Consequences of Termination
                </div>
                <ul>
                  <li>
                    Upon termination of these Terms, the Client shall promptly
                    return to the Principal all property of the Principal or
                    dispose of the property as directed by the Principal. The
                    Principal shall within one (01) month of the termination
                    date purge the Client’s data/information (if any) from its
                    system after delivering the same to the Client
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        {/* section 11 */}
        <div className="section-11">
          <div className="section-heading">11. General Provisions</div>
          <div>
            <ul>
              <li>
                <div className="sub-heading">11.1. Amendments</div>
                <ul>
                  <li>
                    These Terms may only be amended in writing signed by duly
                    authorized representatives of the Parties.
                  </li>
                </ul>
              </li>
              <li>
                <div className="sub-heading">11.2. Assignment</div>
                <ul>
                  <li>
                    The Client shall not assign or transfer any of its rights or
                    obligations under these Terms without the prior written
                    consent of the Principal.
                  </li>
                </ul>
              </li>
              <li>
                <div className="sub-heading">11.3. Entire Terms</div>
                <ul>
                  <li>
                    These Terms contain the entire understanding between the
                    Parties with respect to the subject matter hereof and
                    supersede all prior agreements, negotiations, and
                    discussions between the Parties relating thereto.
                  </li>
                </ul>
              </li>
              <li>
                <div className="sub-heading">11.4. Waiver</div>
                <ul>
                  <li>
                    No failure or delay by a Party to exercise any right or
                    remedy provided under these Terms shall constitute a waiver
                    of that or any other right or remedy.
                  </li>
                </ul>
              </li>
              <li>
                <div className="sub-heading">11.5. Relationship</div>
                <ul>
                  <li>
                    Nothing in these Terms shall create, or be deemed to create,
                    a partnership, joint venture, or relationship of principal
                    and agent between the Parties.
                  </li>
                </ul>
              </li>
              <li>
                <div className="sub-heading">11.6. Further Assurance</div>
                <ul>
                  <li>
                    Each Party shall execute and deliver all such further
                    documents, papers, and instruments, and do all such acts, as
                    may be reasonably required to carry the provisions of these
                    Terms into full force and effect.
                  </li>
                </ul>
              </li>
              <li>
                <div className="sub-heading">11.7. Severance</div>
                <ul>
                  <li>
                    If any provision of these Terms is found to be invalid,
                    illegal, or unenforceable, the remaining provisions shall
                    remain in full force and effect.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        {/* section 12 */}
        <div className="section-12">
          <div className="section-heading">12. Notices</div>
          <div>
            <ul>
              <li>
                <div className="sub-heading">12.1. Communication</div>
                <ul>
                  <li>
                    Any notice required to be given under these Terms shall be
                    in writing and shall be delivered or sent by pre-paid
                    first-class post or other next working day delivery service,
                    or by email, to the other Party’s registered office address
                    or principal place of business.
                  </li>
                </ul>
              </li>
              <li>
                <div className="sub-heading">12.2. Addresses</div>
                <ul>
                  <li>The addresses for service of notices are:</li>
                  <li className="bullet">
                    For the Principal: No: 84 Srimath Anagarika Dharmapala
                    Mawatha, Colombo 07, Sri Lanka.
                  </li>
                  <li className="bullet">
                    For the Client: The address provided during the online
                    registration.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        {/* section 13 */}
        <div className="section-13">
          <div className="section-heading">13. Intellectual Property</div>
          <div>
            <ul>
              <li>
                <div className="sub-heading">13.1. Ownership</div>
                <ul>
                  <li>
                    All intellectual property rights in any documents,
                    materials, software, or information provided by the
                    Principal to the Client in connection with the Services
                    shall remain vested in the Principal.
                  </li>
                </ul>
              </li>
              <li>
                <div className="sub-heading">13.2. License</div>
                <ul>
                  <li>
                    The Principal grants to the Client a license to use the
                    intellectual property for the purpose for which it was
                    provided.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        {/* section 14 */}
        <div className="section-14">
          <div className="section-heading">
            14. Governing Law & Dispute Resolution
          </div>
          <div>
            <ul>
              <li>
                <div className="sub-heading">14.1. Governing Law</div>
                <ul>
                  <li>
                    These Terms shall be governed by and construed in accordance
                    with the laws of Sri Lanka.
                  </li>
                </ul>
              </li>
              <li>
                <div className="sub-heading">14.2. Dispute Resolution</div>
                <ul>
                  <li>
                    In the event of any dispute arising out of or in connection
                    with these Terms, the Parties shall use their best endeavors
                    to settle the dispute amicably. If the Parties fail to
                    resolve the dispute amicably, the dispute shall be referred
                    to arbitration in Colombo, Sri Lanka, in accordance with the
                    rules of the Sri Lanka Arbitration Centre.
                  </li>
                </ul>
              </li>
              <li>
                <div className="sub-heading">14.3. Jurisdiction</div>
                <ul>
                  <li>
                    The courts of Colombo, Sri Lanka, shall have exclusive
                    jurisdiction to settle any dispute or claim arising out of
                    or in connection with these Terms.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        {/* section 15 */}
        <div className="section-15">
          <div className="section-heading">15. Indemnity</div>
          <div>
            <ul>
              <li>
                The Parties shall indemnify and keep indemnified each other
                against any and all losses, damages, and expenses incurred by
                the other Party as a result of or in connection with any act or
                omission of the other Party, its employees, agents, or
                subcontractors in the performance of its obligations under these
                Terms.
              </li>
            </ul>
          </div>
        </div>

        {/* others */}
        <div className="section-16">
          <div className="section-heading">Schedule 1</div>
        </div>

        <div className="section-17">
          <div className="section-heading">The Services</div>
        </div>

        <div className="section-18">
          <div className="section-heading">
            ERP Solutions for the Client located in the address provided during
            the online registration will be covering the following areas:
          </div>
        </div>

        <div className="section-19">
          <div className="section-heading">
            <ul>
              <li>
                <GiStaryu /> Management Web Portal
                <ul>
                  <li className="numbers">Facility Booking Management</li>
                  <li className="numbers">
                    Income & Expense Management / Cash Book
                  </li>
                  <li className="numbers">Digital Noticeboard</li>
                  <li className="numbers">Visitor Management</li>
                  <li className="numbers">LIWE Helpdesk</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div className="section-20">
          <div className="section-heading">
            <ul>
              <li>
                <GiStaryu /> Resident Mobile Application
                <ul>
                  <li className="numbers">Digital Noticeboard</li>
                  <li className="numbers">Facility Bookings</li>
                  <li className="numbers">Payments</li>
                  <li className="numbers">LIWE Helpdesk</li>
                  <li className="numbers">Visitor Invitations </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div className="section-21">
          <div className="section-heading">
            Future updates of the system/features will be provided timely and
            will be notified in advance for minimum disruption.
          </div>
        </div>
      </div>
    </div>
  );
}
