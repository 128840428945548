import { useState, useContext, useEffect } from "react";
import AppButton from "../../../components/AppButton/AppButton";
import AppPasswordField from "../../../components/AppPasswordField/AppPasswordFiled";
import ApiService from "../../../services/ApiService";
import { removeToken, getDeviceCode, saveToken, handleLoginErrorNavigation, handleErrors } from "../../../services/HelperMethods";
import { UserDetailsContext } from "../../../store/Store";
import { Link, useHistory, useParams } from "react-router-dom";

export default function AdminLogin() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const apiService = new ApiService();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useContext(UserDetailsContext);
    const { clearData } = useParams() as any;

    useEffect(() => {
        console.log('d', clearData);
        if (clearData) {
            removeToken();
            setUser(null);
        }
    }, []);

    return (
        <div className="section login-wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="body">
                            <form>
                                <div className="login-box">
                                    <p className="title text-primary">Admin Login</p>
                                    <p className="sub-title">
                                        Please enter your email address and
                                        password to continue
                                    </p>
                                    <input
                                        type="text"
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                        placeholder="Enter your email"
                                    />
                                    <AppPasswordField placeholder={"Enter your password"} setter={setPassword} />
                                    <Link
                                        to="forgot-password"
                                        className="text-forgot-password"
                                    >
                                        Forgot Password?
                                    </Link>

                                    <AppButton
                                        
                                        onClick={handleLogin}
                                        isLoading={isLoading}
                                    >
                                        Login
                                    </AppButton>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    async function getUserDetails() {
        try {
            let user = await apiService.getUserDetails();
            user = user.data;
            setUser(user);
        } catch (error) { }
        setIsLoading(false);
    }

    async function handleLogin() {
        setIsLoading(true);
        try {
            let result = (await apiService.loginAdmin({
                username: email,
                password,
                deviceCode: getDeviceCode(),
            })) as any;
            result = result.data;
            saveToken(result.token);
            await getUserDetails();
            history.push("/admin/dashboard");
        } catch (error: any) {
            console.log(error?.response, "error-login");

            if (!handleLoginErrorNavigation(error, email, history)) {
                handleErrors(error);
            }
        }
        setIsLoading(false);
    }
}
