import { Link, useParams } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import ordinalSuffixOf, { handleErrors, showNotification, splitWord, } from "../../services/HelperMethods";
import ApiService from "../../services/ApiService";
import Drawer from "../../components/Drawer/Drawer";
import AppButton from "../../components/AppButton/AppButton";
import moment from "moment";
import DrawerHeader from "../../components/Drawer/DrawerHeader/SideDrawerHeader";
import Navbar from "../../components/Navbar/Navbar";
import AppLoading from "../../components/AppLoading/AppLoading";
import DrawerBody from "../../components/Drawer/DrawerBody";
import DrawerFooter from "../../components/Drawer/DrawerFooter";

import "./Users.scss";
import Filter from "../../components/Filter/Filter";
import AddStaff from "../../components/AddStaff/AddStaff";

export default function Staff() {

  const apiService = new ApiService();

  const [users, setUsers] = useState([] as any[]);
  const [selectedUser, setSelectedUser] = useState(null as any);
  const [isLoading, setIsLoading] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  // const [name, setName] = useState("All");
  // const [email, setEmail] = useState("All");
  const [userType, setUserType] = useState("All");
  const [userStatus, setUserStatus] = useState("All");
  const [userTypeList, setUserTypeList] = useState([] as any[])
  const [userStatusList, setUserStatusList] = useState([] as any[])
  const [isFiltered, setIsFiltered] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([] as any[]);
  const [searchedUsers, setSearchedUsers] = useState([] as any[]);
  const [showFilter, setShowFilter] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showNewStaffAddition, setShowNewStaffAddition] = useState(false)

  const [selectedItem, setSelectedItem] = useState(null);
  
  let { id } = useParams() as any;

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (id) {
      getUsers();
    }
  }, [id]);

  useEffect(() => {
    if (users.length > 0) {
      getLists();
    }
  }, [users]);

  function handleItemClick(item: any){
    setSelectedItem(item);  
  }

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  function getLists() {

    var userTypesArr = [] as any[];
    var userStatusArr = [] as any[];

    if (users.length > 0) {
      users.forEach(user => {

        if (user?.userType) {
          userTypesArr.push(user?.userType)
        }

        if (user?.status) {
          userStatusArr.push(user?.status)
        }
      });
    }

    setUserTypeList(userTypesArr.filter(onlyUnique))
    setUserStatusList(userStatusArr.filter(onlyUnique))
  }

  async function getUsers() {
    setIsLoading(true);

    try {
      let result = (await apiService.getBuildingStaff()) as any;

      result = result.data;
      setUsers(result.users);
      setSearchedUsers(result.users);

      if (id) {
        let user = result.users.find((u) => u.id == id);

        if (user) {
          setSelectedUser(user);
        }
      }

    } catch (error) {
      handleErrors(error);
    }

    setIsLoading(false);
  }

  function filter() {

    setIsFiltered(true)

    setShowFilter(false);

    var filtered = [] as any;

    if (userType == "All" && userStatus == "All") {

      filtered = users;

      setFilteredUsers(filtered)
      setSearchedUsers(filtered)
    } else {

      // if (name != "All") {
      //   filtered = users.filter(function (user) {
      //     return user?.name == name;
      //   });
      // }


      // if (email != "All") {
      //   if (filtered.length > 0) {
      //     filtered = filtered.filter(function (user) {
      //       return user?.email == email;
      //     });
      //   } else {
      //     filtered = users.filter(function (user) {
      //       return user?.email == email;
      //     });
      //   }
      // }

      if (userType != "All") {
        if (filtered.length > 0) {
          filtered = filtered.filter(function (user) {
            return user?.userType == userType;
          });
        } else {
          filtered = users.filter(function (user) {
            return user?.userType == userType;
          });
        }
      }

      if (userStatus != "All") {
        if (filtered.length > 0) {
          filtered = filtered.filter(function (user) {
            return user?.status == userStatus;
          });
        } else {
          filtered = users.filter(function (user) {
            return user?.status == userStatus;
          });
        }
      }

      setFilteredUsers(filtered)
      setSearchedUsers(filtered)
    }

    if (filtered.length == 0) {
      showNotification("Filter Returned No Results", "Filter Returned No Results", "error")
    }
  }

  function clear() {
    // setName("All")
    // setEmail("All")
    setUserType("All")
    setUserStatus("All")
    setFilteredUsers([])
    setIsFiltered(false);
    setShowFilter(false);
    getUsers();
  }

  function handleFilter(array: any[], searchedWord: string) {
    let searchedArray = [] as any[];
    array.forEach((user) => {
      if (user?.name.toLowerCase().includes(searchedWord.toLowerCase())
        || user?.email.toLowerCase().includes(searchedWord.toLowerCase())
        || user?.phoneNumber?.toString().includes(searchedWord.toLowerCase())
        || splitWord(user?.status).toString().toLowerCase().includes(searchedWord.toLowerCase())
        || splitWord(user?.userType).toLowerCase().includes(searchedWord.toLowerCase())) {
        searchedArray.push(user);
      }
    });
    return searchedArray;
  }

  function handleSearch(searchedWord: string) {
    setIsLoading(true);
    if (searchedWord.trim() === "") {
      if (filteredUsers.length > 0 || isFiltered) {
        setSearchedUsers(filteredUsers);
      } else {
        setSearchedUsers(users);
      }
      setIsLoading(false);
      return;
    }

    let searchedArray = [] as any[];
    if (filteredUsers.length > 0 || isFiltered) {
      searchedArray = handleFilter(filteredUsers, searchedWord);
    } else {
      searchedArray = handleFilter(users, searchedWord);
    }
    setSearchedUsers(searchedArray);
    setIsLoading(false);
  }

  const sortedStaff = searchedUsers.sort((a, b) => {
    // Convert the lastLogin strings to Date objects for comparison
    const dateA = new Date(a.lastLogin ?? 0); // Use 0 as a default value if lastLogin is null or undefined
    const dateB = new Date(b.lastLogin ?? 0);
  
    // Compare the Date objects
    return dateB.getTime() - dateA.getTime(); // Use getTime() to get the timestamp for comparison
  });

  return (
    <div className="body">

      <h2>Apartments/Users</h2>

      <AppLoading isLoading={isLoading} />

      <Navbar showSearch hideSettings={true} hideAdd={false} hideFilter={false} onFilterButtonClick={() => { setShowFilter(!showFilter) }}
        onAddButtonClick={() => { setShowNewStaffAddition(true) }} onSearchChange={handleSearch}>
        <Link to="/apartments">Apartments</Link>
        <Link to="/users">Residents</Link>
        <Link className="active" to="/staff">Staff</Link>
      </Navbar>

      <Table borderless={true} className="users-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email Address</th>
            <th>Telephone Number</th>
            <th>User Type</th>
            <th>User Status</th>
            <th>Last Login</th>
          </tr>
        </thead>
        <tbody>
          {sortedStaff.map((user, i) => {
            return (
              <tr 
                key={i} 
                onClick={() => { 
                  setSelectedUser(user); 
                  setSelectedItem(user);
                }}
                style={{
                  backgroundColor: selectedItem === user ? '#e1fce1' : 'transparent',
                }}
                className="clickable">
                <td>{user?.name}</td>
                <td>{user?.email}</td>
                <td>{user?.phoneNumber}</td>
                <td>{splitWord(user?.userType)}</td>
                <td>{splitWord(user?.status)}</td>
                <td className="font-blue">{user?.lastLogin ? moment.utc(user?.lastLogin).fromNow() : "-"}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Drawer isOpen={selectedUser} className="user-drawer">
        <DrawerHeader 
        onClose={() => { 
          setSelectedUser(null); 
          setSelectedItem(null);
        }} 
        profileImage={selectedUser?.profileImagePath}>
          <div className="name-section">
            <p className="user-name">
              {selectedUser?.name}
            </p>
            <p style={{ fontSize: 14, color: "gray" }}>
              {selectedUser?.phoneNumber}
            </p>
            <p style={{ fontSize: 14, color: "gray" }}>{selectedUser?.email}</p>
          </div>
        </DrawerHeader>
        <DrawerBody>
          <div>
            <div className="apartment-info mt-2">
              {selectedUser?.apartment?.floor && selectedUser?.apartment?.doorNumber
                ? <h4 className="apartment-name">
                  {ordinalSuffixOf(selectedUser?.apartment?.floor)}
                  {" Floor, "}
                  {selectedUser?.apartment?.doorNumber}
                </h4>
                : null}
            </div>
            <div>
              <a className="document" href={selectedUser?.tenancyDocumentURL} target="_blank" rel="noreferrer">
              </a>
              <a className="document" href={selectedUser?.passportDocumentURL} target="_blank" rel="noreferrer">
              </a>
              {getActionButtons(selectedUser?.userStatus, selectedUser)}
            </div>

            <Modal centered onHide={() => { setShowModal(false); }} show={showModal}>
              <Modal.Header closeButton>
                <Modal.Title style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ fontSize: "18px", color: "#222" }}>{"Reason for Application Rejection"}</span>
                  </div>
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div>
                  <div>
                    <input className="form-control mb-1" placeholder="Reject Reason" value={rejectReason}
                      onChange={(e) => { setRejectReason(e.target.value); }} />
                  </div>
                </div>
                <div className="mt-4 text-right w-100">
                  <AppButton onClick={() => { handleApprove(selectedUser.id, 4); }}
                    style={{ width: 100, padding: 5, backgroundColor: "#4bbb46", color: "white", marginRight: "0.6rem" }}>
                    {"Reject"}
                  </AppButton>
                  <AppButton onClick={() => { setShowModal(false) }}
                    style={{ width: 100, padding: 5, backgroundColor: "#FEBE4D", color: "white", }}>
                    Cancel
                  </AppButton>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </DrawerBody>
        <DrawerFooter>
        </DrawerFooter>
      </Drawer>

      <Filter children={[
        // {
        //   label: "Name",
        //   type: <select style={{ flex: "2" }} className="form-control"
        //     onChange={(e) => { setName(e.target.value); }} value={name}>
        //     <option value={"All"}>All</option>
        //     {users?.map((user: any, index) => {
        //       return (
        //         <option value={user?.name} key={index}>
        //           {user?.name}
        //         </option>
        //       );
        //     })}
        //   </select>
        // },
        // {
        //   label: "Email",
        //   type: <select style={{ flex: "2" }} className="form-control"
        //     onChange={(e) => { setEmail(e.target.value); }} value={email}>
        //     <option value={"All"}>All</option>
        //     {users?.map((user: any, index) => {
        //       return (
        //         <option value={user?.email} key={index}>
        //           {user?.email}
        //         </option>
        //       );
        //     })}
        //   </select>
        // },
        {
          label: "User Type",
          type: <select style={{ flex: "2" }} className="form-control"
            onChange={(e) => { setUserType(e.target.value); }} value={userType}>
            <option value={"All"}>All</option>
            {userTypeList?.map((type: any, index) => {
              return (
                <option value={type} key={index}>
                  {type == "EntityAdmin" ? "Management" : splitWord(type)}
                </option>
              );
            })}
          </select>
        },
        {
          label: "User Status",
          type: <select style={{ flex: "2" }} className="form-control"
            onChange={(e) => { setUserStatus(e.target.value); }} value={userStatus}>
            <option value={"All"}>All</option>
            {userStatusList?.map((status: any, index) => {
              return (
                <option value={status} key={index}>
                  {splitWord(status)}
                </option>
              );
            })}
          </select>
        }
      ]} isOpen={showFilter} wrapperClass={"filter-area-user"} showClear={true} onFilter={() => { filter() }}
        onClear={() => { clear() }} />

      <AddStaff isShow={showNewStaffAddition} onClose={() => { setShowNewStaffAddition(false) }}
        onNewStaffMemberAdded={() => { getUsers(); setShowNewStaffAddition(false) }} />
    </div>
  );

  function getActionButtons(status, userBuilding) {
    if (status == 0 || status == 1 || status == 3) {
      return (
        <div className="action-buttons">
          <div className="btn-wrap">
            <div>
              <AppButton style={{ backgroundColor: "#737373", }} onClick={() => { setShowModal(true) }}>
                Reject Application
              </AppButton>
            </div>
            <AppButton onClick={() => { handleApprove(userBuilding.id, 2); }}>
              Approve User
            </AppButton>
          </div>
        </div>
      );
    }

    if (status == 4) {
      return (
        <div className="btn-wrap">
          <AppButton style={{ backgroundColor: "#cacaca", }} disable={true}>
            Documents Pending
          </AppButton>
          <AppButton onClick={() => { handleApprove(userBuilding.id, 2); }}>
            Approve User
          </AppButton>
        </div>
      );
    }

    return (
      <AppButton style={{ backgroundColor: "transparent", color: "red", boxShadow: "none", }}
        onClick={() => { handleApprove(userBuilding.id, 3); }} isLoading={false}>
        Disable User
      </AppButton>
    );
  }

  async function handleApprove(id, status) {
    setIsLoading(true);

    try {
      await apiService.changeUserStatus({ userBuildingId: id, status: status, rejectReason });

      getUsers();

      setSelectedUser(null);

      showNotification("Success", "Updated!", "success");
    } catch (error) {
      handleErrors(error);
    }

    setIsLoading(false);
  }
}
