import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import "./SettingsUserManagement.scss";

import AppButton from "../../components/AppButton/AppButton";
import defaultImg from "../../assets/images/default.jpg";

export default function SettingsUserManagement() {
  return (
    <div className="body settings payments">
      <h2>Settings</h2>

      <Navbar hideSettings={true} hideAdd={true} hideFilter={true}>
        {/* <Link to="#">General</Link> */}
        <Link to="#" className="active">User Management</Link>
        {/* <Link to="/settings/vendorManagement">Staff Management</Link> */}
        <Link to="/settings/payment">Payments</Link>
        {/* <Link to="#">Notifications</Link> */}
      </Navbar>

    

      <div className="white-box">
        <div className="user-management-section">
          <div className="row">
            <div className="col-auto pr-0">
              <div className="icon">
                <i className="fas fa-user"></i>
              </div>
            </div>
            <div className="col-auto">
              <div className="title">User Management</div>
              <p className="description">
                Sed sed risus pretium quam vulputate dignissim suspendisse in
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-auto">
              <AppButton>Add New User</AppButton>
            </div>
          </div>
        </div>

        <div className="row user-management-row"></div>

        {/* start of user box */}

        <div className="user-card-wrapper">
          {/* start of first ror */}
          <div className="row no-gutters">
            <div className="col">
              <div>
                <div className="user-card">
                  <div className="user-card-title">Managers (2/4)</div>

                  <div className="user-card-description">
                    Donec adipiscing tristique risus nec feugiat in fermentum
                    posuere. Ac tincidunt vitae semper quis. Libero justo
                    laoreet sit amet.
                  </div>

                  <div className="single-user-bar">
                    <img className="user_avatar" src={defaultImg} />

                    <div className="user_details">
                      <div className="user_name">Elizabeth Spencer </div>
                      <div className="user_email"> espencer@gmail.com </div>
                    </div>
                  </div>

                  <div className="single-user-bar">
                    <img className="user_avatar" src={defaultImg} />

                    <div className="user_details">
                      <div className="user_name">Elizabeth Spencer </div>
                      <div className="user_email"> espencer@gmail.com </div>
                    </div>
                  </div>

                  <div>
                    <button className="btn btn-decline">User Settings</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              {" "}
              <div>
                <div className="user-card">
                  <div className="user-card-title">Executors (3/4)</div>

                  <div className="user-card-description">
                    Donec adipiscing tristique risus nec feugiat in fermentum
                    posuere. Ac tincidunt vitae semper quis. Libero justo
                    laoreet sit amet.
                  </div>

                  <div className="single-user-bar">
                    <img className="user_avatar" src={defaultImg} />

                    <div className="user_details">
                      <div className="user_name">Elizabeth Spencer </div>
                      <div className="user_email"> espencer@gmail.com </div>
                    </div>
                  </div>

                  <div className="single-user-bar">
                    <img className="user_avatar" src={defaultImg} />

                    <div className="user_details">
                      <div className="user_name">Elizabeth Spencer </div>
                      <div className="user_email"> espencer@gmail.com </div>
                    </div>
                  </div>

                  <div>
                    <button className="btn btn-decline">User Settings</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end of first ror */}

          {/* start of second ror */}
          <div className="row no-gutters">
            <div className="col">
              {" "}
              <div>
                <div className="user-card">
                  <div className="user-card-title">Officers (2/4)</div>

                  <div className="user-card-description">
                    Donec adipiscing tristique risus nec feugiat in fermentum
                    posuere. Ac tincidunt vitae semper quis. Libero justo
                    laoreet sit amet.
                  </div>

                  <div className="single-user-bar">
                    <img className="user_avatar" src={defaultImg} />

                    <div className="user_details">
                      <div className="user_name">Elizabeth Spencer </div>
                      <div className="user_email"> espencer@gmail.com </div>
                    </div>
                  </div>

                  <div className="single-user-bar">
                    <img className="user_avatar" src={defaultImg} />

                    <div className="user_details">
                      <div className="user_name">Elizabeth Spencer </div>
                      <div className="user_email"> espencer@gmail.com </div>
                    </div>
                  </div>

                  <div>
                    <button className="btn btn-decline">User Settings</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              {" "}
              <div>
                <div className="user-card">
                  <div className="user-card-title">Other Staffs (3/5)</div>

                  <div className="user-card-description">
                    Donec adipiscing tristique risus nec feugiat in fermentum
                    posuere. Ac tincidunt vitae semper quis. Libero justo
                    laoreet sit amet.
                  </div>

                  <div className="single-user-bar">
                    <img className="user_avatar" src={defaultImg} />

                    <div className="user_details">
                      <div className="user_name">Elizabeth Spencer </div>
                      <div className="user_email"> espencer@gmail.com </div>
                    </div>
                  </div>

                  <div className="single-user-bar">
                    <img className="user_avatar" src={defaultImg} />

                    <div className="user_details">
                      <div className="user_name">Elizabeth Spencer </div>
                      <div className="user_email"> espencer@gmail.com </div>
                    </div>
                  </div>

                  <div>
                    <button className="btn btn-decline">User Settings</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end of second ror */}
        </div>

        {/* end of  of user box */}
      </div>
    </div>
  );
}
