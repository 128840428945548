import React, { useContext, useEffect, useState } from "react";
import ApiService from "../../services/ApiService";
import {
    getDeviceCode,
    handleErrors,
    handleLoginErrorNavigation,
    saveToken,
    showNotification,
} from "../../services/HelperMethods";
import { Link, useHistory } from "react-router-dom";
import AppButton from "../../components/AppButton/AppButton";

export default function FAQ() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const apiService = new ApiService();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div className="section login-wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="body">
                            <form>
                                <div className="login-box">
                                    <p className="title">FAQ</p>
                                    {/* <p className="sub-title mb-4">
                                        Forgot password! Not a problem. We'll
                                        send you a reset link.
                                    </p>
                                    <input
                                        type="text"
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                        placeholder="Enter your email"
                                    />

                                    <AppButton
                                        onClick={handleLogin}
                                        isLoading={isLoading}
                                    >
                                        Send Reset Link
                                    </AppButton>

                                    <Link to="/start/login" className="small">
                                        Go back to login page
                                    </Link> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    async function handleLogin() {
        setIsLoading(true);
        try {
            let result = (await apiService.resetPassword({
                email: email,
            })) as any;
            showNotification(
                "Success",
                "We sent you an email to reset the password.",
                "success"
            );
            history.push("/start/login");
        } catch (error) {
            handleErrors(error);
        }
        setIsLoading(false);
    }
}
