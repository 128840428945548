import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import "./SettingsPayment.scss";

import { Table } from "react-bootstrap";
import { Switch } from "@material-ui/core";

export default function SettingsVendorManagement() {
  return (
    <div className="body settings payments">
      <h2>Settings</h2>

      <Navbar hideSettings={true} hideAdd={true} hideFilter={true}>
        {/* <Link to="#">General</Link> */}
        <Link to="/settings/userManagement">User Management</Link>
        {/* <Link to="#" className="active">Staff Management</Link> */}
        <Link to="/settings/payment">Payments</Link>
        {/* <Link to="#">Notifications</Link> */}
      </Navbar>

      <div className="white-box">
        <div className="row">
          <div className="col-auto pr-0">
            <div className="icon">
              <i style={{ background:'green' }} className="far fa-shield-check"></i>
            </div>
          </div>
          <div className="col-auto">
            <div className="title">Security Access</div>
            <p className="description">
              Sed sed risus pretium quam vulputate dignissim suspendisse in
            </p>
          </div>
        </div>

        <div>
          <Table responsive="lg" borderless={true}>
            <thead>
              <tr>
                <th></th>
                <th>Role</th>
                <th>Status</th>
                <th>Requested On</th>
                <th>Expire On</th>
                <th></th>
                <th></th>
              </tr>
            </thead>

            <tbody className="wrapper-payments-table">
              <tr>
                <td>
                  <Switch checked={true} />
                </td>

                <td>
                  <select className="form-control input-lbl white-select-input">
                    <option value={0}>Parking Guard</option>
                    <option value={1}>Lobby Guard</option>
                    <option value={1}>Exit Guard</option>
                  </select>
                </td>

                <td>
                <span> <i className="fas fa-circle gren-dot"></i> {" "}On Duty </span>
                </td>

                <td>
                  {" "}
                  <div> Jan 19, 2021 </div>
                </td>

                <td>Mar 28, 2021</td>
                <td>Assign Access</td>
                <td>Remove Access</td>
              </tr>

              <tr>
                <td>
                  <Switch checked={true} />
                </td>

                <td>
                  <select className="form-control input-lbl white-select-input">
                    <option value={0}>Parking Guard</option>
                    <option value={1}>Lobby Guard</option>
                    <option value={1}>Exit Guard</option>
                  </select>
                </td>

                <td>
                  <span> <i className="fas fa-circle gren-dot"></i> {" "}On Duty </span>
                </td>

                <td>
                  
                  <div> Jan 19, 2021 </div>
                </td>

                <td>Mar 28, 2021</td>
                <td>Assign Access</td>
                <td>Remove Access</td>
              </tr>
            </tbody>
          </Table>

          <div style={{ maxWidth: "20%" }}>
            <select className="form-control input-lbl green-select-input">
              <option value={0}>Add New</option>
            </select>
          </div>
        </div>
      
      
      </div>
    </div>
  );
}
