import { debug } from "console";
import moment from "moment";
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import AppLoading from "../../components/AppLoading/AppLoading";
import Navbar from "../../components/Navbar/Navbar";
import ProfileImage from "../../components/ProfileImage/ProfileImage";
import ApiService from "../../services/ApiService";
import FirebaseServices from "../../services/FirebaseServices";
import { handleErrors, onlyUnique, splitWord } from "../../services/HelperMethods";
import { UserDetailsContext } from "../../store/Store";
import FileInput from "../../components/FileInput/FileInput";

import "./Messages.scss";
import PaymentFileInput from "../../components/FileInput/PaymentFileInput";

export default function Messaging() {

  const apiService = new ApiService();

  const firebaseServices = new FirebaseServices();

  const [friends, setFriends] = useState([] as any[]);
  const [messagedFriends, setMessagedFriends] = useState([] as any[]);
  const [otherFriends, setOtherFriends] = useState([] as any[]);
  const [messages, setMessages] = useState([] as any[]);
  const [selectedProfile, setSelectedProfile] = useState(null as any);
  const [isMessageLoading, setIsMessageLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null as any);

  const messagesEndRef = useRef(null as any)

  const [user, setUser] = useContext(UserDetailsContext);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView()
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages]);

  useEffect(() => {
    if (user) {
      getContacts();
    }
  }, [user]);

  useEffect(() => {
    sortFriends()
  }, [friends])

  useEffect(() => {
    if (selectedProfile) {
      readMessages()
    }
  }, [selectedProfile])

  function sortFriends() {

    setIsLoading(true);

    var allFriendsArr = friends;

    var messagedIdArr = [] as any[];

    firebaseServices.getDb().collection("users").doc(user?.firebaseId).onSnapshot((snapshot) => {

      var data = snapshot.data();

      data?.UnreadMessagesCountByUser?.forEach((item) => {
        if (item.UnreadMessageCount > 0) {
          messagedIdArr.push(item.SenderId);
        }
      });

      var messagedFriendsArr = allFriendsArr.filter(function (friend) {
        return messagedIdArr.includes(friend.userId)
      })

      var otherFriendsArr = allFriendsArr.filter(function (friend) {
        return !messagedIdArr.includes(friend.userId)
      })

      setMessagedFriends(messagedFriendsArr)
      setOtherFriends(otherFriendsArr)
      setIsLoading(false)
    })
  }

  function readMessages() {
    if (selectedProfile) {

      let msgCountByUser = [] as any[];

      firebaseServices.getDb().collection("users").doc(user?.firebaseId).get().then((doc) => {

        let data = doc.data();

        data?.UnreadMessagesCountByUser?.forEach((item) => {
          if (item?.SenderId == selectedProfile.userId) {
            item.UnreadMessageCount = 0;
          }

          msgCountByUser.push(item);
        })

        firebaseServices.getDb().collection("users").doc(user?.firebaseId).update({
          UnreadMessagesCountByUser: msgCountByUser,
        });
      }).catch((error) => {

      });
    }
  }

  async function getMessages(selectedProfile, showLoader = false) {
    if (!selectedProfile?.chatId) {
      setMessages([]);
      return;
    }

    if (showLoader)
      setIsMessageLoading(true);

    firebaseServices.getDb().collection("chat").doc(selectedProfile.chatId).collection("messages").orderBy("timestamp", "asc")
      .onSnapshot((snapshot) => {
        setMessages(snapshot.docs.map((doc) => doc.data()));
        setIsMessageLoading(false);
      });
  }

  async function getContacts() {
    try {
      setIsLoading(true)
      let result = await apiService.getUserContacts() as any;
      result = result.data;
      console.log('ree', result);
      setFriends(result.buildingUsers);
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      handleErrors(error);
    }
  }

  useEffect(() => {
    if (image?.url) {
      firebaseServices.getDb().collection("chat").doc(selectedProfile.chatId).collection("messages").add({
        message: image?.url,
        userId: user?.id,
        timestamp: firebaseServices.timeStamp(),
        type: 2
      })

      getMessages(selectedProfile, true);

      let data = {
        sendToId: selectedProfile?.userId
      }

      setImage("");

      apiService.SendMessagePushNotification(data);
    }
  }, [image])

  return (
    <div className="body wrapper-messaging">
      <AppLoading isLoading={isLoading} />
      <div className="container">
        <Navbar hideSettings={true} hideAdd={true} hideFilter={true}>
          <Link className="active" to="/messages">All Messages</Link>
          <Link to="/broadcast">Broadcast</Link>
        </Navbar>
        <div className="row row-messenger">
          <div className="col-md-5 profile-list">
            {messagedFriends.map((item, i) => {
              return (
                <div key={i} onClick={() => { setSelectedProfile(item); getMessages(item, true); }} style={{ display: "flex" }}
                  className={selectedProfile?.userId == item?.userId ? "wrapper-person active" : "wrapper-person"}>
                  <ProfileImage style={{ width: 50, height: 50 }} src={item?.userProfilePath} />
                  <div className="online">!</div>
                  <div className="wrapper-name" style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <p className="name">{item?.firstName}{" "}{item?.lastName}</p>
                    {item?.apartment
                      ? (<p style={{ fontWeight: 400, fontSize: 10 }}>
                        {"Floor "}{item?.apartment?.floor}{" Door "}{item?.apartment?.doorNumber}
                      </p>)
                      : (<p style={{ fontWeight: 400, fontSize: 10 }}>
                        {splitWord(item?.role)}
                      </p>)
                    }
                  </div>
                  <div className="wrapper-time"></div>
                </div>
              );
            })}
            {otherFriends.map((item, i) => {
              return (
                <div key={i} onClick={() => { setSelectedProfile(item); getMessages(item, true); }} style={{ display: "flex" }}
                  className={selectedProfile?.userId == item?.userId ? "wrapper-person active" : "wrapper-person"}>
                  <ProfileImage style={{ width: 50, height: 50 }} src={item?.userProfilePath} />
                  <div className="wrapper-name" style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <p className="name">{item?.firstName}{" "}{item?.lastName}</p>
                    {item?.apartment
                      ? (<p style={{ fontWeight: 400, fontSize: 10 }}>
                        {"Floor "}{item?.apartment?.floor}{" Door "}{item?.apartment?.doorNumber}
                      </p>)
                      : (<p style={{ fontWeight: 400, fontSize: 10 }}>
                        {splitWord(item?.role)}
                      </p>)
                    }
                  </div>
                  <div className="wrapper-time"></div>
                </div>
              );
            })}
          </div>
          <div className="col-md-7 col-messaging">
            <div className="wrapper-messaging">
              <AppLoading size={50} style={{ backgroundColor: "transparent" }} color="#8acb87" isLoading={isMessageLoading} />
              {messageBodyUI()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function handleSendMessage() {
    let msg = message;

    setMessage("");

    if (message.length > 0) {
      firebaseServices.getDb().collection("chat").doc(selectedProfile.chatId).collection("messages").add({
        message: msg,
        userId: user?.id,
        timestamp: firebaseServices.timeStamp(),
        senderName: user.firstName + " " + user.lastName,
      })

      getMessages(selectedProfile);
    }

    let data = {
      sendToId: selectedProfile?.userId
    }

    apiService.SendMessagePushNotification(data);
  }

  function messageBodyUI() {

    if (!selectedProfile) {
      return <Fragment />;
    }

    return (
      <div>
        <div className="wrapper-person active" style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex" }}>
            <ProfileImage style={{ width: 50, height: 50 }} src={selectedProfile?.userProfilePath} />
            <div className="wrapper-name" style={{ display: "flex", flexDirection: "column" }}>
              <p className="name">{selectedProfile?.firstName + " "}{selectedProfile?.lastName}</p>
              {selectedProfile?.apartment
                ? (<p style={{ fontWeight: 400, fontSize: 10 }}>
                  {"Floor "}{selectedProfile?.apartment?.floor}{" Door "}{selectedProfile?.apartment?.doorNumber}
                </p>)
                : (<Fragment />)
              }
            </div>
          </div>
          <div className="wrapper-options">
            {/* <i className="fas fa-ellipsis-v"></i> */}
          </div>
        </div>

        <div className="message-wrapper">
          {messages.map((msg, i) => {
            return (
              <div key={i} className={msg?.userId == user.id ? "message-sent" : "message-received"}>
                <div className="senderName">{msg?.senderName}</div>
                {msg?.type == "2"
                  ? (<img src={msg?.message} style={{ width: "10rem", height: "10rem", borderRadius: "15px" }} />)
                  : (<div className="message">{msg?.message}</div>)
                }
                <div className="wrapper-time">
                  <p className="time">{moment(msg?.timestamp?.toDate()).fromNow()}</p>
                </div>
              </div>
            );
          })}
          <div ref={messagesEndRef} />
        </div>

        <form className="wrapper-type-message">
          <input type="text" value={message} onChange={(e) => { setMessage(e.target.value); }} placeholder="Type your message here.." />
          <div className="wrapper-icons">
            {/* <i className="fas fa-grin"></i> */}
            <i className="fas fa-paperclip icon-attachment" onClick={() => { }} style={{ cursor: "pointer" }}>
              <PaymentFileInput
                onChange={(url) => {
                  if (url) {
                    setImage({ isBase64: true, url });
                  }
                }} />
            </i>
            {/* <i className="far fa-image"></i> */}
            <button type="submit" onClick={(e) => { e.preventDefault(); handleSendMessage(); }}
              style={{ backgroundColor: "transparent", border: "none", padding: 0, marginLeft: 10 }}>
              <i className="fas fa-location-arrow icon-send"></i>
            </button>
          </div>
        </form>
      </div>
    );
  }
}
