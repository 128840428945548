import moment from "moment";
import React from "react";
import ProfileImage from "../ProfileImage/ProfileImage";

export default function PublicNoticeBox(props: { notice }) {
    return (
        <div className="notice">
            <div className="row">
                <div className="col-auto">
                    <ProfileImage style={{ width: 40, height: 40 }} src={props.notice?.createdByProfileImageURL} />
                </div>
                <div className="col">
                    <div>
                        <span style={{ fontWeight: "bold", fontSize: 15, }}>{props.notice?.name}</span>
                    </div>
                    <div>
                        <span style={{ fontSize: 13, color: "gray", }}>
                            {props.notice?.createdBy}{" "}
                            {moment.utc(props.notice?.issuedDate).local().format("LLL")}
                        </span>
                    </div>
                    <div style={{ margin: "0.8rem 0rem" }}>
                        <span>{props.notice?.description}</span>
                    </div>
                    <div className="wrapper-bottom-set">
                        {props.notice?.attachments?.map((attachment, index) => {
                            return (
                                <div key={index} className="other-image-wrap" onClick={() => { window.open(attachment.url) }}
                                    style={{ cursor: "pointer" }}>
                                    <img src={attachment.attachmentType == "Image" ? attachment.url : "/images/PDF.png"} />
                                </div>
                            );
                        })}
                    </div>
                    <div>
                        <span className="">
                            {props.notice?.publishedStatus == "Active"
                                ? (<span className="status-btn active">Published</span>)
                                : (<span className="status-btn draft">{props.notice?.publishedStatus}</span>)
                            }
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
