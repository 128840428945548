import React, { Fragment } from "react";
import AppButton from "../AppButton/AppButton";

import "./Filter.scss"

interface FilterProps {
    wrapperClass: string;
    isOpen: boolean;
    children: any;
    showClear: boolean;
    onFilter: CallableFunction;
    onClear?: CallableFunction;
}

export default function Filter(props: FilterProps) {
    return (props.isOpen
        ? (<div className={"filter-wrapper " + props?.wrapperClass}>
            {props.children?.length > 0
                ? (<div>
                    {props.children?.map((child: any, index) => {
                        return (<div className="filter-item" key={index}>
                            <div className="label">{child?.label}</div>
                            <div className="type">{child?.type}</div>
                        </div>)
                    })}

                    <div style={{ display: "flex", justifyContent: "flex-end" }} className="filter-area-buttons-compound">
                        <AppButton style={{
                            width: "6rem", height: "2.5rem", margin: props.showClear ? "0rem 1rem" : "0rem 0.4rem 0rem 0rem"
                        }}
                            onClick={() => { props.onFilter() }}>Filter</AppButton>
                        {props.showClear
                            ? (<AppButton style={{ width: "3rem", height: "2.5rem", background: "#FF5440" }}
                                onClick={() => { props.onClear?.() }}>X</AppButton>)
                            : (<Fragment />)}
                    </div>
                </div>)
                : (<Fragment />)
            }
        </div>)
        : (<Fragment />)
    )
}