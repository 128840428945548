export const BankNames: string[] = [
  "Amana Bank PLC",
  "Bank of Ceylon",
  "Bank of China Ltd",
  "Cargills Bank PLC",
  "Citibank, N.A.",
  "Commercial Bank of Ceylon PLC",
  "Deutsche Bank AG, Colombo Branch",
  "DFCC Bank PLC",
  "Habib Bank Ltd",
  "Hatton National Bank PLC",
  "Indian Bank",
  "Indian Overseas Bank",
  "MCB Bank Ltd",
  "National Development Bank PLC",
  "Nations Trust Bank PLC",
  "Pan Asia Banking Corporation PLC",
  "People's Bank",
  "Public Bank Berhad",
  "Sampath Bank PLC",
  "Seylan Bank PLC",
  "Standard Chartered Bank",
  "State Bank of India",
  "The Hongkong & Shanghai Banking Corporation Ltd (HSBC)",
  "Union Bank of Colombo PLC",
];
