import "./VisitorForm.scss";
import React, { Fragment, useEffect, useState } from "react";
import ApiService from "../../services/ApiService";
import AppButton from "../../components/AppButton/AppButton";
import Checkbox from "@material-ui/core/Checkbox";
import { showNotification } from "../../services/HelperMethods";
import { Link, useHistory, useParams } from "react-router-dom";

export default function VisitorForm() {
  const [ischecked, setChecked] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [visitoName, setVisitoName] = useState("");
  const [visitorNumber, setVisitorNumber] = useState("");
  const [visitorNIC, setVisitorNIC] = useState("");
  const [visitorVehicelNo, setVisitorVehicelNo] = useState("");
  const { id } = useParams() as any;
  const apiService = new ApiService();

  useEffect(() => {
    getVisitor();
    console.log(id)
  }, [id]);

  async function getVisitor() {

    var result = await apiService.getVisitor(id).then((result) => {
      if (result.data.visitor == null) {
        showNotification("Error", "visitor not found", "error");
      }
      else {
        setVisitoName(result?.data?.visitor?.name)
        setVisitorNumber(result?.data?.visitor?.phoneNumber)
      }
    }).catch((result) => {
      showNotification("error", result.data.error, "error");
    });

  }
  async function handleFormsubmit() {
    if (visitoName === "") {
      showNotification("Error", "Name is required", "error");
      return;
    }
    if (visitorNumber === "") {
      showNotification("Error", "Mobile number is required", "error");
      return;
    }
    if (visitorNIC === "") {
      showNotification("Error", "NIC is required", "error");
      return;
    }

    if (ischecked) {
      if (visitorVehicelNo === "") {
        showNotification("Error", "Vehicle number is required", "error");
        return;
      }
    }

    var result = await apiService.saveVisitor({
      id: id,
      nIC: visitorNIC,
      vehicleNumber: visitorVehicelNo,
    }).then((result) => {
      console.log(result);
      if (result.data.success == true) {
        showNotification("success", "Visitor QR sent", "success");
        return;
      }
      else {
        showNotification("error", "QR Already Sent", "error");
        return;
      }
    }).catch((result) => {
      showNotification("error", result.data.error, "error");
    });;
  }

  return (
    <div className="section visitorform-wrapper">
      <div className="logo"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="body">
              <form>
                <div className="visitorform-box">
                  <p className="title">Visitor Details Form</p>
                  <input
                    type="text"
                    onChange={(e) => {
                      setVisitoName(e.target.value);
                    }}
                    value={visitoName}
                    placeholder="Enter visitor name"
                  />
                  <input
                    type="text"
                    onChange={(e) => {
                      setVisitorNumber(e.target.value);
                    }}
                    value={visitorNumber}
                    placeholder="Enter mobile number"
                  />
                  <input
                    type="text"
                    onChange={(e) => {
                      setVisitorNIC(e.target.value);
                    }}
                    value={visitorNIC}
                    placeholder="Enter NIC number"
                  />
                  <div className="check-box">
                    <Checkbox
                      checked={ischecked}
                      onChange={(e) => {
                        ischecked ? setChecked(false) : setChecked(true);
                      }}
                    ></Checkbox>
                    <p>Are you driving?</p>
                  </div>
                  {ischecked ? (
                    <input
                      type="text"
                      onChange={(e) => {
                        setVisitorVehicelNo(e.target.value);
                      }}
                      value={visitorVehicelNo}
                      placeholder="Enter vehical registration NO"
                    />
                  ) : (
                    <Fragment />
                  )}

                  <AppButton onClick={handleFormsubmit} isLoading={isLoading}>
                    Submit
                  </AppButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
