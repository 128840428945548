import "./TickCircle.scss";

import React from "react";

import Tick from "../../assets/images/icons/tick.svg";
import Minus from "../../assets/images/icons/minus.svg";
import User from "../../assets/images/icons/user.svg";
import Close from "../../assets/images/icons/close.svg";

interface TickCircleProps {
    icon?: "tick" | "minus" | "user" | "close";
    large?: false | true;
}

export default function TickCircle({ icon = "tick", large }: TickCircleProps) {
    return (
        <img
            src={icon === "tick" ? Tick : icon === "minus" ? Minus : icon === "user" ? User : Close}
            className={large ? "largeIcon" : "tickCircle"}
        ></img>
    );
}
