import React from "react";
import AppButton from "../../../components/AppButton/AppButton";

interface Props{
    link: string;
}
export default function ExcelTemplateDownload({link}: Props) {
  return (
    <div className="col-auto">
      <AppButton
        onClick={() => {
          window.open(
            link,
            "_blank"
          );
        }}
      >
        Download Template
      </AppButton>
    </div>
  );
}
