import moment from "moment";
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import AppLoading from "../../components/AppLoading/AppLoading";
import Navbar from "../../components/Navbar/Navbar";
import ProfileImage from "../../components/ProfileImage/ProfileImage";
import ApiService from "../../services/ApiService";
import FirebaseServices from "../../services/FirebaseServices";
import { handleErrors, showNotification, splitWord } from "../../services/HelperMethods";
import { UserDetailsContext } from "../../store/Store";
import PaymentFileInput from "../../components/FileInput/PaymentFileInput";

import "./Messages.scss";

export default function Broadcast() {

  const apiService = new ApiService();

  const firebaseServices = new FirebaseServices();

  const [friends, setFriends] = useState([] as any[]);
  const [selectAllCheckedState, setSelectAllCheckedState] = useState(false);
  const [checkedState, setCheckedState] = useState([false] as any[]);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null as any);

  const [user, setUser] = useContext(UserDetailsContext);
  const [filteredFriends, setFilteredFriends] = useState([] as any[]);
  const [role, setRole] = useState("All");
  useEffect(() => {
    // Update filteredFriends whenever friends or role changes
    filterFriends();
  }, [friends, role]);

  useEffect(() => {
    if (user) {
      getContacts();
    }
  }, [user]);

  useEffect(() => {
    if (friends.length > 0) {
      var tempValues = new Array(filteredFriends.length).fill(false);
      setCheckedState(tempValues)
    }
  }, [friends]);

  async function getContacts() {
    try {
      setIsLoading(true);
      let result = await apiService.getUserContacts();
      setFriends(result.data.buildingUsers);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleErrors(error);
    }
  }

  const filterFriends = () => {
    // Use role to filter friends array
    if (role === "All") {
      setFilteredFriends(friends);
    } else {
      const filtered = friends.filter((item: { role: string }) => item?.role === role)
      setFilteredFriends(filtered);
    }
  }

  const roles = [...Array.from(new Set(friends.map((item: { role: string }) => item?.role)))]
  // useEffect(() => {
  //   if (image?.url) {
  //   firebaseServices.getDb().collection("chat").doc(selectedProfile.chatId).collection("messages").add({
  //     message: image?.url,
  //     userId: user?.id,
  //     timestamp: firebaseServices.timeStamp(),
  //     type: 2
  //   })

  //   let data = {
  //     sendToId: selectedProfile?.userId
  //   }

  //   setImage("");

  //   apiService.SendMessagePushNotification(data);
  // }
  // }, [image])

  return (
    <div className="body wrapper-messaging">
      <AppLoading isLoading={isLoading} />
      <div className="container">
        <Navbar hideSettings={true} hideAdd={true} hideFilter={true}>
          <Link to="/messages">All Messages</Link>
          <Link className="active" to="/broadcast">Broadcast</Link>
        </Navbar>
        <div className="row row-messenger">
          <div className="col-md-5 profile-list">
            <input
              style={{ marginRight: "1rem", cursor: "pointer" }}
              type="checkbox"
              checked={selectAllCheckedState}
              onChange={() => {
                const newState = !selectAllCheckedState;
                setSelectAllCheckedState(newState);
                setCheckedState(new Array(friends.length).fill(newState));
              }}
            />
            <label htmlFor="select-all">Select All</label>
            {/* Filter dropdown for role */}
            <select
              className="form-control"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              <option value="All">All Roles</option>
              {/* Mapping roles */}
              {roles.map((roleOption,index)=>(
                <option key={index} value={roleOption}>
                  {roleOption}
                </option>
              ))}

            </select>
            {filteredFriends.map((item, i) => {
              return (
                <div key={i} onClick={() => { }} style={{ display: "flex" }}
                  className={"wrapper-person"}>
                  <input style={{ marginRight: "1rem", cursor: "pointer" }} type="checkbox" checked={checkedState[i]}
                    onChange={() => {
                      var temp = [...checkedState];
                      temp[i] = !temp[i];
                      setCheckedState(temp);
                      setSelectAllCheckedState(false); // Uncheck "Select All" when an individual checkbox is clicked
                    }} />
                  <ProfileImage style={{ width: 50, height: 50 }} src={item?.userProfilePath} />
                  <div className="wrapper-name" style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <p className="name">{item?.firstName + " " + item?.lastName}</p>
                    {item?.apartment
                      ? (<p style={{ fontWeight: 400, fontSize: 10 }}>
                        {"Floor "}{item?.apartment?.floor}{" Door "}{item?.apartment?.doorNumber}
                      </p>)
                      : (<p style={{ fontWeight: 400, fontSize: 10 }}>
                        {splitWord(item?.role)}
                      </p>)
                    }
                  </div>
                  <div className="wrapper-time"></div>
                </div>
              );
            })}
          </div>
          <div className="col-md-7 col-messaging">
            <div className="wrapper-messaging">
              {messageBodyUI()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function handleSendMessage() {
    checkedState.forEach((state, index) => {
      if (state || selectAllCheckedState) {
        let msg = message;

        if (message.length > 0) {
          firebaseServices.getDb().collection("chat").doc(filteredFriends[index]?.chatId).collection("messages").add({
            message: msg,
            userId: user?.id,
            timestamp: firebaseServices.timeStamp(),
          })
        }

        let data = {
          sendToId: filteredFriends[index]?.userId
        }

        apiService.SendMessagePushNotification(data);
      }
    })

    var tempValues = new Array(filteredFriends.length).fill(false);
    setCheckedState(tempValues)
    setMessage("");

    showNotification("Broadcast Sent", "Broadcast Sent", "success");
  }

  function messageBodyUI() {
    return (
      <div>
        {/* <div className="wrapper-person active" style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex" }}>
            <ProfileImage style={{ width: 50, height: 50 }} src={selectedProfile?.userProfilePath} />
            <div className="wrapper-name" style={{ display: "flex", flexDirection: "column" }}>
              <p className="name">{selectedProfile?.firstName + " "}{selectedProfile?.lastName}</p>
              {selectedProfile?.apartment
                ? (<p style={{ fontWeight: 400, fontSize: 10 }}>
                  {"Floor "}{selectedProfile?.apartment?.floor}{" Door "}{selectedProfile?.apartment?.doorNumber}
                </p>)
                : (<Fragment />)
              }
            </div>
          </div>
        </div> */}

        <form className="wrapper-type-message">
          <input type="text" value={message} onChange={(e) => { setMessage(e.target.value); }} placeholder="Type your message here.." />
          <div className="wrapper-icons">
            {/* <i className="fas fa-grin"></i> */}
            {/* <i className="fas fa-paperclip icon-attachment" onClick={() => { }} style={{ cursor: "pointer" }}>
              <PaymentFileInput
                onChange={(url) => {
                  if (url) {
                    setImage({ isBase64: true, url });
                  }
                }} />
            </i> */}
            {/* <i className="far fa-image"></i> */}
            <button type="submit" onClick={(e) => { e.preventDefault(); handleSendMessage(); }}
              style={{ backgroundColor: "transparent", border: "none", padding: 0, marginLeft: 10 }}>
              <i className="fas fa-location-arrow icon-send"></i>
            </button>
          </div>
        </form>
      </div>
    );
  }
}
