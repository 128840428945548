import React, { useState, useEffect } from 'react';
import AppLoading from '../AppLoading/AppLoading';

export default function AccountingView({ url }: { url: string | undefined }) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const iframe = document.getElementById('accounting-iframe') as HTMLIFrameElement;
        if (iframe) {
            iframe.onload = () => setLoading(false);
        }
    }, []);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: 'calc(100vh - 72px)',
        }}>
            {loading && <AppLoading style={{backgroundColor:'transparent'}} color={'green'} isLoading={true} />}
            <iframe
                id="accounting-iframe"
                style={{ width: '100%', flexGrow: 1, display: loading ? 'none' : 'block' }}
                src={process.env.REACT_APP_ACCOUNTING_URL! + "?t=" + localStorage.getItem("jwt") + url}
            ></iframe>
        </div>
    );
}