import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import { Table } from "react-bootstrap";
import moment from "moment";
import AppButton from "../../components/AppButton/AppButton";
import { DatePicker } from "antd";
import ApiService from "../../services/ApiService";
import { showNotification } from "../../services/HelperMethods";
import AppLoading from "../../components/AppLoading/AppLoading";
import "./Reporting.scss";
import ExcelExport from "../../components/ExcelExport/ExcelExport";

function VisitorReports() {
  let apiService = new ApiService();

  const [fromDate, setFromDate] = useState(moment().add("-1", "days"));
  const [toDate, setToDate] = useState(moment());
  const [isLoading, setIsLoading] = useState(false);
  const [visitorData, setVisitorData] = useState([] as any[]);
  const [visitorStatus, setVisitorStatus] = useState("");

  useEffect(() => {
    getVisitorData();
  }, []);

  async function getVisitorData() {
    setIsLoading(true);
    
  
    let data = {
      fromDate: fromDate,
      toDate: toDate,
    };

    
    await apiService
      .getVisitorReports(data)
      .then((result) => {
        let data = result.data;
        
        console.log("visitors");
        console.log(data);

        setVisitorData(
          data?.visitorReport.sort((a, b) => {
            return moment(b?.invitedDate).diff(a?.invitedDate);
          })
        );
      })
      .catch((err) => {
        showNotification("Unexpected Error", "Unexpected Error", "error");
      });

    setIsLoading(false);
  }

   // Function to extract desired attributes from a vistor object
   const extractAttributes = (visitor) => {
    const extractedVisitor = {
      "Invited By": visitor?.createdBy,
      "Apartment": visitor?.apartment,
      "Invited Date": visitor?.invitedDate !== null
        ? moment.utc(visitor?.invitedDate).local().format("LLL")
        : "-",
      "Arrival Date": visitor?.arrivedDate !== null
        ? moment.utc(visitor?.arrivedDate).local().format("LLL")
        : "-",
      "Visitor": visitor?.visitor,
      "NIC": visitor?.nic,
      "Vehicle Number": visitor?.vehicleNumber,
      "Location": visitor?.location,
      "Status": visitor?.isActive?"":"Canceled",
    };
    return extractedVisitor;
  };

  // Create a new list with objects containing only the desired attributes
  const modifiedVisitorList = visitorData.map(visitor => extractAttributes(visitor));


  return (
    <div className="body">
      <h2>Reports</h2>

      <div>
        <Navbar hideSettings={true} hideAdd={true} hideFilter={true}>
          <Link to="/reports/invoiceAgingReport">Invoice Aging Report</Link>
          <Link to="/reports/facilityUsageReport">Facility Usage Report</Link>
          <Link to="#" className="active">
            Visitor Reports
          </Link>
          {/* <Link to="/reports/securityRosterReports">
            Security Roster Reports
          </Link>
          <Link to="/reports/appUsageReport">App Usage Report</Link>
          <Link to="/reports/transactionReport">Transaction Report</Link> */}
          <Link to="/reports/supportTicketReport">Support Tickets Report</Link>
        </Navbar>
      </div>

      <div className="filter-area">
        <AppLoading isLoading={isLoading} />
        <div className="filter-area-item">
          <span>From : </span>
          <DatePicker
            defaultValue={moment().add("-1", "days")}
            onChange={(date) => {
              if (date) {
                setFromDate(date);
              }
            }}
            format={"DD/MM/YYYY"}
            allowClear={false}
          />
        </div>

        <div className="filter-area-item">
          <span>To : </span>
          <DatePicker
            defaultValue={moment()}
            onChange={(date) => {
              if (date) {
                setToDate(date);
              }
            }}
            format={"DD/MM/YYYY"}
            disabledDate={(date) => {
              let isd = moment(fromDate);
              return !date || date.isBefore(isd);
            }}
            allowClear={false}
          />
        </div>

        <div className="filter-area-item">
          <AppButton
            onClick={() => {
              getVisitorData();
            }}
          >
            Filter
          </AppButton>
        </div>

        <div className="filter-area-item">
          <ExcelExport excelData={modifiedVisitorList} fileName={"VisitorReports"} />
        </div>
      </div>

      <div style={{ maxWidth: "76rem", overflow: "auto" }}>
        <Table
          responsive="lg"
          borderless={true}
          id="table-to-xlx"
          className="table"
        >
          <thead>
            <tr>
              <th>Invited By</th>
              <th>Apartment</th>
              <th>Invited Date</th>
              <th>Arrival Date</th>
              <th>Visitor</th>
              <th>NIC</th>
              <th>Vehicle Number</th>
              <th>Location</th>
              <th>Status</th>
            </tr>
          </thead>

          <tbody>
            {visitorData?.length > 0 ? (
              visitorData?.map((visitor, index) => {
                console.log("sjaicbasojcnaojsc");
                console.log(visitor);

                
                return (
                  <tr key={index}>
                    <td>{visitor?.createdBy}</td>
                    <td>{visitor?.apartment}</td>
                    <td>
                      {" "}
                      {visitor?.invitedDate !== null
                        ? moment.utc(visitor?.invitedDate).local().format("LLL")
                        : "-"}{" "}
                    </td>
                    <td>
                      {" "}
                      {visitor?.arrivedDate !== null
                        ? moment.utc(visitor?.arrivedDate).local().format("LLL")
                        : "-"}
                    </td>
                    <td>{visitor?.visitor}</td>
                    <td>{visitor?.nic}</td>
                    <td>{visitor?.vehicleNumber}</td>
                    <td>{visitor?.location}</td>
                    <td>{visitor?.isActive?"":"Canceled"}</td>
                  </tr>
                );
              })
            ) : (
              <Fragment />
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
export default VisitorReports;