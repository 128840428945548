import { useState } from "react";
import { TbFileUpload } from "react-icons/tb";
import { showNotification } from "../../services/HelperMethods";

interface Props {
  handleFileChange: (event) => void;
}

export default function ExcelUpload({ handleFileChange }: Props) {
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <label
          htmlFor="file-upload"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            height: "16rem",
            border: "2px dashed #d1d5db",
            borderRadius: "0.5rem",
            cursor: "pointer",
            backgroundColor: "#f9fafb",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "1.25rem",
              paddingBottom: "1.25rem",
            }}
          >
            <TbFileUpload
              style={{
                width: "2.5rem",
                height: "2.5rem",
                marginBottom: "0.75rem",
              }}
            />
            <p style={{ marginBottom: "0.5rem" }}>
              <span>Click to upload</span>
            </p>
            <p>Excel (.xlsx, .xls) or CSV (.csv)</p>
          </div>
          <input
            style={{ display: "none" }}
            id="file-upload"
            type="file"
            className="hidden"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={handleFileChange}
          />
        </label>
      </div>
    </div>
  );
}
