import React, {useContext, useEffect, useState} from "react";
import ApiService from "../../services/ApiService";
import { handleErrors, saveToken } from "../../services/HelperMethods";
import { useHistory } from "react-router-dom";
import "./SelectCondominium.scss";
import {UserDetailsContext} from "../../store/Store";
import AppLoading from "../../components/AppLoading/AppLoading";

export default function SelectCondominium() {
  const apiService = new ApiService();
  const [userBuildings, setUserBuildings] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [user, setUser] = useContext(UserDetailsContext);

  useEffect(() => {
    getCondo();
  }, []);

  async function getCondo() {
    try {
      let result = (await apiService.getUserBuildingList()) as any;
      result = result.data;
      setUserBuildings(result.buildings);

    } catch (error) {
      handleErrors(error);
    }
  }
  return (
    <div className="section signup-wrapper">
      <AppLoading isLoading={isLoading}/>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="body">
              <div className="sign-up-box">
                <h2 className="mb-4">Select Your Condominium</h2>
                <div>
                  {userBuildings.map((building, i) => {
                    return (
                      <div
                        key={i}
                        onClick={() => {
                          handleSelect(building.id);
                        }}
                        className="mb-4"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          backgroundColor: "#f8f8f8",
                          padding: 10,
                          borderRadius: 5,
                          cursor: "pointer",
                          alignItems: "center",
                        }}
                      >
                        <img
                          className="round-img mr-2"
                          src={building?.building?.imageURL}
                        />
                        <div>
                          <div
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            {building?.building?.name}
                          </div>
                          {building?.apartment &&
                              <div>
                                  Floor: {building?.apartment?.floor}
                                  Door number: {building?.apartment?.doorNumber}
                              </div>
                          }
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  async function handleSelect(buildingId) {
    setIsLoading(true);
    try {
      await apiService.setActiveBuilding({ buildingId: buildingId });
      let result = (await apiService.appAuthTempToken()) as any;
      result = result.data;
      saveToken(result.token);
      let user = await apiService.getUserDetails();
      user = user.data;
      setUser(user);
      history.push("/dashboard");
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  }
}
