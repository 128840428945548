import React, { Fragment, useContext } from "react";
import DashboardIcon from "../../../assets/images/icons/dashboard.svg";
import SettingsIcon from "../../../assets/images/icons/settings.svg";
import ApartmentIcon from "../../../assets/images/icons/apartment.svg";
import StaffIcon from "../../../assets/images/icons/staff.svg";
import { UserDetailsContext } from "../../../store/Store";
import { SidebarLink } from "../../../components/Sidebar/Sidebar";
import { FaExternalLinkAlt } from "react-icons/fa";


export default function AdminSidebar() {
    const [user] = useContext(UserDetailsContext);

    if (!user || user?.userType != "SystemAdmin") {
        return <div className="sidebar-wrapper-admin">
            <div className="sidenav" >
                <span className="badge badge-primary">ADMIN</span>
                <div className="logo">
                    <img src="/images/logo.png" />
                </div>
                <div>
                    <div className="side-menu">
                        <SidebarLink to={"/admin/"}>
                            <div className="nav-item">
                                <img src={DashboardIcon} />
                                <div className="text">Login</div>
                            </div>
                        </SidebarLink>
                    </div>
                </div>
            </div>
        </div>
    }

    return (
        <div className="sidebar-wrapper-admin">
            <div className="sidenav">

                <span className="badge badge-primary">ADMIN</span>
                <div className="logo">
                    <img src="/images/logo.png" />
                </div>
                <div>

                    <div className="side-menu">
                        <SidebarLink to={"/admin/dashboard"}>
                            <div className="nav-item">
                                <img src={DashboardIcon} />
                                <div className="text">Admin Home</div>
                            </div>
                        </SidebarLink>
                        <SidebarLink to={"/admin/condominium"}>
                            <div className="nav-item">
                                <img src={SettingsIcon} />
                                <div className="text">Condominiums</div>
                            </div>
                        </SidebarLink>
                        <SidebarLink to={"/admin/users"}>
                            <div className="nav-item">
                                <img src={StaffIcon} />
                                <div className="text">Users</div>
                            </div>
                        </SidebarLink>
                        <SidebarLink to={"/admin/apartments"}>
                            <div className="nav-item">
                                <img src={ApartmentIcon} />
                                <div className="text">Apartments</div>
                            </div>
                        </SidebarLink>
                        <SidebarLink to={"/admin/bulk"}>
                            <div className="nav-item">
                                <img src={SettingsIcon} />
                                <div className="text">Bulk Upload</div>
                            </div>
                        </SidebarLink>
                        <SidebarLink to={"/admin/RefundTickets"}>
                            <div className="nav-item">
                                <img src={SettingsIcon} />
                                <div className="text">Refund Tickets</div>
                            </div>
                        </SidebarLink>
                        <SidebarLink to={"/admin/RequestBuilding"}>
                            <div className="nav-item">
                                <img src={SettingsIcon} />
                                <div className="text">Request Building</div>
                            </div>
                        </SidebarLink>
                        

                        <h2 style={{ color: "white", fontSize: 15, padding: 20 }}>OpenSearch</h2>

                        <a className="nav-link extrnal" target="_blank" href="https://search-liwe-prod-opensearch-sk6wjculad7x52s7wnmn4psjo4.ap-south-1.es.amazonaws.com/_dashboards/app/dashboards?security_tenant=global#/view/a5d00450-46cc-11ee-9712-bb8c93975135?_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-7d%2Cto%3Anow))">
                            <div className="nav-item">
                                <div className="text">Analytics</div>&nbsp;
                                <FaExternalLinkAlt color="white" size={12} />
                            </div>
                        </a>
                        <a className="nav-link extrnal" target="_blank" href="https://search-liwe-prod-opensearch-sk6wjculad7x52s7wnmn4psjo4.ap-south-1.es.amazonaws.com/_dashboards/app/observability-logs#/" >
                            <div className="nav-item">
                                <div className="text">Logs</div>&nbsp;
                                <FaExternalLinkAlt color="white" size={12} />
                            </div>
                        </a>


                        <SidebarLink className="logout" to={"/admin/true"}>
                            <div className="nav-item">
                                <img src={SettingsIcon} />
                                <div className="text">Logout</div>
                            </div>
                        </SidebarLink>
                    </div>
                </div>
            </div>
        </div>
    );
}
