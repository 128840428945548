import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppLoading from "../../components/AppLoading/AppLoading";
import ApiService from "../../services/ApiService";
import { formatMoney, splitWord } from "../../services/HelperMethods";

import "./PrintInvoice.scss"

export default function PrintInvoice() {

    const apiService = new ApiService();

    const { invoiceId } = useParams() as any;

    const [isLoading, setIsLoading] = useState(false);
    const [invoice, setInvoice] = useState(null as any);

    useEffect(() => {

        if (invoiceId) {

            setIsLoading(true)

            let data = {
                refNumber: invoiceId
            }

            apiService.getInvoiceByRefNumber(data).then((result) => {

                let data = result.data
                console.log(data);

                setInvoice(data)
            }).catch((err) => {
                console.log(err);
            })
        }

        setIsLoading(false)

    }, [invoiceId])

    useEffect(() => {
        if (invoice) {
            setTimeout(() => {
                openPrint();
            }, 2000)
        }
    }, [invoice])

    function openPrint() {
        window.print()
    }

    return (
        <div className="print-invoice-container">
            <AppLoading isLoading={isLoading} />
            <div className="print-invoice-wrapper">
                <div className="info-wrapper">
                    <div className="invoice-tower">
                        <h3 style={{ color: "#8ACB87" }}>#{invoice?.refNumber}</h3>
                        <h1>{invoice?.building?.name}</h1>
                    </div>
                    <div className="tower-address">
                        {invoice?.building?.addressLine1
                            ? (<span>{invoice?.building?.addressLine1.toString().trim() + ","}</span>)
                            : (<Fragment />)
                        }

                        {invoice?.building?.addressLine2
                            ? (<span>{invoice?.building?.addressLine2.toString().trim() + ","}</span>)
                            : (<Fragment />)
                        }

                        {invoice?.building?.street
                            ? (<span>{invoice?.building?.street.toString().trim() + ","}</span>)
                            : (<Fragment />)
                        }

                        {invoice?.building?.city
                            ? (<span>{invoice?.building?.city.toString().trim() + ","}</span>)
                            : (<Fragment />)
                        }

                        <span>{"Sri Lanka"}</span>
                    </div>
                    <div>
                        <h3>{splitWord(invoice?.invoiceType)}</h3>
                        {invoice?.invoiceType == "AmenityBooking"
                            ? (<h4>{moment.utc(invoice?.bookedDate).local().format("Do MMMM YYYY")}{" | "}
                                {moment.utc(invoice?.startTime).local().format("LT") + " - " +
                                    moment.utc(invoice?.endTime).local().format("LT")}
                            </h4>)
                            : (<Fragment />)
                        }
                    </div>
                    <div className="issue-due-wrapper">
                        <div className="issue">
                            <span style={{ fontSize: "14px" }}>Issued</span>
                            <span style={{ fontSize: "16px" }}>{moment.utc(invoice?.issuedDate).local().format("Do MMMM YYYY")}</span>
                        </div>
                        <div className="due">
                            <span style={{ fontSize: "14px" }}>Due</span>
                            <span style={{ fontSize: "16px" }}>{moment.utc(invoice?.dueDate).local().format("Do MMMM YYYY")}</span>
                        </div>
                    </div>
                    <div className="invoice-for-wrapper">
                        <span style={{ fontSize: "14px" }}>Billed To</span>
                    </div>
                    <div className="customer-address-wrapper">
                        <span>{invoice?.createdFor?.name}</span>
                        <span>{"Apt " + invoice?.createdFor?.doorNumber.toString().trim()},</span>

                        {invoice?.building?.addressLine1
                            ? (<span>{invoice?.building?.addressLine1.toString().trim() + ","}</span>)
                            : (<Fragment />)
                        }

                        {invoice?.building?.addressLine2
                            ? (<span>{invoice?.building?.addressLine2.toString().trim() + ","}</span>)
                            : (<Fragment />)
                        }

                        {invoice?.building?.street
                            ? (<span>{invoice?.building?.street.toString().trim() + ","}</span>)
                            : (<Fragment />)
                        }

                        {invoice?.building?.city
                            ? (<span>{invoice?.building?.city.toString().trim() + ","}</span>)
                            : (<Fragment />)
                        }

                        <span>{"Sri Lanka"}</span>
                    </div>
                </div>
                <div className="table-wrapper">
                    <div className="header-wrapper">
                        <div className="header-item">Item</div>
                        <div className="header-item">Total</div>
                    </div>

                    {invoice?.invoiceType == "AmenityBooking"
                        ? (<div>
                            {invoice?.invoiceItems?.length > 0
                                ? (invoice?.invoiceItems?.map((item, index) => {
                                    return (
                                        <>
                                            {item?.description != "Service Charge" && item?.description != "VAT/NBT"
                                                ? (<div key={index} className="row-wrapper">
                                                    <div className="item">
                                                        {item?.description}
                                                    </div>
                                                    <div className="item">
                                                        LKR {formatMoney(item?.amount)}
                                                    </div>
                                                </div>)
                                                : (<Fragment />)
                                            }
                                        </>
                                    )
                                }))
                                : (<Fragment />)
                            }
                            {invoice?.invoiceItems?.length > 0
                                ? (invoice?.invoiceItems?.map((item, index) => {
                                    return (
                                        <>
                                            {item?.description == "Service Charge" || item?.description == "VAT/NBT"
                                                ? (<div key={index} className="row-wrapper">
                                                    <div className="item">
                                                        {item?.description + " " + item?.percentage + "%"}
                                                    </div>
                                                    <div className="item">
                                                        LKR {formatMoney(item?.amount)}
                                                    </div>
                                                </div>)
                                                : (<Fragment />)
                                            }
                                        </>
                                    )
                                }))
                                : (<Fragment />)
                            }
                            {invoice?.refundableDeposit > 0
                                ? (<>
                                    <div style={{ borderBottom: "0.1rem solid #4d4d4d", marginBottom: "0.3rem" }} />
                                    <div className="row-wrapper">
                                        <div style={{ fontWeight: "bold" }} className="item">Sub Total</div>
                                        <div className="item" style={{ fontWeight: "bold" }}>
                                            LKR {formatMoney(invoice?.subTotal)}
                                        </div>
                                    </div>
                                    <div className="row-wrapper">
                                        <div className="item">Refundable Deposit</div>
                                        <div className="item">
                                            LKR {formatMoney(invoice?.refundableDeposit)}
                                        </div>
                                    </div>
                                </>)
                                : (<Fragment />)
                            }
                            <div style={{ borderBottom: "0.1rem solid #8ACB87", marginBottom: "0.3rem" }} />
                            <div className="row-wrapper">
                                <div style={{ fontWeight: "bold", color: "#8ACB87" }} className="item">Total</div>
                                <div className="item" style={{ fontWeight: "bold", color: "#8ACB87" }}>
                                    LKR {formatMoney(invoice?.total)}
                                </div>
                            </div>
                        </div>)
                        : (invoice?.invoiceType == "CustomInvoice"
                            ? (<div>
                                {invoice?.invoiceItems?.length > 0
                                    ? (invoice?.invoiceItems?.map((item, index) => {
                                        return (
                                            <>
                                                {item?.description != "Service Charge" && item?.description != "VAT/NBT"
                                                    ? (<div key={index} className="row-wrapper">
                                                        <div className="item">
                                                            {item?.description}
                                                        </div>
                                                        <div className="item">
                                                            LKR {formatMoney(item?.amount)}
                                                        </div>
                                                    </div>)
                                                    : (<Fragment />)
                                                }
                                            </>
                                        )
                                    }))
                                    : (<Fragment />)
                                }
                                {invoice?.invoiceItems?.length > 0
                                    ? (invoice?.invoiceItems?.map((item, index) => {
                                        return (
                                            <>
                                                {item?.description == "Service Charge" || item?.description == "VAT/NBT"
                                                    ? (<div key={index} className="row-wrapper">
                                                        <div className="item">
                                                            {item?.description + " " + item?.percentage + "%"}
                                                        </div>
                                                        <div className="item">
                                                            LKR {formatMoney(item?.amount)}
                                                        </div>
                                                    </div>)
                                                    : (<Fragment />)
                                                }
                                            </>
                                        )
                                    }))
                                    : (<Fragment />)
                                }
                                <div style={{ borderBottom: "0.1rem solid #8ACB87", marginBottom: "0.3rem" }} />
                                <div className="row-wrapper">
                                    <div style={{ fontWeight: "bold", color: "#8ACB87" }} className="item">Total</div>
                                    <div className="item" style={{ fontWeight: "bold", color: "#8ACB87" }}>
                                        LKR {formatMoney(invoice?.total)}
                                    </div>
                                </div>
                            </div>)
                            : (<div>
                                {invoice?.invoiceItems?.length > 0
                                    ? (invoice?.invoiceItems?.map((item, index) => {
                                        return (
                                            <>
                                                {item?.description != "Service Charge" && item?.description != "VAT/NBT"
                                                    ? (<div key={index} className="row-wrapper">
                                                        <div className="item">
                                                            {item?.description}
                                                        </div>
                                                        <div className="item">
                                                            LKR {formatMoney(item?.amount)}
                                                        </div>
                                                    </div>)
                                                    : (<Fragment />)
                                                }
                                            </>
                                        )
                                    }))
                                    : (<Fragment />)
                                }
                                {invoice?.invoiceItems?.length > 0
                                    ? (invoice?.invoiceItems?.map((item, index) => {
                                        return (
                                            <>
                                                {item?.description == "Service Charge" || item?.description == "VAT/NBT"
                                                    ? (<div key={index} className="row-wrapper">
                                                        <div className="item">
                                                            {item?.description}
                                                        </div>
                                                        <div className="item">
                                                            LKR {formatMoney(item?.amount)}
                                                        </div>
                                                    </div>)
                                                    : (<Fragment />)
                                                }
                                            </>
                                        )
                                    }))
                                    : (<Fragment />)
                                }
                                <div style={{ borderBottom: "0.1rem solid #8ACB87", marginBottom: "0.3rem" }} />
                                <div className="row-wrapper">
                                    <div style={{ fontWeight: "bold", color: "#8ACB87" }} className="item">Total</div>
                                    <div className="item" style={{ fontWeight: "bold", color: "#8ACB87" }}>
                                        LKR {formatMoney(invoice?.total)}
                                    </div>
                                </div>
                            </div>)
                        )
                    }
                </div>
                <div className="liwe-wrapper">
                    <p style={{ marginRight: "0.4rem" }}>Powered By </p><img style={{ width: "4rem", height: "2rem" }} src="/images/logoYellow.png" />
                </div>
            </div>
        </div>
    )
}