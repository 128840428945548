import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AppLoading from "../../components/AppLoading/AppLoading";
import Navbar from "../../components/Navbar/Navbar";
import ApiService from "../../services/ApiService";
import { formatMoney, getApartmentName, handleErrors, showNotification, splitWord } from "../../services/HelperMethods";
import { useHistory } from "react-router-dom";
import AppCalendar from "../../components/AppCalendar/AppCalendar";
import Drawer from "../../components/Drawer/Drawer";
import ProfileImage from "../../components/ProfileImage/ProfileImage";
import DrawerHeader from "../../components/Drawer/DrawerHeader/SideDrawerHeader";

import "./FacilityBookings.scss";
import AppButton from "../../components/AppButton/AppButton";
import { DatePicker, TimePicker } from "antd";
import Filter from "../../components/Filter/Filter";

function Bookings() {

  const apiService = new ApiService();

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [bookings, setBookings] = useState([] as any[]);
  const [selectedBooking, setSelectedBooking] = useState(null as any);
  const [weekNumber, setWeekNumber] = useState(moment().week());
  const [amenitiesList, setAmenitiesList] = useState([] as any[])
  const [amenity, setAmenity] = useState("All");
  const [isFiltered, setIsFiltered] = useState(false);
  const [filteredAmenities, setFilteredAmenities] = useState([] as any[]);
  const [showFilter, setShowFilter] = useState(false)
  const [showNewBooking, setShowNewBooking] = useState(false);
  const [amenities, setAmenities] = useState([] as any);


  const [selectedAmenity, setSelectedAmenity] = useState(null as any);
  const [selectedResident, setSelectedResident] = useState("" as any);
  const [allResidents, setAllResidents] = useState([] as any);
  const [residents, setResidents] = useState([] as any);
  const [bookingStartDate, setBookingStartDate] = useState(moment());
  const [bookingEndDate, setBookingEndDate] = useState(moment());
  const { RangePicker } = DatePicker;
  const [totalCost, setTotalCost] = useState(0);
  const [selectedBookingAvailability, setSelectedBookingAvailability] = useState(null as any)
  const [canBook, setCanBook] = useState(false);
  const [apartments, setApartments] = useState([] as any[]);
  const [apartmentId, setApartmentId] = useState(0);


  useEffect(() => {
    getFacilities();
    getResidents();
    getApartments();
  }, [])

  useEffect(() => {
    if (apartmentId) {
      getUserByApartmentId(apartmentId)
    }
    setSelectedResident("");
  }, [apartmentId])

  useEffect(() => {
    if (bookings.length > 0) {
      getAmenitiesList();
    }
  }, [bookings]);

  useEffect(() => {
    getBookings();
  }, [weekNumber]);

  useEffect(() => {
    if (!selectedAmenity) return;
    const totalMinutes = moment(bookingEndDate).diff(bookingStartDate, 'minutes');
    let cost = (totalMinutes * selectedAmenity.feePerMinute) + selectedAmenity.depositFee;
    cost += cost * (selectedAmenity.serviceChargePercentage / 100);
    setTotalCost(cost)

    const weekNum = bookingStartDate.format('d');
    const availability = selectedAmenity.amenityAvailabilities.find(a => a.weekNumber == weekNum);
    setSelectedBookingAvailability(availability)
  }, [bookingStartDate, bookingEndDate, selectedAmenity])

  useEffect(() => {
    if (!selectedAmenity) {
      return;
    }
    let isBetweenStart = false;
    let isBetweenEnd = false;

    if (selectedAmenity.amenityAvailabilityStatus == 1) {
      selectedBookingAvailability?.times.forEach(t => {
        const startDate = moment.utc(t.startDate).local()
          .set({ date: bookingStartDate.date(), month: bookingStartDate.month(), year: bookingStartDate.year() })
          .add(-1, 'minutes')
          ;
        const endDate = moment.utc(t.endDate).local()
          .set({ date: bookingStartDate.date(), month: bookingStartDate.month(), year: bookingStartDate.year() })
          .add(1, 'minutes')
          ;

        if (bookingStartDate.isBetween(startDate, endDate)) {
          isBetweenStart = true;
        }

        if (bookingEndDate.isBetween(startDate, endDate)) {
          isBetweenEnd = true;
        }
      });
      if (isBetweenStart && isBetweenEnd) {
        setCanBook(true);
      } else {
        setCanBook(false)
      }
    } else {
      setCanBook(true);
    }

  }, [selectedBookingAvailability, bookingStartDate, bookingEndDate, selectedAmenity])

  async function getUserByApartmentId(Id) {
    try {
      let data = {
        apartmentId: Id
      };
      setIsLoading(true);
      let result = await apiService.getUserByApartmentID(data);
      setResidents(result?.data?.users);
      setIsLoading(false);
    }
    catch (error) {
      handleErrors(error);
      setIsLoading(false);
    }
  }

  async function getApartments() {
    await apiService.getApartments().then((result) => {
      let data = result.data;
      setApartments(data?.apartment);
      setIsLoading(false);
    });
  }

  function getAmenitiesList() {
    const amenitiesList = [] as any[];
    if (bookings.length > 0) {
      bookings.forEach(booking => {
        if (booking?.amenityName) {
          amenitiesList.push(booking?.amenityName)
        }
      });
    }

    setAmenitiesList(amenitiesList.filter(onlyUnique))
  }

  async function getResidents() {
    let result = await apiService.getBuildingUsers();
    setAllResidents(result?.data?.users);
  }

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  async function getBookings() {
    setIsLoading(true);
    let from = moment().day("Sunday").week(weekNumber);
    let to = moment().day("Saturday").week(weekNumber);

    try {
      let result = (await apiService.getBookings({ from: from, to: to, })) as any;
      result = result.data;
      setBookings(result.amenityBookings);
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  }

  function filter() {
    setIsFiltered(true)
    setShowFilter(false);
    let filtered = [] as any;
    if (amenity == "All") {
      filtered = bookings;
      setFilteredAmenities(filtered)
    } else {
      filtered = bookings.filter(function (user) {
        return user?.amenityName == amenity;
      });
    }
    setFilteredAmenities(filtered)
  }

  function clear() {
    setAmenity("All");
    setIsFiltered(false);
    setFilteredAmenities([])
    setShowFilter(false);
  }

  async function getFacilities() {
    setIsLoading(true);
    try {
      let result = (await apiService.getAmenities()) as any;
      result = result.data;
      result = result.amenities.filter(amenity => amenity.active)
      setAmenities(result);
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  }

  async function declineBooking(bookingId) {
    setIsLoading(true);
    let data = {
      amenityBookingId: bookingId
    }
    await apiService.declineBooking(data).then((result) => {
      let data = result.data;
      if (data?.isSuccessful) {
        showNotification("Booking Cancelled", "Booking Cancelled", "success")
        getBookings();
      }
    }).catch(() => {
      showNotification("Error Cancelling Booking", "Error Cancelling Booking", "error")
    })
    setIsLoading(false);
  }

  async function createBooking() {
    setIsLoading(true);
    let data = {
      amenityId: selectedAmenity.id,
      requestByUserId: selectedResident.id,
      startDate: bookingStartDate,
      endDate: bookingEndDate,
      ApartmentId: apartmentId,
    }
    try {
      var result = await apiService.managementAmenityBooking(data);
      showNotification("Success", "Successfully Creating Booking", "success")
      getBookings();
    } catch (e) {
      handleErrors(e);
    }
    setIsLoading(false);
  }

  return (
    <div className="body booking-wrap ">
      <AppLoading isLoading={isLoading} />
      <h2>Facilities</h2>
      <div className="facility-navbar-wrap">
        <Navbar hideSettings={true} onAddButtonClick={() => {
          setShowNewBooking(true)
        }} hideFilter={false} onFilterButtonClick={() => {
          setShowFilter(!showFilter)
        }}>
          <Link className="active" to="/facility/bookings">Bookings</Link>
          <Link to="/facility/manage">Manage Facilities</Link>
        </Navbar>

        <div className="week-select">
          <button className="btn" onClick={() => {
            setWeekNumber(weekNumber - 1);
          }}>
            <i className="fas fa-angle-left" />
          </button>

          {moment().day("Sunday").week(weekNumber).format("DD MMM")}{" "}
          to{" "}
          {moment().day("Saturday").week(weekNumber).format("DD MMM")}
          (Week {weekNumber})

          <button className="btn" onClick={() => {
            setWeekNumber(weekNumber + 1);
          }}>
            <i className="fas fa-angle-right" />
          </button>
        </div>

      </div>


      <div>
        <AppCalendar onBookingClick={(booking) => {
          setSelectedBooking(booking);
        }} weekNumber={weekNumber}
          bookings={filteredAmenities.length > 0 && isFiltered ? filteredAmenities : bookings} />
      </div>

      <Drawer className={"facility-booking-drawer"} isOpen={selectedBooking != null}>
        <DrawerHeader onClose={() => {
          setSelectedBooking(null);
        }}
          profileImage={selectedBooking?.amenityImageURL}>
          <div style={{ textAlign: "center" }}>
            <p className="title">{selectedBooking?.amenityName}</p>
            <p className="sub-title">
              {splitWord(selectedBooking?.amenityType)}
            </p>
          </div>
        </DrawerHeader>

        <div className="body">
          <div className="row">
            <div className="col-md-12">
              <div className="wrapper-reserved-by">
                <p className="title">Reserved By</p>
                <ProfileImage src={selectedBooking?.bookedBy?.profilePictureURL}
                  className={"reserved-profile-image"} />
                <div className="wrapper-name">
                  <p className="title">
                    {selectedBooking?.bookedBy?.name}
                  </p>
                  <p className={"sub-title"}>
                    Door {selectedBooking?.bookedBy?.floor}{" "}| Floor{" "}
                    {selectedBooking?.bookedBy?.doorNumber}
                  </p>
                </div>
              </div>

              <div className="wrapper-booking-summary">
                <p className="title">Booking Summary</p>

                <div className="wrapper-date-and-time">
                  {(moment.utc(selectedBooking?.bookedDate).local().format("MMMM DD, YYYY").toString()
                    !== moment.utc(selectedBooking?.endTime).local().format("MMMM DD, YYYY").toString())
                    ? (<>
                      <div className="wrapper-time-header">
                        <p>Start Date & Time</p>
                      </div>
                      <div className="wrapper-date">
                        <i className="far fa-calendar icon-date" />
                        <p>
                          {moment.utc(selectedBooking?.bookedDate).local().format("MMMM DD, YYYY")}
                        </p>
                      </div>

                      <div className="wrapper-time" style={{ marginLeft: "1rem" }}>
                        <a>-</a>
                        <i style={{ marginLeft: "1rem" }} className="far fa-clock icon-clock" />
                        <p>
                          {moment.utc(selectedBooking?.startTime).local().format("h:mm a")}
                        </p>
                      </div>

                      <div className="wrapper-time-header">
                        <p>End Date & Time</p>
                      </div>
                      <div className="wrapper-date">
                        <i className="far fa-calendar icon-date" />
                        <p>
                          {moment.utc(selectedBooking?.endTime).local().format("MMMM DD, YYYY")}
                        </p>
                      </div>

                      <div className="wrapper-time" style={{ marginLeft: "1rem" }}>
                        <a>-</a>
                        <i style={{ marginLeft: "1rem" }} className="far fa-clock icon-clock" />
                        <p>
                          {moment.utc(selectedBooking?.endTime).local().format("h:mm a")}
                        </p>
                      </div>
                    </>)
                    : (<>
                      <div className="wrapper-date">
                        <i className="far fa-calendar icon-date" />
                        <p>
                          {moment.utc(selectedBooking?.bookedDate).local().format("MMMM DD, YYYY")}
                        </p>
                      </div>

                      <div className="wrapper-time" style={{ marginLeft: "0.6rem" }}>
                        <i className="far fa-clock icon-clock" />
                        <p>
                          {moment.utc(selectedBooking?.startTime).local().format("h:mm a")}
                          {" - "}
                          {moment.utc(selectedBooking?.endTime).local().format("h:mm a")}
                        </p>
                      </div>
                    </>)

                  }

                  <div className="wrapper-fees">
                    <i className="fas fa-lock icon-lock" />
                    <p>Fees &amp; Charges</p>

                    <div className="wrapper-charges">
                      <div className="wrapper-charge">
                        <p className="charge-type">
                          Facility Charges
                        </p>
                        <p className="charge">
                          LKR{" "}{formatMoney(selectedBooking?.hourlyCharge)}
                          {" x "}
                          {moment.duration(moment(selectedBooking?.endTime)
                            .diff(moment(selectedBooking?.startTime))).asHours().toFixed(0)}
                        </p>
                      </div>

                      <div className="wrapper-charge">
                        <p className="charge-type">
                          Refundable Deposit
                        </p>
                        <p className="charge">
                          LKR{" "}{formatMoney(selectedBooking?.depositFee)}
                        </p>
                      </div>

                      <div className="wrapper-charge">
                        <p className="charge-type">
                          Service Charges
                        </p>
                        <p className="charge">
                          {selectedBooking?.serviceCharge} %
                        </p>
                      </div>

                      <div className="wrapper-charge">
                        <p className="charge-type">
                          Total Booking Fees
                        </p>
                        <p className="charge">
                          LKR{" "}{formatMoney(selectedBooking?.totalBookingFee)}
                        </p>
                      </div>

                      <div className="wrapper-charge wrapper-total">
                        <p className="charge-type">
                          {selectedBooking?.bookingStatus == "Cancelled"
                            ? ("Total Refundable")
                            : ("Total Payable")
                          }
                        </p>
                        <p className="charge">
                          {selectedBooking?.totalPayable > 0
                            ? ("LKR " + formatMoney(selectedBooking?.totalPayable))
                            : selectedBooking?.bookingStatus == "Cancelled"
                              ? ("Refunded")
                              : ("Paid")
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {selectedBooking?.payments?.length > 0
                ? (<div className="wrapper-payment-summary">
                  <p style={{ fontWeight: 600 }} className="title">Payment Summary</p>

                  {selectedBooking?.payments?.map((payment, index) => {
                    return (
                      <div className="payment" key={index}>
                        {payment?.acceptedBy != null
                          ? (<div className="payment-item">
                            <p className="description">Paid To</p>
                            <p className="text">{payment?.acceptedBy?.name}</p>
                          </div>)
                          : (<Fragment />)
                        }
                        <div className="payment-item">
                          <p className="description">Payment Date</p>
                          <p className="text">
                            {moment.utc(payment?.paidDate).local().format("MMMM DD, YYYY")}
                          </p>
                        </div>
                        <div className="payment-item">
                          <p className="description">Amount</p>
                          <p className="text">{"LKR " + formatMoney(payment?.amount)}</p>
                        </div>
                        <div className="payment-item">
                          <p className="description">Paid via</p>
                          <p className="text">{payment?.paymentMethod == "Card"
                            ? "LIWE App"
                            : splitWord(payment?.paymentMethod)
                          }</p>
                        </div>
                        <div className="payment-item">
                          <p className="description">Status</p>
                          <p className="text">{splitWord(payment?.paymentStatus)}</p>
                        </div>
                      </div>
                    )
                  })}
                </div>)
                : (<Fragment />)
              }

              {/* <button className="btn-collect-payment">Edit Booking</button> */}
              {(selectedBooking?.bookingStatus == "Active") || (selectedBooking?.bookingStatus == "PendingPayment")
                ? (<p onClick={() => {
                  declineBooking(selectedBooking?.id)
                }} className="link-decline">Decline Booking</p>)
                : (<Fragment />)}
            </div>
          </div>
        </div>
      </Drawer>

      <Drawer className={"add-booking"} isOpen={showNewBooking}>
        <DrawerHeader onClose={() => {
          setShowNewBooking(false)
        }} profileImage={selectedAmenity?.imageUrl
        }>
          <div style={{ textAlign: 'center' }}>
            <p className="title">{selectedAmenity?.name}</p>
            {(selectedAmenity?.type) &&
              <p className="sub-title">
                {selectedAmenity.type == 1 ? "Exclusive" : "Shared"}
              </p>
            }
          </div>
        </DrawerHeader>

        <div className="p-2">
          <div className="row no-gutter">
            <div className="col-12">
              <div className="form-group">
                <label>Select Amenity</label>
                <select onChange={(val) => {
                  const a = amenities.find((a) => (a.id == val.target.value));
                  setSelectedAmenity(a)
                }} className={"form-control input-lbl white-select-input"}>
                  <option value={""}></option>
                  {amenities?.map((amenity: any, index) => {
                    return (
                      <option value={amenity.id} key={index}>
                        {amenity?.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col-6">
                    <label>Apartment</label>
                    <select style={{ border: "1px solid #4bbb46" }} className="form-control"
                      onChange={(e) => {
                        setApartmentId(parseInt(e.target.value));
                      }}>
                      <option value={0}>Select Apartment</option>
                      {apartments?.map((apartment, i) => {
                        return (
                          <option value={apartment?.id} key={i}>
                            {getApartmentName(apartment?.location, apartment?.floor, apartment?.doorNumber)}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-6">
                    <label>Select Resident</label>
                    <select value={selectedResident?.id ? selectedResident.id : ""} onChange={(val) => {
                      const a = allResidents.find((a) => (a.id == val.target.value));
                      setSelectedResident(a)
                    }} className={"form-control input-lbl white-select-input"}>
                      <option value={""}>Select Resident</option>
                      {residents?.map((user: any, index) => {
                        return (
                          <option value={user.id} key={index}>
                            {user.firstName} {user.lastName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

              </div>

            </div>
            {selectedAmenity && selectedResident &&
              <div className="col-12">
                {/* <div className="form-group">
                  <label>Booking date</label>
                  <DatePicker className="form-control" value={bookingStartDate}
                    onChange={(d) => {
                      if (d) {
                        setBookingStartDate(d);
                        setBookingEndDate(d);
                      }
                    }} />
                </div> */}
                <div className="form-group">
                  <label>Available Time</label>
                  {selectedAmenity?.amenityAvailabilityStatus == 0 ? <div style={{ fontWeight: 'bold' }}>All Day</div> :
                    selectedBookingAvailability?.times?.length ?

                      selectedBookingAvailability?.times.map((time, key) => {
                        return (
                          <div className={"available-times"} key={key}>
                            {moment.utc(time.startDate).local().format("YYYY/MM/DD :- hh:mm A")}
                            &nbsp;-&nbsp;
                            {moment.utc(time.endDate).local().format("hh:mm A")}
                          </div>
                        )
                      })
                      : <div style={{ color: 'red' }}>No available time today</div>
                  }
                </div>
                <div className="form-group">
                  <label>Booking date & time:</label>
                  <div className="row">
                    <div className="col">
                      <DatePicker value={bookingStartDate}
                        clearIcon={null}
                        format={"DD/MM/YYYY"}
                        onChange={(val: any) => {
                          if (val) {
                            setBookingStartDate(val);
                            setBookingEndDate(val);
                          }
                          // if (val) {
                          //   setBookingStartDate(val);
                          // }
                        }} />
                      <br />
                      <br />
                      <DatePicker value={bookingEndDate}
                        clearIcon={null}
                        format={"DD/MM/YYYY"}
                        onChange={(val: any) => {
                          if (val) {
                            setBookingEndDate(val);
                          }
                        }} />
                    </div>
                    <div className="col">
                      <TimePicker value={bookingStartDate}
                        clearIcon={null}
                        format={"hh:mm:A"}
                        minuteStep={selectedAmenity?.minBookingTimeMinute}
                        onChange={(val: any) => {
                          if (val) {
                            setBookingStartDate(val);
                          }
                        }} />
                      <br />
                      <br />
                      <TimePicker value={bookingEndDate}
                        clearIcon={null}
                        format={"hh:mm:A"}
                        className={"time-picker"}
                        minuteStep={selectedAmenity?.minBookingTimeMinute}
                        onChange={(val: any) => {
                          if (val) {
                            setBookingEndDate(val);
                          }
                        }} />
                    </div>
                  </div>

                </div>
                <div className="total-value-field form-group">
                  <label>
                    <small>Total Invoice Value</small>
                  </label>
                  <br />
                  <span style={{ fontSize: 30, color: "#4bbb46", fontWeight: "bold", }}>
                    <span style={{ fontWeight: "normal" }}>
                      LKR
                    </span>{" "}
                    {formatMoney(totalCost)}
                  </span>
                </div>

                <div className="form-group">
                  <AppButton disable={!canBook} className="btn btn-primary" onClick={() => {
                    console.log(bookingStartDate, "---- Booking Start Date");
                    console.log(bookingEndDate, "---- Booking End Date");
                    createBooking();
                  }}>Create
                    booking</AppButton>
                </div>
              </div>
            }
          </div>
        </div>
      </Drawer>


      <Filter children={[
        {
          label: "Facility",
          type: <select style={{ flex: "2", margin: "0rem 0.8rem" }} className="form-control"
            onChange={(e) => {
              setAmenity(e.target.value);
            }} value={amenity}>
            <option value={"All"}>All</option>
            {amenitiesList?.map((amenity: any, index) => {
              return (
                <option value={amenity} key={index}>
                  {amenity}
                </option>
              );
            })}
          </select>
        }
      ]} isOpen={showFilter} wrapperClass={"filter-area-booking"} showClear={true} onFilter={() => {
        filter()
      }} onClear={() => {
        clear()
      }} />
    </div>
  );
}
export default  Bookings;