import "./Dot.scss";
import CSS from "csstype";

import React from "react";

interface DotProps {
    color: "green" | "yellow" | "red" | "blue" | "orange" | "pink" | "purple";
    style?: CSS.Properties;
}

export default function Dot({ color, style }: DotProps) {
    const dotColor =
        color == "green"
            ? "dot green"
            : color == "yellow"
            ? "dot yellow"
            : color == "red"
            ? "dot red"
            : color == "blue"
            ? "dot blue"
            : color == "orange"
            ? "dot orange"
            : color == "purple"
            ? "dot purple"
            : "dot pink";
    return <span className={dotColor} style={style}></span>;
}
