import moment from "moment";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import AppButton from "../../components/AppButton/AppButton";
import CreateInvoice from "../../components/CreateInvoice/CreateInvoice";
import Navbar from "../../components/Navbar/Navbar";
import ProfileImage from "../../components/ProfileImage/ProfileImage";
import DrawerHeader from "../../components/Drawer/DrawerHeader/SideDrawerHeader";
import Drawer from "../../components/Drawer/Drawer";
import ApiService from "../../services/ApiService";
import { formatMoney, handleErrors, showNotification, splitWord, } from "../../services/HelperMethods";
import DrawerFooter from "../../components/Drawer/DrawerFooter";
import RightArrow from "../../assets/images/icons/right-arrow.svg";
import AppLoading from "../../components/AppLoading/AppLoading";

import "./Payment.scss";
import PaymentFileInput from "../../components/FileInput/PaymentFileInput";
import FirebaseServices from "../../services/FirebaseServices";

export default function () {

  const apiService = new ApiService();
  const firebaseServices = new FirebaseServices();

  const history = useHistory();

  const { id } = useParams() as any;

  const [payments, setPayments] = useState([] as any[]);
  const [searchedPayments, setSearchedPayments] = useState([] as any[]);
  const [showCreateInvoice, setShowCreateInvoice] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null as any);
  const [paymentMethod, setPaymentMethod] = useState(2);
  const [paymentRefNumber, setPaymentRefNumber] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("");
  const [paymentRemarks, setPaymentRemarks] = useState("");
  const [paymentImage, setPaymentImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [apartmentUserId, setApartmentUserId] = useState<number>();
  const createInvoiceRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (createInvoiceRef.current && !createInvoiceRef.current.contains(event.target)) {
      setShowCreateInvoice(false);
    }
  };
  useEffect(() => {
    getTransactions();
  }, []);

  useEffect(() => {
    let selected = payments.find((p) => p.id == id);

    if (selected) {
      setSelectedPayment(selected);
    }

    console.log(selected, "selectedselected");


  }, [id, payments]);

  async function getTransactions() {

    setIsLoading(true);

    try {
      let result = await apiService.getTransactions();
      var invoices = result.data.invoices;

      setPayments(invoices);
      setSearchedPayments(invoices)
    } catch (error) {
      handleErrors(error);
    }

    setIsLoading(false);
  }

  function pay() {

    setIsLoading(true);

    if (selectedPayment != null) {

      let data = {
        invoiceId: selectedPayment.id,
        userId: selectedPayment.createdFor.id,
        paymentMethod: paymentMethod,
        paymentRefNumber: paymentRefNumber,
        paymentRemarks: paymentRemarks,
        paymentAmount: parseFloat(paymentAmount),
        paymentImage: paymentImage
      }

      apiService.payOverTheCounter(data).then((result) => {
        if (result.data?.isSuccess) {
          showNotification("Payment", "Payment Successful", "success")

          setPaymentMethod(2);
          setPaymentRefNumber("");
          setPaymentAmount("0");
          setPaymentRemarks("");
          setPaymentImage("");

          getTransactions()
        } else {
          showNotification("Payment", "Payment Failed", "error")
        }
      }).catch(() => {
        showNotification("Payment", "Payment Failed", "error")
      });
    }

    setIsLoading(false);
  }

  function setPaidAmount(amount, maxPayable) {
    if (isNaN(amount)) {
      setPaymentAmount("0")
    } else if (maxPayable >= amount) {
      setPaymentAmount(amount)
    } else {
      setPaymentAmount("0")
      showNotification("Total Payable LKR " + formatMoney(maxPayable), "Total Payable LKR " + formatMoney(maxPayable), "error");
    }
  }

  function openPrint() {
    window.open(window.location.origin + "/#/start/print/" + selectedPayment?.refNumber)
  }

  function handleFilter(array: any[], searchedWord: string) {
    let searchedArray = [] as any[];
    array.forEach((payment) => {
      if (payment?.refNumber.toString().toLowerCase().includes(searchedWord.toLowerCase())
        || moment.utc(payment?.issuedDate).local().format("MMMM DD, YYYY").toString().toLowerCase().includes(searchedWord.toLowerCase())
        || splitWord(payment?.invoiceType).toLowerCase().includes(searchedWord.toLowerCase())
        || payment?.createdFor?.name.toLowerCase().includes(searchedWord.toLowerCase())) {
        searchedArray.push(payment);
      }
    });
    return searchedArray;
  }

  function handleSearch(searchedWord: string) {
    setIsLoading(true);
    if (searchedWord.trim() === "") {
      setSearchedPayments(payments);
      setIsLoading(false);
      return;
    }

    let searchedArray = handleFilter(payments, searchedWord);
    setSearchedPayments(searchedArray);
    setIsLoading(false);
  }

  async function handleUserBuildingId(){
    try {
      let result = await apiService.geApartmenBuilidingUserId({
        apartmentUserId: selectedPayment?.createdBy?.id
      });
      setApartmentUserId(result.data.apartmentUserId)
    } catch (error) {
      handleErrors(error);
    }
  }

  function handleLink(){
    handleUserBuildingId();
    return "/users/"+apartmentUserId;
  }

   // Function to extract desired attributes from a payment object
  const extractAttributes = (payment) => {
    const extractedInvoice = {
        "RefNumber": payment?.refNumber,
        "Total": "LKR " + formatMoney(payment?.total),
        "Service Charge Percentage": payment?.serviceChargePercentage + "%",
        "Vat Percentage": payment?.vatPercentage + "%",
        "Created Date": payment?.createdDate?.split('T')[0],
        "Issued Date": payment?.issuedDate?.split('T')[0],
        "Due Date": payment?.dueDate?.split('T')[0],
        "Settled Date": payment?.settledDate?.split('T')[0],
        "Created For": payment?.createdFor?.name,
        "Created By": payment?.createdBy?.name,
        "Amount Receivable": payment?.amountPayable > 0 ? "LKR " + formatMoney(payment?.amountPayable) : "Paid",
        "Refundable Deposit": "LKR " + formatMoney(payment?.refundableDeposit),
        "LateFee Amount": "LKR " + formatMoney(payment?.lateFeeAmount),
        "End Time": payment?.endTime?.split('T')[0],
        "Invoice Type": payment?.invoiceType,
    };
    return extractedInvoice;
  };

  // Create a new list with objects containing only the desired attributes
  const modifiedInvoiceList = searchedPayments.map(user => extractAttributes(user));

  return (
    <div className="body payment-page">
      <AppLoading isLoading={isLoading} />
      <h2>Accounting</h2>

      <Navbar showSearch hideFilter={true} hideSettings={true} onSettingsButtonClick={() => { history.push("/settings/payment"); }}
        onAddButtonClick={() => { setShowCreateInvoice(true); }} onSearchChange={handleSearch}
        downloadExcel={true}
        fileName="Invoices"
        excelData={modifiedInvoiceList}
        >

        <Link className="active" to="/payment">Invoices</Link>
        {/* <Link to="/payment/settlements">Settlements</Link> */}
        <Link to="/payment/bills">Expenses</Link>
      </Navbar>

      <Table responsive="lg" borderless={true} className="invoices-table">
        <thead>
          <tr>
            <th>Ref No</th>
            <th>Issued Date</th>
            <th>Type</th>
            <th>Amount</th>
            <th>Amount Receivable</th>
            <th>Payee</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody className="wrapper-payments-table">
          {searchedPayments?.length
            ? (searchedPayments.map((payment, i) => {
              return (
                <tr key={i} onClick={() => { history.push("/payment/" + payment.id); }} className="cursor-pointer">
                  <td className="td-ref-no">{payment?.refNumber}</td>
                  <td>
                    {moment.utc(payment?.issuedDate).local().format("MMMM DD, YYYY")}
                  </td>
                  <td>
                    {splitWord(payment?.invoiceType)}
                  </td>
                  <td>LKR {formatMoney(payment?.total)}</td>
                  <td>{payment?.amountPayable > 0
                    ? ("LKR " + formatMoney(payment?.amountPayable))
                    : ("Paid")
                  }</td>
                  <td className="td-action-by">
                    <ProfileImage style={{ width: 30, height: 30 }} src={payment?.createdFor?.profilePicturePath} />
                    <p>
                      {payment?.createdFor?.name}
                    </p>
                  </td>
                  <td className="td-status">
                    {payment?.invoiceStatus == 0
                      ? (<div><i className="fas fa-minus-circle icon-pending"></i></div>)
                      : (<i className="fas fa-check-circle icon-done"></i>)
                    }
                  </td>
                </tr>
              );
            }))
            : (<Fragment />)}
        </tbody>
      </Table>

      <Drawer className="payment-info-drawer" isOpen={selectedPayment != null}>
        <DrawerHeader profileImage={false} onClose={() => { history.push("/payment"); setSelectedPayment(null); }}>
          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
            <div className="icon-cir" style={{
              backgroundColor: "#E7F9E8", borderRadius: "5rem",
              width: "7rem", height: "7rem", display: "flex", justifyContent: "center", alignItems: "center"
            }}>
              <img className="icon-cir-img" style={{ width: "5rem", height: "5rem" }} src={RightArrow}></img>
            </div>
            <div style={{ color: "#707076", margin: "1rem 0rem 0.5rem 0rem" }}>#{selectedPayment?.refNumber}</div>
            <div>{moment.utc(selectedPayment?.issuedDate).local().format("LL")}</div>
          </div>
        </DrawerHeader>
        <div style={{ overflow: "auto", flex: 1 }}>
          <div className="amount">
            LKR {formatMoney(selectedPayment?.total)}
          </div>
          <div style={{}} className="ii-inner">
            <div className="payment-info mt-3">
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="label-heading">Invoice Summary</div>
                  </div>
                </div>
                <div>
                  {selectedPayment?.invoiceKind == "AmenityBooking"
                    ? (<div>
                      {selectedPayment?.invoiceItems?.length > 0
                        ? (selectedPayment?.invoiceItems?.map((item, index) => {
                          return (
                            <>
                              {item?.description != "Service Charge" && item?.description != "VAT/NBT"
                                ? (<div key={index} className="ii-top">
                                  <div className="ii-lft">
                                    {item?.description}
                                  </div>
                                  <div className="ii-rht">
                                    LKR {formatMoney(item?.amount)}
                                  </div>
                                </div>)
                                : (<Fragment />)
                              }
                            </>
                          )
                        }))
                        : (<Fragment />)
                      }
                      {selectedPayment?.invoiceItems?.length > 0
                        ? (selectedPayment?.invoiceItems?.map((item, index) => {
                          return (
                            <>
                              {item?.description == "Service Charge" || item?.description == "VAT/NBT"
                                ? (<div key={index} className="ii-top">
                                  <div className="ii-lft">
                                    {item?.description + " " + item?.percentage + "%"}
                                  </div>
                                  <div className="ii-rht">
                                    LKR {formatMoney(item?.amount)}
                                  </div>
                                </div>)
                                : (<Fragment />)
                              }
                            </>
                          )
                        }))
                        : (<Fragment />)
                      }
                      {selectedPayment?.refundableDeposit > 0
                        ? (<>
                          <div style={{ borderBottom: "0.1rem solid #4d4d4d", marginBottom: "0.3rem" }} />
                          <div className="ii-top">
                            <div style={{ fontWeight: "bold" }} className="ii-lft">Sub Total</div>
                            <div className="ii-rht">
                              LKR {formatMoney(selectedPayment?.subTotal)}
                            </div>
                          </div>
                          <div className="ii-top">
                            <div className="ii-lft">Refundable Deposit</div>
                            <div className="ii-rht">
                              LKR {formatMoney(selectedPayment?.refundableDeposit)}
                            </div>
                          </div>
                        </>)
                        : (<Fragment />)
                      }
                      <div style={{ borderBottom: "0.1rem solid #4d4d4d", marginBottom: "0.3rem" }} />
                      <div className="ii-top">
                        <div style={{ fontWeight: "bold" }} className="ii-lft">Total</div>
                        <div className="ii-rht">
                          LKR {formatMoney(selectedPayment?.total)}
                        </div>
                      </div>
                    </div>)
                    : (selectedPayment?.invoiceKind == "CustomInvoice"
                      ? (<div>
                        {selectedPayment?.invoiceItems?.length > 0
                          ? (selectedPayment?.invoiceItems?.map((item, index) => {
                            return (
                              <>
                                {item?.description != "Service Charge" && item?.description != "VAT/NBT"
                                  ? (<div key={index} className="ii-top">
                                    <div className="ii-lft">
                                      {item?.description}
                                    </div>
                                    <div className="ii-rht">
                                      LKR {formatMoney(item?.amount)}
                                    </div>
                                  </div>)
                                  : (<Fragment />)
                                }
                              </>
                            )
                          }))
                          : (<Fragment />)
                        }
                        {selectedPayment?.invoiceItems?.length > 0
                          ? (selectedPayment?.invoiceItems?.map((item, index) => {
                            return (
                              <>
                                {item?.description == "Service Charge" || item?.description == "VAT/NBT"
                                  ? (<div key={index} className="ii-top">
                                    <div className="ii-lft">
                                      {item?.description + " " + item?.percentage + "%"}
                                    </div>
                                    <div className="ii-rht">
                                      LKR {formatMoney(item?.amount)}
                                    </div>
                                  </div>)
                                  : (<Fragment />)
                                }
                              </>
                            )
                          }))
                          : (<Fragment />)
                        }
                        <div style={{ borderBottom: "0.1rem solid #4d4d4d", marginBottom: "0.3rem" }} />
                        <div className="ii-top">
                          <div style={{ fontWeight: "bold" }} className="ii-lft">Total</div>
                          <div className="ii-rht">
                            LKR {formatMoney(selectedPayment?.total)}
                          </div>
                        </div>
                      </div>)
                      : (<div>
                        {selectedPayment?.invoiceItems?.length > 0
                          ? (selectedPayment?.invoiceItems?.map((item, index) => {
                            return (
                              <>
                                {item?.description != "Service Charge" && item?.description != "VAT/NBT"
                                  ? (<div key={index} className="ii-top">
                                    <div className="ii-lft">
                                      {item?.description}
                                    </div>
                                    <div className="ii-rht">
                                      LKR {formatMoney(item?.amount)}
                                    </div>
                                  </div>)
                                  : (<Fragment />)
                                }
                              </>
                            )
                          }))
                          : (<Fragment />)
                        }
                        {selectedPayment?.invoiceItems?.length > 0
                          ? (selectedPayment?.invoiceItems?.map((item, index) => {
                            return (
                              <>
                                {item?.description == "Service Charge" || item?.description == "VAT/NBT"
                                  ? (<div key={index} className="ii-top">
                                    <div className="ii-lft">
                                      {item?.description}
                                    </div>
                                    <div className="ii-rht">
                                      LKR {formatMoney(item?.amount)}
                                    </div>
                                  </div>)
                                  : (<Fragment />)
                                }
                              </>
                            )
                          }))
                          : (<Fragment />)
                        }
                        <div style={{ borderBottom: "0.1rem solid #4d4d4d", marginBottom: "0.3rem" }} />
                        <div className="ii-top">
                          <div style={{ fontWeight: "bold" }} className="ii-lft">Total</div>
                          <div className="ii-rht">
                            LKR {formatMoney(selectedPayment?.total)}
                          </div>
                        </div>
                      </div>)
                    )
                  }
                </div>
              </div>

              <div style={{ margin: "2rem 0rem" }}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="label-heading">Payee</div>
                  </div>
                </div>

                <div style={{ display: "flex", alignItems: "center", justifyContent: "stretch", }}>
                  <ProfileImage style={{ width: 56, height: 56, marginTop: 10, marginRight: 10, }}
                    src={selectedPayment?.createdFor?.profilePicturePath} />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ fontSize: "14px", fontWeight: 600 }}>
                      {selectedPayment?.createdFor?.name}
                    </div>
                    <div style={{ fontSize: "13px", color: "#9a9a9a", fontWeight: 500 }}>
                      Door {selectedPayment?.createdFor?.doorNumber}{" "}| Floor{" "}
                      {selectedPayment?.createdFor?.floor}
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ marginBottom: "2rem" }}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="label-heading">Invoice Info</div>
                  </div>
                </div>

                <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
                  <div>
                    <div className="ii-top">
                      <div className="ii-lft">Created</div>
                      <div className="ii-rht">
                        {moment.utc(selectedPayment?.createdDate).local().format("LL")}
                      </div>
                    </div>
                    <div className="ii-top">
                      <div className="ii-lft">Issued</div>
                      <div className="ii-rht">
                        {moment.utc(selectedPayment?.issuedDate).local().format("LL")}
                      </div>
                    </div>
                    <div className="ii-top">
                      <div className="ii-lft">Due</div>
                      <div className="ii-rht">
                        {moment.utc(selectedPayment?.dueDate).local().format("LL")}
                      </div>
                    </div>
                    {selectedPayment?.invoiceStatus == 1
                      ? (<div className="ii-top">
                        <div className="ii-lft">Settled</div>
                        <div className="ii-rht">
                          {moment.utc(selectedPayment?.settledDate).local().format("LL")}
                        </div>
                      </div>)
                      : (<Fragment />)
                    }
                    <div className="ii-top">
                      <div className="ii-lft">Actioned By</div>
                      <div className="ii-rht">
                        <Link to={() => handleLink()}>
                          {selectedPayment?.createdBy?.name}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ marginBottom: "2rem" }}>
                {selectedPayment?.payments?.length > 0
                  ? (<div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="label-heading">Payment Summary</div>
                      </div>
                    </div>

                    {selectedPayment?.payments?.map((payment, index) => {
                      return (
                        <div key={index} style={{ display: "flex", flex: 1, flexDirection: "column", marginBottom: "0.6rem" }}>
                          {payment?.acceptedBy != null
                            ? (<div className="ii-top">
                              <div className="ii-lft">Paid To</div>
                              <div className="ii-rht">
                                <Link to={"/users/" + payment?.acceptedBy?.id}>
                                  {payment?.acceptedBy?.name}
                                </Link>
                              </div>
                            </div>)
                            : (<Fragment />)
                          }
                          {(payment?.refNumber != "" && payment?.refNumber != null)
                            ? (<div className="ii-top">
                              <div className="ii-lft">Ref Number</div>
                              <div className="ii-rht">
                                {payment?.refNumber}
                              </div>
                            </div>)
                            : (<Fragment />)
                          }
                          <div className="ii-top">
                            <div className="ii-lft">Payment Date</div>
                            <div className="ii-rht">
                              {moment.utc(payment?.paidDate).local().format("LL")}
                            </div>
                          </div>
                          {payment?.isMDRAdded
                            ? (<div className="ii-top">
                              <div className="ii-lft">{"Convenience Fee " + payment?.mdr + "%"}</div>
                              <div className="ii-rht">
                                LKR {formatMoney(payment?.mdrAmount)}
                              </div>
                            </div>)
                            : (<Fragment />)
                          }
                          <div className="ii-top">
                            <div className="ii-lft">Amount</div>
                            <div className="ii-rht">
                              LKR {formatMoney(payment?.amount)}
                            </div>
                          </div>
                          <div className="ii-top">
                            <div className="ii-lft">Paid Via</div>
                            <div className="ii-rht">
                              {payment?.paymentMethod == "Card"
                                ? ("LIWE App")
                                : (payment?.paymentMethod == "POS"
                                  ? ("Card")
                                  : (splitWord(payment?.paymentMethod))
                                )
                              }
                            </div>
                          </div>
                          {(payment?.remarks != "" && payment?.remarks != null)
                            ? (<div className="ii-top">
                              <div className="ii-lft">Remarks</div>
                              <div className="ii-rht">
                                {payment?.remarks}
                              </div>
                            </div>)
                            : (<Fragment />)
                          }
                          <div className="ii-top">
                            <div className="ii-lft">Status</div>
                            <div className="ii-rht">
                              {splitWord(payment?.paymentStatus)}
                            </div>
                          </div>
                          {payment?.imageURL
                            ? (<div className="ii-top">
                              <div className="ii-lft">Attachments</div>
                              <div style={{ color: "#8acb87", fontWeight: 500, cursor: "pointer" }} className="ii-rht"
                                onClick={() => { window.open(payment?.imageURL) }}>
                                {"Click Here"}
                              </div>
                            </div>)
                            : (<Fragment />)
                          }
                        </div>
                      )
                    })}
                  </div>)
                  : (<Fragment />)
                }
              </div>

              <div>
                {selectedPayment?.invoiceStatus == 0
                  ? (<div style={{ margin: "0.1rem" }} className="row make-payment">
                    <div style={{ marginBottom: "0.2rem" }} className="row">
                      <div className="col payee-lbl">Accept Payment</div>
                    </div>
                    <div className="row">
                      <div style={{ marginBottom: "0.5rem" }} className="col-12">
                        <div className="lbl">Method</div>
                        <div>
                          <select className="form-control" onChange={(e) => { setPaymentMethod(parseInt(e.target.value)) }}>
                            {/* <option value="1">Card</option> */}
                            <option value="2">Cash</option>
                            <option value="3">Bank Transfer</option>
                            <option value="4">Cheque</option>
                          </select>
                        </div>
                      </div>
                      <div style={{ marginBottom: "0.5rem" }} className="col-6">
                        <div className="lbl">Ref Number</div>
                        <div>
                          <input onChange={(e) => { setPaymentRefNumber(e.target.value) }} value={paymentRefNumber}
                            className="form-control" type="text" />
                        </div>
                      </div>
                      <div style={{ marginBottom: "0.5rem" }} className="col-6">
                        <div className="lbl">Amount</div>
                        <div>
                          <input onChange={(e) => { setPaidAmount(e.target.value, selectedPayment?.amountPayable) }} value={(paymentAmount)}
                            className="form-control" type="number" />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="lbl">Remarks</div>
                        <div>
                          <textarea rows={5} className="form-control" onChange={(e) => { setPaymentRemarks(e.target.value); }}
                            value={paymentRemarks} maxLength={250} />
                        </div>
                      </div>
                      <div style={{ marginTop: "0rem" }} className="wrapper-bottom-set">
                        {paymentImage != ""
                          ? (<div style={{ marginBottom: "0.5rem", cursor: "pointer" }} className="other-image-wrap col-12">
                            <button onClick={() => { setPaymentImage(""); }} className="remove-button">
                              <i className="fas fa-times" />
                            </button>
                            <img style={{ cursor: "pointer" }} onClick={() => { window.open(paymentImage) }} src={paymentImage} />
                          </div>)
                          : (<Fragment />)
                        }
                        {paymentImage == ""
                          ? (<div style={{ marginBottom: "0.5rem" }} className="col-12">
                            <PaymentFileInput onChange={async (url) => {

                              var docURL = await firebaseServices.uploadDoc(url)

                              if (docURL) {
                                setPaymentImage(docURL)
                              }
                            }} />
                            <span className="other-image-upload-icon">
                              <i className="fas fa-plus" />
                            </span>
                          </div>)
                          : (<Fragment />)
                        }
                      </div>
                    </div>
                    <div style={{ margin: "0.1rem" }} className="other-image-add row">
                      <div onClick={(() => { pay(); })} style={{ margin: "0.8rem 0rem" }} className="sidebar-footer">
                        <AppButton>Accept Payment</AppButton>
                      </div>
                    </div>
                  </div>)
                  : (<Fragment />)}
              </div>
            </div>
          </div>
        </div>
        <DrawerFooter>
          <div style={{ margin: "0.8rem 0rem" }} className="sidebar-footer">
            <AppButton onClick={() => { openPrint() }}>Print Invoice</AppButton>
          </div>
        </DrawerFooter>
      </Drawer>

<Fragment>
{showCreateInvoice && (
          <div ref={createInvoiceRef} className="create-invoice-modal">
           <CreateInvoice isShow={showCreateInvoice} onClose={() => { setShowCreateInvoice(false); }}
        onCreateInvoice={() => { getTransactions(); setShowCreateInvoice(false); }} />
          </div>
        )}

</Fragment>
    </div>
  );
}
