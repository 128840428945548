import React, { Fragment, useEffect, useState } from "react";
import AppButton from "../../components/AppButton/AppButton";
import AppLoading from "../../components/AppLoading/AppLoading";
import PaymentFileInput from "../../components/FileInput/PaymentFileInput";
import ApiService from "../../services/ApiService";
import FirebaseServices from "../../services/FirebaseServices";
import { showNotification } from "../../services/HelperMethods";

import "./Marketplace.scss";

export default function MarketplaceAdmin() {

    const apiService = new ApiService();
    const firebaseServices = new FirebaseServices();

    const [password, setPassword] = useState("");
    const [showForm, setShowForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    //Condominium Form
    const [logo, setLogo] = useState("");
    const [banner, setBanner] = useState("");
    const [additionalImages, setAdditionalImages] = useState([] as any);
    const [name, setName] = useState("");
    const [aboutUs, setAboutUs] = useState("");
    const [phoneNumber, setPhoneNumber] = useState(0);
    const [whatsappNumber, setWhatsappNumber] = useState(0);
    const [email, setEmail] = useState("");
    const [website, setWebsite] = useState("");
    const [categories, setCategories] = useState([] as any);

    useEffect(() => {
        if (password.trim() == "BANANA") {
            setShowForm(true);
        }
    }, [password])

    async function add() {

        let data = {
            logo: logo,
            banner: banner,
            name: name,
            aboutUs: aboutUs,
            phoneNumber: phoneNumber,
            whatsappPhoneNumber: whatsappNumber,
            email: email,
            website: website,
            categories: categories,
            additionalImages: additionalImages
        }

        await apiService.addMerchant(data).then((result) => {
            console.log(result, "marketplaceResult");

        })
    }

    function checkOnChange(value) {
        var categoryValue = value;
        var tempCategories = [...categories]

        if (tempCategories.includes(categoryValue)) {
            tempCategories = tempCategories.filter((category) => { return category != categoryValue })
        } else {
            tempCategories.push(categoryValue)
        }

        setCategories(tempCategories)
    }

    return (
        <>
            <AppLoading isLoading={isLoading} />
            {showForm
                ? (<div style={{
                    display: "flex", flexDirection: "column", minWidth: "100vw", minHeight: "100vh",
                    padding: "4rem 0rem"
                }}>
                    {addMerchant()}
                </div>)
                : (<div style={{
                    display: "flex", flexDirection: "column", alignItems: "center",
                    justifyContent: "center", minWidth: "100vw", minHeight: "100vh"
                }}>
                    <div>
                        <input className="form-control" style={{ borderRadius: "0.5rem" }} type="text"
                            onChange={(e) => { setPassword(e.target.value); }} placeholder="Enter Password" />
                    </div>
                </div>)
            }
        </>
    )

    function addMerchant() {
        return (
            <div style={{ marginBottom: "2rem" }}>
                <h1 style={{ textAlign: "center" }}>Add Merchant</h1>
                <div style={{ display: "flex", justifyContent: "space-evenly", marginBottom: "1rem" }}>
                    <div style={{ marginTop: "0rem" }} className="wrapper-bottom-set-mtp">
                        <div style={{
                            marginBottom: "0.5rem", cursor: "pointer", display: "flex",
                            justifyContent: "center", flexDirection: "column", alignItems: "center"
                        }}
                            className="other-image-wrap-mtp">
                            {logo != ""
                                ? (<>
                                    <label>Logo</label>
                                    <img style={{ cursor: "pointer" }} onClick={() => { window.open(logo) }} src={logo} />
                                    <button onClick={() => { setLogo(""); }} className="remove-button-mtp">
                                        <i className="fas fa-times" />
                                    </button>
                                </>)
                                : (<Fragment />)
                            }
                        </div>
                        <div style={{
                            marginBottom: "0.5rem", cursor: "pointer", display: "flex",
                            justifyContent: "center", flexDirection: "column", alignItems: "center"
                        }}
                            className="other-image-wrap-mtp">
                            {banner != ""
                                ? (<>
                                    <label>Banner</label>
                                    <img style={{ cursor: "pointer" }} onClick={() => { window.open(banner) }} src={banner} />
                                    <button onClick={() => { setBanner(""); }} className="remove-button-mtp">
                                        <i className="fas fa-times" />
                                    </button>
                                </>)
                                : (<Fragment />)
                            }
                        </div>
                        <div style={{
                            marginBottom: "0.5rem", display: "flex", justifyContent: "center",
                            flexDirection: "column", alignItems: "center"
                        }} className="col-12">
                            {logo == ""
                                ? (<>
                                    <label>Logo</label>
                                    <PaymentFileInput onChange={async (url) => {

                                        var docURL = await firebaseServices.uploadDoc(url)

                                        if (docURL) {
                                            setLogo(docURL)
                                        }
                                    }} />
                                    <span className="other-image-upload-icon-mtp">
                                        <i className="fas fa-plus" />
                                    </span>
                                </>)
                                : (<Fragment />)
                            }
                        </div>
                        <div style={{
                            marginBottom: "0.5rem", display: "flex", justifyContent: "center",
                            flexDirection: "column", alignItems: "center"
                        }} className="col-12">
                            {banner == ""
                                ? (<>
                                    <label>Banner</label>
                                    <PaymentFileInput onChange={async (url) => {

                                        var docURL = await firebaseServices.uploadDoc(url)

                                        if (docURL) {
                                            setBanner(docURL)
                                        }
                                    }} />
                                    <span className="other-image-upload-icon-mtp">
                                        <i className="fas fa-plus" />
                                    </span>
                                </>)
                                : (<Fragment />)
                            }
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-evenly", marginBottom: "1rem" }}>
                    <input className="form-control" type="text" style={{ maxWidth: "14rem" }} value={name}
                        onChange={(e) => { setName(e.target.value); }} placeholder="Name" />

                    <input className="form-control" type="text" style={{ maxWidth: "14rem" }} maxLength={9}
                        onChange={(e) => { setPhoneNumber(parseInt(e.target.value)); }} placeholder="Phone Number" />

                    <input className="form-control" type="text" style={{ maxWidth: "14rem" }} maxLength={9}
                        onChange={(e) => { setWhatsappNumber(parseInt(e.target.value)); }} placeholder="Whatsapp Number" />

                    <input className="form-control" type="text" style={{ maxWidth: "14rem" }} value={email}
                        onChange={(e) => { setEmail(e.target.value); }} placeholder="Email" />

                    <input className="form-control" type="text" style={{ maxWidth: "14rem" }} value={website}
                        onChange={(e) => { setWebsite(e.target.value); }} placeholder="Website" />
                </div>
                <div style={{ display: "flex", justifyContent: "space-evenly", marginBottom: "1rem" }}>
                    <textarea className="form-control" rows={4} cols={4} maxLength={100} value={aboutUs} placeholder={"About Us"}
                        onChange={(e) => { setAboutUs(e.target.value) }} style={{ maxWidth: "25rem" }} />
                </div>
                <div style={{ display: "flex", justifyContent: "space-evenly", marginBottom: "1rem" }}>
                    <div>
                        <input type="checkbox" value="0" onChange={(e) => { checkOnChange(e.target.value) }} />
                        <label>Food &amp; Beverages</label>
                    </div>
                    <div>
                        <input type="checkbox" value="1" onChange={(e) => { checkOnChange(e.target.value) }} />
                        <label>Groceries</label>
                    </div>
                    <div>
                        <input type="checkbox" value="2" onChange={(e) => { checkOnChange(e.target.value) }} />
                        <label>Electronics</label>
                    </div>
                    <div>
                        <input type="checkbox" value="3" onChange={(e) => { checkOnChange(e.target.value) }} />
                        <label>Flowers &amp; Cakes</label>
                    </div>
                    <div>
                        <input type="checkbox" value="4" onChange={(e) => { checkOnChange(e.target.value) }} />
                        <label>Smokers Lounge</label>
                    </div>
                    <div>
                        <input type="checkbox" value="5" onChange={(e) => { checkOnChange(e.target.value) }} />
                        <label>Fashion &amp; Beauty</label>
                    </div>
                    <div>
                        <input type="checkbox" value="6" onChange={(e) => { checkOnChange(e.target.value) }} />
                        <label>Homeware</label>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-evenly", marginBottom: "1rem" }}>
                    <div>
                        <input type="checkbox" value="7" onChange={(e) => { checkOnChange(e.target.value) }} />
                        <label>Toys &amp; Baby Care</label>
                    </div>
                    <div>
                        <input type="checkbox" value="8" onChange={(e) => { checkOnChange(e.target.value) }} />
                        <label>Health &amp; Wellness</label>
                    </div>
                    <div>
                        <input type="checkbox" value="9" onChange={(e) => { checkOnChange(e.target.value) }} />
                        <label>Stationery &amp; Party Supplies</label>
                    </div>
                    <div>
                        <input type="checkbox" value="10" onChange={(e) => { checkOnChange(e.target.value) }} />
                        <label>Pets</label>
                    </div>
                    <div>
                        <input type="checkbox" value="11" onChange={(e) => { checkOnChange(e.target.value) }} />
                        <label>Automobile</label>
                    </div>
                    <div>
                        <input type="checkbox" value="12" onChange={(e) => { checkOnChange(e.target.value) }} />
                        <label>Hospitality</label>
                    </div>
                    <div>
                        <input type="checkbox" value="13" onChange={(e) => { checkOnChange(e.target.value) }} />
                        <label>Other Services</label>
                    </div>
                    <div>
                        <input type="checkbox" value="14" onChange={(e) => { checkOnChange(e.target.value) }} />
                        <label>Experiences</label>
                    </div>
                </div>
                <label style={{ display: "flex", justifyContent: "center" }}>Additional Images</label>
                <div style={{ display: "flex", justifyContent: "space-evenly", marginBottom: "1rem" }}
                    className="wrapper-bottom-set-mtp">
                    <div style={{ marginBottom: "0.5rem", cursor: "pointer" }} className="other-image-wrap-mtp">
                        {additionalImages.length > 0
                            ? (additionalImages?.map((image, index) => {
                                return (<>
                                    <img key={index} style={{ cursor: "pointer" }} onClick={() => { window.open(image) }} src={image} />
                                    <button key={index} className="remove-button-mtp"
                                        onClick={() => {

                                            var tempImages = [...additionalImages]

                                            var without = tempImages.filter((_tempImg, tempIndex) => {
                                                return index != tempIndex
                                            })

                                            setAdditionalImages(without);
                                        }} >
                                        <i className="fas fa-times" />
                                    </button>
                                </>)
                            }))
                            : (<Fragment />)
                        }
                    </div>
                    <div style={{ marginBottom: "0.5rem", display: "flex", justifyContent: "space-evenly" }} className="col-12">
                        <PaymentFileInput onChange={async (url) => {

                            var docURL = await firebaseServices.uploadDoc(url)

                            if (docURL) {
                                var tempImages = [...additionalImages]
                                tempImages.push(docURL);
                                setAdditionalImages(tempImages)
                            }
                        }} />
                        <span className="other-image-upload-icon-mtp">
                            <i className="fas fa-plus" />
                        </span>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <AppButton style={{ maxWidth: "14rem" }} onClick={() => { add() }}>Add Merchant</AppButton>
                </div>
            </div>
        )
    }
}