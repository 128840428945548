import { useState } from "react";
import Select from "react-select";

export default function AppSearch(props: {
  placeholder;
  options: { value: string; label: string }[];
  onChange?;
  disabled?;
  value?;
}) {
  const [value, setValue] = useState("");

  return (
    <Select
      isDisabled={props.disabled}
      onChange={(e) => props.onChange(e)}
      onInputChange={(e) => setValue(e)}
      // options={value?.length ? props.options : []}
      options={props.options}
      value={props.value}
      placeholder={props.placeholder}
      isClearable
      isSearchable
    />
  );
}
