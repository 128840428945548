import axios from "axios";
import { getDeviceCode, getToken, saveToken } from "./HelperMethods";
export default class ApiService {
  async archiveNotice(data: { noticeId: any }) {
    const ax = await this.ax();
    return await ax.post("/v1/api/noticeboard/archive", data);
  }

  async getPublicNotices() {
    const ax = await this.ax();
    return await ax.get("/v1/api/noticeboard/");
  }

  async createNotice(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/noticeboard/", data);
  }

  async resetPassword(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/auth/password/reset", data);
  }

  async resetPasswordRequest(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/auth/password/reset/request", data);
  }

  async getBuildingDetails() {
    const ax = await this.ax();
    return await ax.get("/v1/api/building");
  }

  async signUpBuilding(data){
    const ax = await this.ax();
    return await ax.post("/v1/api/selfSignup/sign-up", data)
  }

  async GetAllUnRegBuilding(){
    const ax = await this.ax();
    return await ax.get("/v1/api/selfSignup/get-all-Unregistered")
  }

  async GetAllRequestBuilding(){
    const ax = await this.ax();
    return await ax.get("/v1/api/building/get-all-request")
  }

  async ChangeBuildingStatus(data){
    const ax = await this.ax();
    return await ax.post("/v1/api/building/change-status",data)
  }

  async createPaymentInvoice(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/building/invoice/create", data);
  }

  async getTransactions() {
    const ax = await this.ax();
    return await ax.get("/v1/api/building/transactions");
  }

  async getSecurityLog(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/building/getLogs", data);
  }

  async changeUserStatus(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/building/user/status", data);
  }

  async getPaymentGraph(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/dashboard/payment/graph/", data);
  }

  async getDashboardPayment() {
    const ax = await this.ax();
    return await ax.get("/v1/api/dashboard/payment/");
  }

  async getUsersGroupByBuilding() {
    const ax = await this.ax();
    return await ax.get("/v1/api/building/users/groupby/building");
  }

  async geApartmenBuilidingUserId(data){
    const ax = await this.ax();
    return await ax.post("/v1/api/building/user/buildingid",data);
  }

  async getBuildingStaff() {
    const ax = await this.ax();
    return await ax.get("/v1/api/building/users/groupby/buildingStaff");
  }

  async saveManagementFees(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/building/settings/management-fees", data);
  }

  async getBuildingUsers() {
    const ax = await this.ax();
    return await ax.get("/v1/api/building/users/");
  }

  async getUserByApartmentID(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/building/get-by-apartmentId", data);
  }

  async getUserDetails() {
    const ax = await this.ax();
    return await ax.get("/v1/api/user/");
  }

  async amenityBookingStatus(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/dashboard/amenity/status", data);
  }

  async usersApartmentStatus() {
    const ax = await this.ax();
    return await ax.get("/v1/api/dashboard/users/apartments/status");
  }

  async getApartments() {
    const ax = await this.ax();
    return await ax.get("/v1/api/building/apartments/");
  }
  async checkApartmentOwner(data: { apartmentId: any; buildingId }) {
    const ax = await this.ax();
    return await ax.post("/v1/api/user/apartment/check", data);
  }

  async getApartmentss(data: { buildingId: any }) {
    const ax = await this.ax();
    return await ax.get(
      "/v1/api/User/PublicBuildingApartmentList/" + data.buildingId
    );
  }
  async updateApartment(data) {
    const ax = await this.ax();
    return await ax.put("/v1/api/building/apartments/status", data);
  }

  async confirmDevice(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/User/LoginDevice/Confirm/", data);
  }

  async login(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/Auth/Dashboard", data);
  }
  private async loginWithTempToken(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/Auth/UsingTempToken", data);
  }
  async loginAdmin(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/Auth/Dashboard/admin", data);
  }

  async signUp(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/Auth/RegisterUser", data);
  }

  async getAmenities() {
    const ax = await this.ax();
    return await ax.get("/v1/api/Amenity/all");
  }

  async getAmenity(amenityId) {
    const ax = await this.ax();
    return await ax.get("/v1/api/Amenity/" + amenityId);
  }

  async addAmenity(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/Amenity", data);
  }

  async selectApartment(data: { apartmentId: any; buildingId; accountType; userId }) {
    const ax = await this.ax();
    return await ax.post("/v1/api/User/apartment/add", data);
  }

  async setActiveBuilding(data: { buildingId: any }) {
    const ax = await this.ax();
    return await ax.post("/v1/api/User/apartments/active/web", data);
  }

  async getPublicApartments(data: { buildingId: any }) {
    const ax = await this.ax();
    return await ax.get(
      "/v1/api/User/PublicBuildingApartmentList/" + data.buildingId
    );
  }

  async getPublicBuildingList() {
    const ax = await this.ax();
    return await ax.get("/v1/api/user/PublicBuildingList/");
  }

  async confirmationPhoneNumber(data: {
    deviceCode: string;
    confirmationCode: string;
  }) {
    const ax = await this.ax();
    return await ax.post("/v1/api/user/PhoneNumber/Verify", data);
  }

  async uploadDoc(data: { url; type }) {
    const ax = await this.ax();
    return await ax.post("/v1/api/User/UploadBuildingDocuments", data);
  }

  async signInGoogle(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/Auth/Google", data);
  }

  async getUserBuildingList() {
    const ax = await this.ax();
    return await ax.get("/v1/api/user/GetUserBuildings/web");
  }

  async appAuthTempToken() {
    const data = { DeviceCode: getDeviceCode() };

    const ax = await this.ax();
    return await ax.post("/v1/api/Auth/UsingTempToken", data);
  }

  async getBookings(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/Amenity/bookings/", data);
  }

  async getUserContacts() {
    const ax = await this.ax();
    return await ax.get("/v1/api/UserContacts/contacts/management");
  }

  async getNotification() {
    const ax = await this.ax();
    return await ax.get("/v1/api/User/notifications/");
  }

  async getNotificationCount() {
    const ax = await this.ax();
    return await ax.get("/v1/api/User/notifications/getNotificationCount");
  }

  async payOverTheCounter(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/payment/pay/", data);
  }

  async createExpense(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/dashboard/expense/create/", data);
  }

  async getExpense() {
    const ax = await this.ax();
    return await ax.get("/v1/api/dashboard/expense/get/");
  }

  async getPendingPayments() {
    const ax = await this.ax();
    return await ax.get("/v1/api/dashboard/payment/pending/");
  }

  async getTypes() {
    const ax = await this.ax();
    return await ax.get("/v1/api/dashboard/compound/getTypes/");
  }

  async paymentReminder(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/dashboard/payment/remind/", data);
  }

  async getAmenitiesList() {
    const ax = await this.ax();
    return await ax.get("/v1/api/amenity/amenity/getList/");
  }

  async declineBooking(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/amenity/bookings/decline/", data);
  }

  async managementAmenityBooking(data: any) {
    const ax = await this.ax();
    return await ax.post("/v1/api/amenity/management/bookings/create", data);
  }

  async editNotice(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/noticeboard/edit/", data);
  }

  async getInvoiceAgingReports(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/report/invoice/", data);
  }

  async getFacilityUsageReports(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/report/facility/", data);
  }

  async getVisitorReports(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/report/visitor/", data);
  }

  async getCurrentBuildingDetails() {
    const ax = await this.ax();
    return await ax.get("/v1/api/building/getBuildingDetails");
  }

  async updateCurrentBuildingDetails(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/building/updateBuildingDetails", data);
  }

  async SendMessagePushNotification(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/user/SendMessagePushNotification", data);
  }

  async getInvoiceByRefNumber(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/invoice/getInvoice", data);
  }

  async addNewStaffMember(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/user/staff/add", data);
  }

  async addExpenseType(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/payment/addExpenseType", data);
  }

  async addInvoiceType(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/payment/addInvoiceType", data);
  }
  async updateInvoiceTypeStatus(data){
    const ax = await this.ax();
    return await ax.put(`/v1/api/payment/updateInvoiceTypeStatus`, data);
  }
  async UpdateInvoiceType(data){
    const ax = await this.ax();
    return await ax.put(`/v1/api/payment/updateInvoiceType`, data);
  }

  async UpdateExpenseType(data){
    const ax = await this.ax();
    return await ax.put(`/v1/api/payment/updateExpenseType`, data);
  }
  async updateExpenseTypeStatus (data){
    const ax = await this.ax();
    return await ax.put(`/v1/api/payment/updateExpenseTypeStatus`,data);
  }

  async addExpenseSection(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/payment/addExpenseSection", data);
  }

  async getExpenseTypes() {
    const ax = await this.ax();
    return await ax.get("/v1/api/payment/getExpenseTypes");
  }

  async getInvoiceTypes() {
    const ax = await this.ax();
    return await ax.get("/v1/api/payment/getInvoiceTypes");
  }

  async getStaffMembers() {
    const ax = await this.ax();
    return await ax.get("/v1/api/user/staff/get");
  }

  async getUserApartments(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/building/apartments/getUserApartments", data);
  }

  async payExpense(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/payment/pay/expense/", data);
  }

  async adminDashboard(buildingId) {
    const ax = await this.ax();
    return await ax.get("/v1/api/admin/dashboard/" + buildingId);
  }

  async addCondominiumAdmin(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/admin/condominium/add/", data);
  }

  async addApartmentsAdmin(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/admin/apartments/add/", data);
  }

  async addUsersAdmin(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/admin/users/add/", data);
  }

  async getUserStatus(data){
    const ax = await this.ax();
    return await ax.post("/v1/api/user/account/get_status/",data)
  }

  async changeStatus(data){
    const ax = await this.ax();
    return await ax.post("/v1/api/user/account/delete_request/",data)
  }

  async updateUsersBuilding(data) {
    const ax = await this.ax();
    return await ax.patch("/v1/api/building/UpdateById/", data);
  }

  async updateUsersAdmin(data) {
    const ax = await this.ax();
    return await ax.patch("/v1/api/admin/users/updateByAdmin/", data);
  }

  async sendCredentialEmailAdmin(userBuildingId) {
    const ax = await this.ax();
    return await ax.get(
      "/v1/api/admin/users/credential/send/" + userBuildingId
    );
  }

  async removeUsersAdmin(id) {
    const ax = await this.ax();
    return await ax.delete("/v1/api/admin/users/remove/" + id);
  }

  async getUsersAdmin(buildingId) {
    const ax = await this.ax();
    return await ax.get("/v1/api/admin/users/get/" + buildingId);
  }

  async updateApartmentAdmin(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/admin/apartments/update/", data);
  }

  async getCondominiumsAdmin() {
    const ax = await this.ax();
    return await ax.get("/v1/api/admin/condominium/get/");
  }

  async removeCondominiumsAdmin(id) {
    const ax = await this.ax();
    return await ax.delete("/v1/api/admin/condominium/remove/" + id);
  }

  async getApartmentsAdmin(data) {
    const ax = await this.ax();
    return await ax.get("/v1/api/admin/apartments/get/" + data);
  }

  async sendNotification(data) {
    const ax = await this.ax();
    return await ax.post("/v1/api/user/sendNotification/", data);
  }

  async addMerchant(data) {
    const ax = await this.ax();
    return await ax.post("v1/api/marketplace/add/", data);
  }

  async uploadImage(data) {
    const ax = await this.ax();
    return await ax.post("v1/api/user/uploadImage/", data);
  }

  async ResidentBulkUpload(data) {
    const ax = await this.ax();
    return await ax.post("v1/api/admin/bulk/residents/", data);
  }

  async ResidentBulkUploadAnalyze(data) {
    const ax = await this.ax();
    return await ax.post("v1/api/admin/bulk/residents/analyze/", data);
  }

  async createSupportTicketTitle(data) {
    const ax = await this.ax();
    return await ax.post(
      "/v1/api/SupportTicket/createSupportTicketTittle",
      data
    );
  }

  async updateSupportTicketTitle(data) {
    const ax = await this.ax();
    return await ax.post(
      "/v1/api/SupportTicket/updateSupportTicketTittle",
      data
    );
  }

  async getGetTicketTitle() {
    const ax = await this.ax();
    return await ax.post("/v1/api/SupportTicket/getSupportTicketTittle");
  }

  async getVisitorQrData(invCode: any) {
    const ax = await this.ax();
    return await ax.get("/v1/api/auth/qr/data/" + invCode);
  }

  async NotifyUser(data: any) {
    const ax = await this.ax();
    return await ax.post("/v1/api/SupportTicket/notify/", data);
  }

  async getUsersBuildingsByBuildingId(buildingId) {
    const ax = await this.ax();
    return await ax.get("/v1/api/admin/buildingId/get/" + buildingId);
  }

  async disableUsersBuildingsByBuildingId(buildingId) {
    const ax = await this.ax();
    return await ax.patch(
      "/v1/api/building/disable/UserBuilding/" + buildingId
    );
  }

  async enableUsersBuildingsByBuildingId(buildingId) {
    const ax = await this.ax();
    return await ax.patch("/v1/api/building/enable/UserBuilding/" + buildingId);
  }

  async saveVisitor(data: any) {
    const ax = await this.ax();
    return await ax.post("/v1/api/visitors/save-visitor/", data);
  }

  async getVisitor(id) {
    const ax = await this.ax();
    return await ax.get("/v1/api/visitors/get-visitor/" + id);
  }
  async getRefundTickets() {
    const ax = await this.ax();
    return await ax.get("/v1/api/CatchMe/All-Refund-Tickets/");
  }
  async updateRefundTickets(data: any) {
    const ax = await this.ax();
    return await ax.post("/v1/api/CatchMe/refund_status", data);
  }

  async ax() {
    let env = process.env.REACT_APP_ENV;
    var baseUlr = process.env.REACT_APP_LIWE_API_URL;

    if (env == "uat") {
      baseUlr =
        "https://uat-api.269mnjnahmpvo.ap-southeast-1.cs.amazonlightsail.com";
    } else if (env == "prod") {
      baseUlr = "https://api.liwecommunities.com";
    }

    const jwt = localStorage.getItem("jwt");
    return axios.create({
      baseURL: baseUlr,
      timeout: 50000,
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
    });
  }
}
