import React, { useEffect, useState } from "react";
import ApiService from "../../services/ApiService";
import  { handleErrors, showNotification, roundOff } from "../../services/HelperMethods";
import AppButton from "../AppButton/AppButton";
import Drawer from "../Drawer/Drawer";
import DrawerBody from "../Drawer/DrawerBody";
import DrawerFooter from "../Drawer/DrawerFooter";
import TickCircle from "../TickCircle/TickCircle";

import "../CreateInvoice/CreateInvoice.scss";

export default function AddStaff(props: { isShow; onClose; onNewStaffMemberAdded; }) {

    const apiService = new ApiService();

    const [isLoading, setIsLoading] = useState(false);

    //forum
    const [selectedUserType, setSelectedUserType] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [canSubmit, setCanSubmit] = useState(false)

    useEffect(() => {
        if (selectedUserType != "" && firstName.trim() != "" && lastName.trim() != "" && email.trim() != ""
            && phoneNumber.trim() != "") {
            setCanSubmit(true);
        } else {
            setCanSubmit(false);
        }
    }, [selectedUserType, firstName, lastName, email, phoneNumber])

    return (
        <div className="create-invoice-wrap">
            <Drawer isOpen={props.isShow}>
                <DrawerBody>
                    <div>
                        <div>
                            <div className="icon-close cursor-pointer mb-2" onClick={() => { props.onClose(); }}>
                                <TickCircle icon="close" large />
                            </div>

                            <h4>Add New Staff Member</h4>

                            <div style={{ margin: "1rem 0rem" }}>
                                <select className="form-control"
                                    onChange={(e) => { setSelectedUserType(e.target.value); }} value={selectedUserType}>
                                    <option disabled={true} value={""}>--Select User--</option>
                                    <option value={"SystemAdmin"}>{"Administration"}</option>
                                    <option value={"Accountant"}>{"Accountant"}</option>
                                    <option value={"Engineer"}>{"Engineer"}</option>
                                    <option value={"HelpDesk"}>{"Help Desk"}</option>
                                    <option value={"Cleaning"}>{"Cleaning"}</option>
                                    <option value={"CleaningSupervisor"}>{"Cleaning Supervisor"}</option>
                                    <option value={"Maintenance"}>{"Maintenance"}</option>
                                    <option value={"RecreationalOfficer"}>{"Recreational Officer"}</option>
                                    {/* <option value={"Guard"}>{"Guard"}</option> */}
                                </select>

                                <div className="row mt-2">
                                    <div className="col">
                                        <label>First Name *</label>
                                        <input className="form-control" value={firstName} placeholder="First Name"
                                            onChange={(e) => { setFirstName(e.target.value); }} />
                                    </div>
                                    <div className="col">
                                        <label>Last Name *</label>
                                        <input className="form-control" value={lastName} placeholder="Last Name"
                                            onChange={(e) => { setLastName(e.target.value) }} />
                                    </div>
                                </div>
                            </div>

                            <div style={{ margin: "1rem 0rem" }}>
                                <div className="row mt-2">
                                    <div className="col">
                                        <label>Email Address *</label>
                                        <input className="form-control" value={email} placeholder="Email Address"
                                            onChange={(e) => { setEmail(e.target.value); }} />
                                    </div>
                                    <div className="col">
                                        <label>Phone Number *</label>
                                        <input className="form-control" value={phoneNumber} placeholder="77 XXX XXXX"
                                            onChange={(e) => { setPhoneNumber(e.target.value) }} maxLength={9} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DrawerBody>
                <DrawerFooter>
                    <div className="mt-1">
                        <AppButton style={{ marginBottom: 0 }} isLoading={isLoading} onClick={handleCreate}>
                            Add User
                        </AppButton>
                    </div>
                </DrawerFooter>
            </Drawer>
        </div>
    );

    async function handleCreate() {
        if (canSubmit && isNaN(parseInt(phoneNumber))) {
            showNotification("Error", "Phone Number Should Be Numeric", "error");

            return;
        }

        if (!canSubmit) {
            showNotification("Error", "Please Enter All Required Fields", "error");

            return;
        }

        setIsLoading(true);

        try {

            let data = {
                selectedUserType: selectedUserType,
                firstName: firstName,
                lastName: lastName,
                email: email,
                phoneNumber: phoneNumber
            };

            await apiService.addNewStaffMember(data).then((result) => {

                if (result.data?.isSuccessful) {
                    showNotification("Success", "New Staff Member Added", "success");
                }

            }).catch((err) => {
                showNotification("Error", err.response?.data?.error?.errorMessage, "error");
            });

            props.onNewStaffMemberAdded();
            setFirstName("")
            setLastName("")
            setEmail("")
            setPhoneNumber("")
            setSelectedUserType("")

        } catch (error) {
            handleErrors(error);
        }

        setIsLoading(false);
    }
}
