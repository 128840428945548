import React, { useState } from "react";
import ApiService from "../../services/ApiService";
import {
    getDeviceCode,
    handleErrors,
    handleLoginErrorNavigation,
    saveToken,
} from "../../services/HelperMethods";
import "./VerifyAccount.scss";
import { useHistory } from "react-router-dom";
import AppButton from "../../components/AppButton/AppButton";

export default function VerifyAccount() {
    const [confirmNumber, setConfirmNumber] = useState("");
    const apiService = new ApiService();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div className="section verify-account-wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="body">
                            <div className="verify-account-box">
                                <p className="title">Verify account</p>
                                <input
                                    type="text"
                                    placeholder="Enter your code"
                                    onChange={(e) => {
                                        setConfirmNumber(e.target.value);
                                    }}
                                />
                                <AppButton
                                    isLoading={isLoading}
                                    onClick={handleSubmit}
                                >
                                    submit
                                </AppButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    async function handleSubmit() {
        setIsLoading(true);
        try {
            let result = await apiService.confirmDevice({
                confirmationCode: confirmNumber,
                deviceCode: getDeviceCode(),
            });

            let authResult = (await apiService.appAuthTempToken()) as any;
            authResult = authResult.data;
            saveToken(authResult.token);
            history.push("/dashboard");
        } catch (error) {
            if (!handleLoginErrorNavigation(error, null, history)) {
                handleErrors(error);
            }
        }
        setIsLoading(false);
    }
}
