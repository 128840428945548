import "./SupportTickets.scss";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import Navbar from "../../components/Navbar/Navbar";
import TickCircle from "../../components/TickCircle/TickCircle";
import { getApartmentName, getNewTicketId, handleErrors, showNotification, splitWord, } from "../../services/HelperMethods";
import AppButton from "../../components/AppButton/AppButton";
import moment from "moment";
import FileInput from "../../components/FileInput/FileInput";
import { UserDetailsContext } from "../../store/Store";
import FirebaseServices from "../../services/FirebaseServices";
import ApiService from "../../services/ApiService";
import Dot from "../../components/Dot/Dot";
import Drawer from "../../components/Drawer/Drawer";
import DrawerFooter from "../../components/Drawer/DrawerFooter";
import DrawerBody from "../../components/Drawer/DrawerBody";
import AppLoading from "../../components/AppLoading/AppLoading";
import { Link } from "react-router-dom";
import SupportTicketImageModal from "./SupportTicketImageModal";

interface ImageState {
  isBase64: boolean;
  url: string;
}

function SupportTickets() {
  const conditionList = ["In Progress", "On Hold","To be Verified","Todo","Verify"]
  const apiService = new ApiService();
  const firebaseServices = new FirebaseServices();
  const [titles, setTitles] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(false);
  const [tickets, setTickets] = useState([] as any[]);
  const [searchedTickets, setSearchedTickets] = useState([] as any[]);
  const [filterResidents, setFilterResidents] = useState([] as any[]);
  const [allResidents, setAllResidents] = useState([] as any[]);
  const [apartments, setApartments] = useState([] as any[]);
  const [assignees, setAssignees] = useState([] as any[]);
  const [ticketId, setTicketId] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [createdById, setCreatedById] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [createdForId, setCreatedForId] = useState(0);
  const [createdFor, setCreatedFor] = useState("");
  const [assigneeId, setAssigneeId] = useState(0);
  const [assignee, setAssignee] = useState("");
  const [buildingId, setBuildingId] = useState("");
  const [apartmentId, setApartmentId] = useState(0);
  const [apartment, setApartment] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [priority, setPriority] = useState("");
  const [status, setStatus] = useState("");
  const [chatId, setChatId] = useState("");
  const [image, setImage] = useState([] as any[]);

  const [EditableticketId, setEditableticketId] = useState("");
  const [EditablecreatedDate, setEditablecreatedDate] = useState("");
  const [EditablecreatedById, setEditablecreatedById] = useState("");
  const [EditablecreatedBy, setEditablecreatedBy] = useState("");
  const [EditablecreatedForId, setEditablecreatedForId] = useState("");
  const [EditablecreatedFor, setEditablecreatedFor] = useState("");
  const [EditableassigneeId, setEditableassigneeId] = useState("");
  const [Editableassignee, setEditableassignee] = useState("");
  const [EditablebuildingId, setEditablebuildingId] = useState("");
  const [EditableapartmentId, setEditableapartmentId] = useState("");
  const [Editableapartment, setEditableapartment] = useState("");
  const [Editabletitle, setEditabletitle] = useState("");
  const [Editabledescription, setEditabledescription] = useState("");
  const [Editablepriority, setEditablepriority] = useState("");
  const [Editablestatus, setEditablestatus] = useState("");
  const [EditablechatId, setEditablechatId] = useState("");
  const [EditableTicketFirebaseId, setEditableTicketFirebaseId] = useState("");
  const [showAddTicket, setShowAddTicket] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null as any);
  const [isEditable, setIsEditable] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [user, set] = useContext(UserDetailsContext);

  useEffect(() => {
    getApartments(); 
    getDetails();
    getTicketTitles();
  }, [user]);

  useEffect(() => {
    
  }, [title]);

  useEffect(() => {
    if (apartmentId) {
      getUserByApartmentId(apartmentId)
    }
  }, [apartmentId]);

  useEffect(() => {
    if (createdForId != 0) {
      var createdFor = filterResidents.find(function (element) {
        return element?.id == createdForId;
      });

      setCreatedFor(createdFor?.firstName + " " + createdFor?.lastName);
    }

    if (assigneeId != 0) {
      var assignee = assignees.find(function (element) {
        return element?.staffId == assigneeId;
      });

      setAssignee(assignee?.staffMemberName);
    }

    if (apartmentId != 0) {
      var apartment = apartments.find(function (element) {
        return element?.id == apartmentId;
      });

      setApartment(getApartmentName(apartment?.location, apartment?.floor, apartment?.doorNumber));
    }
  }, [createdForId, assigneeId, apartmentId]);

  useEffect(() => {
    if(!user) return;
    try {
      const unsubscribe = firebaseServices
      .getDb()
      .collection("supportTickets")
      .where("buildingId","==",user?.buildingId)
      .where("status", "in",conditionList)
      .orderBy("createdDate", "desc")
        .onSnapshot((snapshot) => {
          let tickets = [] as any;
          snapshot.forEach(async (doc) => {
            let ticket = doc.data();
            ticket.firebaseId = doc.id;
            
            tickets.push(ticket);
          });
          setSearchedTickets(tickets);
          setTickets(tickets);
        });
      return unsubscribe;
    } catch (error) {
      handleErrors(error);
    }
  }, [user]);

  async function getUserByApartmentId(Id) {
    try {
      let data = {
        apartmentId: Id
      };
      setIsLoading(true);
      let result = await apiService.getUserByApartmentID(data);
      console.log(result?.data, "getUserByApartmentID");
      setFilterResidents(result?.data?.users);
      setIsLoading(false);
    }
    catch (error) {
      handleErrors(error);
      setIsLoading(false);
    }
  }

  function getTicketTitles() {
    setIsLoading(true);

    apiService.getGetTicketTitle().then((result) => {
      let data = result.data;
      const filterdSupportTickets = data.titles.filter((ticket)=>ticket.status===true);
      setTitles(filterdSupportTickets);
      setIsLoading(false);
    });

  }

  async function getApartments() {
    setIsLoading(true);
    let data = {
      userId: createdForId,
    };
    await apiService.getUserApartments(data).then((result) => {
      let data = result.data;
      setApartments(data?.apartments);
      setIsLoading(false);
    });
    await apiService.getApartments().then((result) => {
      let data = result.data;
      setApartments(data?.apartment);
      setIsLoading(false);
    });
    setIsLoading(false);
  }

  async function getDetails() {
    let result = await apiService.getBuildingUsers();
    console.log(result, "getBuildingUsers");
    let assignees = await apiService.getStaffMembers();

    setAllResidents(result?.data?.users);
    setFilterResidents(result?.data?.users);

    setAssignees(assignees?.data?.staffMembers);

    console.log(assignees?.data, "getStaffMembers");
  }

  async function handleEdit() {
    if (Editabletitle.trim() == "") {
      showNotification("Validation Error", "Validation Error", "error");
      return;
    }

    if (Editabledescription.trim() == "") {
      showNotification("Validation Error", "Validation Error", "error");
      return;
    }

    setIsLoading(true);

    try {
        await firebaseServices.updateTicket(selectedTicket.firebaseId, {
          ticketId: EditableticketId,
          createdDate: EditablecreatedDate,
          createdById: parseInt(EditablecreatedById),
          createdBy: EditablecreatedBy,
          createdForId: parseInt(EditablecreatedForId),
          createdFor: EditablecreatedFor,
          assigneeId: EditableassigneeId !== null ? parseInt(EditableassigneeId) : null,
          assignee: Editableassignee,
          buildingId: parseInt(EditablebuildingId),
          apartmentId: parseInt(EditableapartmentId),
          apartment: Editableapartment,
          title: Editabletitle,
          description: Editabledescription,
          priority: Editablepriority,
          status: Editablestatus,
          chatId: EditablechatId,
          completedDate: Editablestatus == "Done" ? firebaseServices.timeStamp() : null
        });

        showNotification("Successfully Edited", "Successfully Edited", "success");
        // The end-point will be called only if the status changes
        if (Editablestatus != selectedTicket.status) {
          let data = {
            userId: EditablecreatedForId,
            jobId: EditableticketId.substring(0, 13),
            currentStatus: Editablestatus,
            title: "Support Ticket Status Changed",
            body: EditableticketId.substring(0, 13) + " Status changed to " + Editablestatus
          };

          apiService.NotifyUser(data);
        }

        showNotification("Successfully Edited", "Successfully Edited", "success");

        clearFields();

        // The end-point will be called only if the assigned person changes.
        if (EditableassigneeId != selectedTicket.assigneeId) {
          let data = {
            sendToId: EditableassigneeId,
            title: "New Support Ticket",
            body: "New support ticket has been assigned to you"
          }

          apiService.sendNotification(data)

          let data1 = {
            userId: EditableassigneeId,
            jobId: EditableticketId.substring(0, 13),
            currentStatus: Editablestatus,
            title: "Support Ticket Assigned",
            body: EditableticketId.substring(0, 13) + " Assigned to " + Editableassignee
          };

          apiService.NotifyUser(data1);
        }

      
    } catch (error) {
      handleErrors(error);
      clearFields();
    }

    clearFields();
    setIsEditable(false);
    setSelectedTicket(null);
    getDetails();
    setIsLoading(false);
  }

  function editable() {
    setIsEditable(true);
    setEditableTicketFirebaseId(selectedTicket.firebaseId);
    setEditableticketId(selectedTicket.ticketId);
    setEditablecreatedDate(selectedTicket.createdDate);
    setEditablecreatedById(selectedTicket.createdById);
    setEditablecreatedBy(selectedTicket.createdBy);
    setEditablecreatedForId(selectedTicket.createdForId);
    setEditablecreatedFor(selectedTicket.createdFor);
    setEditableassigneeId(selectedTicket.assigneeId);
    setEditableassignee(selectedTicket.assignee);
    setEditablebuildingId(selectedTicket.buildingId);
    setEditableapartmentId(selectedTicket.apartmentId);
    setEditableapartment(selectedTicket.apartment);
    setEditabletitle(selectedTicket?.title);
    setEditabledescription(selectedTicket?.description);
    setEditablepriority(selectedTicket.priority);
    setEditablestatus(selectedTicket.status);
    setEditablechatId(selectedTicket.chatId);
  }

  function getAssignee(assigneeId) {
    if (assigneeId != 0) {
      var assignee = assignees.find(function (element) {
        return element?.staffId == assigneeId;
      });

      setEditableassigneeId(assigneeId);
      setEditableassignee(assignee?.staffMemberName);
    }
  }

  function trimBuildingname() {
    var buildname = user.buildingName;
    const nameArray = buildname.split(" ");
    return (nameArray[0]);
  }


  async function handleSubmit() {
    if (title.trim() == "") {
      showNotification("Enter a Title", "Enter a Title", "error");
      return;
    }

    if (title == "Select Support Title") {
      showNotification("Select a Title", "Select a Title", "error");
      return;
    }

    if (description.trim() == "") {
      showNotification("Enter Description", "Enter Description", "error");
      return;
    }

    setIsLoading(true);

    let ticketId = getNewTicketId();

    try {

      let uploadedUrl : string[] = [];
      if (image?.length > 0) {
        for (let index = 0; index < image.length; index++) {
          uploadedUrl.push(await firebaseServices.uploadDoc(image[index]))
        }
      }

      await firebaseServices.createTicket({
        ticketId: trimBuildingname() + "-" + ticketId,
        createdDate: firebaseServices.timeStamp(),
        createdById: user?.id,
        createdBy: user?.firstName + " " + user?.lastName,
        createdForId: createdForId,
        createdFor: createdFor,
        assigneeId: assigneeId,
        assignee: assignee,
        buildingId: user?.buildingId,
        apartmentId: apartmentId,
        apartment: apartment,
        title: title,
        description: description,
        priority: priority,
        status: status,
        chatId: ticketId,
        attachment: (uploadedUrl != null ) ? uploadedUrl : [],
      });

      if (assigneeId) {
        let data = {
          sendToId: assigneeId,
          title: "New Support Ticket",
          body: "New support ticket has been assigned to you"
        }

        apiService.sendNotification(data)

        let data1 = {
          userId: createdForId,
          jobId: ticketId.substring(0, 13),
          currentStatus: status,
          title: "Support Ticket Assigned",
          body: ticketId.substring(0, 13) + " Assigned to " + assignee
        };
        apiService.NotifyUser(data1);
      }

      showNotification("Ticket Created", "Ticket Created", "success");
      clearFields();

    } catch (error) {
      handleErrors(error);
      clearFields();
    }

    clearFields();
    getDetails();
    setShowAddTicket(false);
    setIsLoading(false);
  }

  function clearFields() {
    setTicketId("");
    setCreatedDate("");
    setCreatedById("");
    setCreatedBy("");
    setCreatedForId(0);
    setCreatedFor("");
    setAssigneeId(0);
    setAssignee("");
    setBuildingId("");
    setApartmentId(0);
    setApartment("");
    setTitle("");
    setDescription("");
    setPriority("");
    setStatus("");
    setChatId("");
    setImage(null as any);
    setSelectedTicket(null);

    setEditableticketId("");
    setEditablecreatedDate("");
    setEditablecreatedById("");
    setEditablecreatedBy("");
    setEditablecreatedForId("");
    setEditablecreatedFor("");
    setEditableassigneeId("");
    setEditableassignee("");
    setEditablebuildingId("");
    setEditableapartmentId("");
    setEditableapartment("");
    setEditabletitle("");
    setEditabledescription("");
    setEditablepriority("");
    setEditablestatus("");
    setEditablechatId("");
    setEditableTicketFirebaseId("");
  }

  function handleFilter(array: any[], searchedWord: string) {
    let searchedArray = [] as any[];
    array.forEach((ticket) => {
      if (ticket?.ticketId.toString().toLowerCase().includes(searchedWord.toLowerCase())
        || moment.utc(ticket?.createdDate?.seconds * 1000).local().format("LL").toString().toLowerCase().includes(searchedWord.toLowerCase())
        || ticket?.title.toLowerCase().includes(searchedWord.toLowerCase())
        || ticket?.createdBy.toLowerCase().includes(searchedWord.toLowerCase())
        || ticket?.apartment.toLowerCase().includes(searchedWord.toLowerCase())
        || ticket?.assignee?.toLowerCase().includes(searchedWord.toLowerCase())
        || ticket?.priority?.toString().toLowerCase().includes(searchedWord.toLowerCase())
        || ticket?.status.toLowerCase().includes(searchedWord.toLowerCase())
      ) {
        searchedArray.push(ticket);
      }
    });
    return searchedArray;
  }

  function handleSearch(searchedWord: string) {
    setIsLoading(true);
    if (searchedWord.trim() === "") {
      setSearchedTickets(tickets);
      setIsLoading(false);
      return;
    }

    let searchedArray = handleFilter(tickets, searchedWord);
    setSearchedTickets(searchedArray);
    setIsLoading(false);
  }
  function imagecheck(){
    console.log(selectedTicket,"image test");
    
  }

  function openModal(url: any){
    setSelectedImage(url);
    setModalIsOpen(true);
  }
  function closeModal(){
    setSelectedImage(null);
    setModalIsOpen(false);
  }

  return (
    <div className="body overflow-auto">
      <AppLoading isLoading={isLoading} />
      <h2>Support Tickets</h2>

      <Navbar showSearch hideFilter={true} hideSettings={true} hideAdd={false}
        onAddButtonClick={() => {
          setShowAddTicket(true);
          setTitle("");
          setDescription("");
          setImage(null as any);
        }} onSearchChange={handleSearch} >
          <Link className="active" to="/support-tickets">Ongoing</Link>
          <Link to="/completed-support-tickets">Completed</Link>
        <Link to="/cancelled-support-tickets">Canceled</Link>
        </Navbar>
      <Table responsive="lg" borderless={true} id="support-tickets-table">
        <thead>
          <tr>
            <th>Job Id</th>
            <th>Date Created</th>
            <th>Title</th>
            <th>Reported By</th>
            <th>Apartment</th>
            <th>Priority</th>
            <th style={{ textAlign: "left" }}>Status</th>
            <th>Assignee</th>
          </tr>
        </thead>

        <tbody>
          {searchedTickets.map((ticket, i) => {
            return (
              <tr className="cursor-pointer" key={i} onClick={() => {
                if (!isEditable) {
                  setSelectedTicket(ticket);
                  imagecheck();
                }
              }}>
                <td
                  style={{ textTransform: 'uppercase' }}>{ticket?.ticketId.length > 10 ? ticket?.ticketId.substring(0, 13) : null}</td>
                <td>
                  {moment.utc(ticket?.createdDate?.seconds * 1000).local().format("LLL")}
                </td>
                <td>{ticket?.title}</td>
                <td>{ticket?.createdBy}</td>
                <td>{ticket?.apartment}</td>
                <td>{ticket?.priority}</td>
                <td style={{ textAlign: "left" }}>
                  {ticket.status == "Todo"
                    ? (<>
                      <Dot color={"blue"} style={{ marginRight: "0.5rem", color: "#0000FF" }} />
                      {ticket?.status}
                    </>)
                    : null
                  }
                  {ticket.status == "Cancel"
                    ? (<>
                      <Dot color={"orange"} style={{ marginRight: "0.5rem", color: "#964B00" }} />
                      {ticket?.status}
                    </>)
                    : null
                  }

                  {ticket?.status == "In Progress"
                    ? (<>
                      <Dot color={"yellow"} style={{ marginRight: "0.5rem", color: "#FFFF00" }} />
                      {ticket?.status}
                    </>)
                    : null
                  }

                  {/*todo remove "Verify" check*/}
                  {ticket?.status == "Verify" || ticket?.status == "To be Verified"
                    ? (<>
                      <Dot color={"purple"} style={{ marginRight: "0.5rem", color: "#A020F0" }} />
                      {ticket?.status}
                    </>)
                    : null
                  }

                  {ticket?.status == "Done"
                    ? (<>
                      <Dot color={"green"} style={{ marginRight: "0.5rem", color: "#4BBB46" }} />
                      {ticket?.status == "Done" ? ("Closed") : ticket?.status}
                    </>)
                    : null
                  }

                  {ticket?.status == "On Hold"
                    ? (<>
                      <Dot color={"red"} style={{ marginRight: "0.5rem", color: "#FF0000" }} />
                      {ticket?.status}
                    </>)
                    : null
                  }
                </td>
                <td>{ticket?.assignee}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Drawer isOpen={selectedTicket != null}>
        <DrawerBody>
          <div>
            <div>
              <div className="icon-close cursor-pointer mb-2"
                onClick={() => {
                  clearFields();
                  setShowAddTicket(false);
                  setSelectedTicket(null);
                  setIsEditable(false);
                }}>
                <TickCircle icon="close" large />
              </div>
              <div className="row mt-3">
                <div className="col">
                  <p className="m-des-hd">Job Id</p>

                  <span
                    style={{ textTransform: 'uppercase' }}>{selectedTicket?.ticketId.length > 10 ? selectedTicket?.ticketId.substring(0, 13) : null}</span>
                </div>

                <div className="col">
                  <p className="m-des-hd">Title</p>

                  <span>{selectedTicket?.title}</span>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col">
                  <p className="m-des-hd">Creator</p>
                  <span>{selectedTicket?.createdBy}</span>
                </div>

                <div className="col">
                  <p className="m-des-hd">Created Date</p>
                  <span>
                    {moment.utc(selectedTicket?.createdDate?.seconds * 1000).local().format("LL")}
                  </span>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col">
                  <p className="m-des-hd">Priority</p>
                  <div>
                    {isEditable
                      ? (<select style={{ border: "1px solid #4bbb46" }} className="form-control"
                        onChange={(e) => {
                          setEditablepriority(e.target.value);
                        }} value={Editablepriority}>
                        <option disabled={true} value="">--Select Priority--</option>
                        <option value={"Low"}>Low</option>
                        <option value={"Medium"}>Medium</option>
                        <option value={"High"}>High</option>
                      </select>)
                      : (<span>{selectedTicket?.priority}</span>)
                    }
                  </div>
                </div>

                <div className="col">
                  <p className="m-des-hd">Status</p>
                  <div>
                    {isEditable
                      ? (<select style={{ border: "1px solid #4bbb46" }} className="form-control"
                        onChange={(e) => {
                          setEditablestatus(e.target.value);
                        }} value={Editablestatus}>
                        <option disabled={true} value="">--Select Status--</option>
                        <option value={"On Hold"}>On Hold</option>
                        <option value={"Todo"}>Todo</option>
                        <option value={"In Progress"}>In Progress</option>
                        <option value={"To be Verified"}>To be Verified</option>
                        <option value={"Done"}>Closed</option>
                      </select>)
                      : (<span>{selectedTicket?.status == "Done" ? ("Closed") : selectedTicket?.status}</span>)
                    }
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col">
                  <p className="m-des-hd">Assignee</p>
                  {isEditable
                    ? (<select style={{ border: "1px solid #4bbb46" }} className="form-control"
                      onChange={(e) => {
                        getAssignee(e.target.value)
                      }} value={EditableassigneeId}>
                      <option value={0}>Select Assignee</option>
                      {assignees?.map((assignee, index) => {
                        return (
                          <option key={index} value={assignee?.staffId}>
                            {assignee?.staffMemberName + " - " + assignee?.staffType}
                          </option>
                        );
                      })}
                    </select>)
                    : (<span>{selectedTicket?.assignee}</span>)
                  }
                </div>
              </div>

              <div className="mt-3">
                <p className="m-des-hd">Description</p>
                <span>{selectedTicket?.description}</span>
              </div>

              <div className="mt-3">
                {isEditable
                  ? (<>
                    {/* <div className="mt-3">
                      <p className="m-des-hd">Attachment</p>
                    </div>
                    <div>
                      <div className="row align-items-center mt-1">
                        <div className="col-auto pr-0">
                        <div className="image-upload-wrap">
                          {selectedTicket?.createdBy === user?.firstName + " " + user?.lastName && (
                            <div>
                              <span className="upload-icon">
                              <i className="fas fa-camera" />
                              </span>
                              <FileInput onChange={handleImageChange} />
                            </div>
                          )}
                          {image.map((image, index) => (
                           <img key={index} src={image} className="img-main" alt={`Image ${index + 1}`} />
                          ))}
                            </div>
                        </div>
                      </div>
                    </div> */}
                  </>)
                  : (<>
                    <div className="mt-3">
                      {selectedTicket?.attachment != null && (<p className="m-des-hd">Attachment</p>)}
                    </div>

                    <div>
                      <div>

                      {selectedTicket?.attachment != null && (
                        <div className="row align-items-center mt-1">
                         {Array.isArray(selectedTicket.attachment) ? (
                           selectedTicket.attachment.map((url, index) => (url && (

                            <div key={index} className="col-auto pr-0" style={{cursor: "pointer"}} onClick={() => openModal(url)}>
                              <div className="image-upload-wrap">
                                <img src={url} className="img-main" />
                              </div>
                            </div>

                            )
                         ))
                         ) : (
                            <div className="col-auto pr-0" onClick={() => openModal(selectedTicket?.attachment)}>
                              <div className="image-upload-wrap">
                                <img src={selectedTicket.attachment} className="img-main" />
                              </div>
                            </div>
                          )}
                        </div>
                        )}
                      </div>
                    </div>
                  </>
                  )
                }
              </div>
            </div>
          </div>
        </DrawerBody>

        <DrawerFooter>
          <div className="mt-1">
            {selectedTicket?.status != "Done"
              ? (<AppButton isLoading={isLoading}
                onClick={() => {
                  if (isEditable) {
                    handleEdit();
                  } else {
                    editable();
                  }
                }}>
                {isEditable ? "Save" : "Edit"}
              </AppButton>)
              : (<Fragment />)
            }
          </div>
        </DrawerFooter>
      </Drawer>

      <SupportTicketImageModal modalIsOpen={modalIsOpen} onCloseModal={closeModal} selectedImage={selectedImage}/>
      <Drawer isOpen={showAddTicket}>
        <DrawerBody>
          <div>
            <div>
              <div className="icon-close cursor-pointer mb-2"
                onClick={() => {
                  clearFields();
                  setShowAddTicket(false);
                }}>
                <TickCircle icon="close" large />
              </div>

              <div>
                <p className="m-des-hd">Title *</p>
                <select style={{ border: "1px solid #4bbb46" }} className="form-control"
                  defaultValue={title} value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}>
                  <option disabled={true} value={""}>--Select Support Title--</option>
                  {titles.map((title, i) => {
                    return (<option key={i} value={title.title}>{title.title}</option>);
                  })}
                </select>
              </div>

              <div className="row mt-3">
                <div className="col">
                  <p className="m-des-hd">Priority *</p>
                  <div>
                    <select style={{ border: "1px solid #4bbb46" }} className="form-control"
                      onChange={(e) => {
                        setPriority(e.target.value);
                      }} value={priority}>
                      <option disabled={true} value="">--Select Priority--</option>
                      <option value={"Low"}>Low</option>
                      <option value={"Medium"}>Medium</option>
                      <option value={"High"}>High</option>
                    </select>
                  </div>
                </div>
                <div className="col">
                  <p className="m-des-hd">Status *</p>
                  <div>
                    <select style={{ border: "1px solid #4bbb46" }} className="form-control"
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }} value={status}>
                      <option  disabled={true} value="">--Select Status--</option>
                      <option value={"On Hold"}>On Hold</option>
                      <option value={"Todo"}>Todo</option>
                      <option value={"Cancel"}>Cancel</option>
                      <option value={"In Progress"}>In Progress</option>
                      <option value={"To be Verified"}>To be Verified</option>
                      <option value={"Done"}>Closed</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col">
                  <p className="m-des-hd">Apartment</p>
                  <select style={{ border: "1px solid #4bbb46" }} className="form-control"
                    onChange={(e) => {
                      setApartmentId(parseInt(e.target.value));
                    }} value={apartmentId}>
                    <option disabled={true} value={0}>--Select Apartment--</option>
                    {apartments?.map((apartment, i) => {
                      return (
                        <option value={apartment?.id} key={i}>
                          {getApartmentName(apartment?.location, apartment?.floor, apartment?.doorNumber)}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="col">
                  <p className="m-des-hd">Resident</p>
                  <select style={{ border: "1px solid #4bbb46" }} className="form-control"
                    onChange={(e) => {
                      setCreatedForId(parseInt(e.target.value));
                    }} value={createdForId}>
                    <option disabled={true} value={0}>--Select Resident--</option>
                    {filterResidents?.map((u, i) => {
                      return (
                        <option value={u.id} key={i}>
                          {u.firstName} {u.lastName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="mt-3">
                <p className="m-des-hd">Assignee</p>
                <select style={{ border: "1px solid #4bbb46" }} className="form-control"
                  onChange={(e) => {
                    setAssigneeId(parseInt(e.target.value));
                  }} value={assigneeId}>
                  <option disabled={true} value={0}>--Select Assignee--</option>
                  {assignees?.map((assignee, index) => {
                    return (
                      <option key={index} value={assignee?.staffId}>
                        {assignee?.staffMemberName + " - " + assignee?.staffType}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="mt-3">
                <p className="m-des-hd">Description *</p>
                <textarea placeholder="Description" rows={5} className="form-control" maxLength={250}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }} value={description} />
              </div>

              <div className="mt-3">
                <p className="m-des-hd">Attachment</p>
                <div>
                  <div className="row align-items-center mt-1">
                    <div className="col-auto pr-0">
                      {image && image.length > 2 ? 
                        <Fragment/> : <Button>
                          <div>
                            <FileInput onChange={(url) => {
                              if (url) {
                              let imgs = Array.isArray(image) ? [...image] : [];
                              imgs.push(url);
                              setImage(imgs);
                              ;}}} />
                      </div>
                        <p style={{color:"black"}}>Add Images/Attachments</p>
                    </Button>}
                    

                      <div className="image-upload-wrap">
                        {image && image.map((img, index) => (
                          <div key={index} >
                            <img src={img} className="img-main" alt={`Image ${index + 1}`} />
                      </div>
                      ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DrawerBody>

        <DrawerFooter>
          <div className="mt-1">
            <AppButton isLoading={isLoading} onClick={() => {
              handleSubmit();
            }}>
              Create Ticket
            </AppButton>
          </div>
        </DrawerFooter>
      </Drawer>
    </div>
  );
}
export default SupportTickets;