import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import { Table } from "react-bootstrap";
import moment from "moment";
import { DatePicker } from "antd";
import AppButton from "../../components/AppButton/AppButton";
import AppLoading from "../../components/AppLoading/AppLoading";
import { UserDetailsContext } from "../../store/Store";
import Dot from "../../components/Dot/Dot";
import { handleErrors } from "../../services/HelperMethods";
import FirebaseServices from "../../services/FirebaseServices";

import "./Reporting.scss";
import ExcelExport from "../../components/ExcelExport/ExcelExport";

export default function SupportTicketReport() {

  const firebaseServices = new FirebaseServices();

  const [user, set] = useContext(UserDetailsContext);

  const [fromDate, setFromDate] = useState(moment().add("-1", "days"));
  const [toDate, setToDate] = useState(moment());
  const [isLoading, setIsLoading] = useState(false);
  const [tickets, setTickets] = useState([] as any[]);

  useEffect(() => {
    var from = moment().add("-1", "days")
    var to = moment()

    filterData(from, to)
  }, [])

  async function filterData(from, to) {

    setTickets([] as any[]);

    setIsLoading(true);

    const fromYear = moment(from).format("YYYY");
    const fromMonth = moment(from).format("MM");
    const fromDate = moment(from).format("DD");

    const transformedFromDate = new Date(
      fromYear + "-" + fromMonth + "-" + fromDate + " 00:00:01"
    );

    const toYear = moment(to).format("YYYY");
    const toMonth = moment(to).format("MM");
    const toDate = moment(to).format("DD");

    const tnasformedToDate = new Date(
      toYear + "-" + toMonth + "-" + toDate + " 23:59:59"
    );

    try {
      firebaseServices.getDb().collection("supportTickets")
        .where("createdDate", ">=", transformedFromDate)
        .where("createdDate", "<=", tnasformedToDate)
        .orderBy("createdDate", "desc").get().then((res) => {

          let tickets = [] as any;

          res.forEach(async (doc) => {

            let ticket = doc.data();

            ticket.firebaseId = doc.id;

            if (ticket?.buildingId == user?.buildingId) {
              tickets.push(ticket);
            }
          });

          setTickets(tickets);
          setIsLoading(false);
        }).catch((err) => {
          console.log(err, err);
          setIsLoading(false);
        });
    } catch (error) {
      handleErrors(error);
      setIsLoading(false);
    }
  }

   // Function to extract desired attributes from a ticket object
   const extractAttributes = (ticket) => {  
    const extractedTicket = {
      "Date Reported": moment.utc(ticket?.createdDate?.seconds * 1000).local().format("LLL"),
      "Date Completed":ticket?.completedDate ? (moment.utc(ticket?.completedDate?.seconds * 1000).local().format("LLL")) : ("-"),
      "Title": ticket?.title ,
      "Reported By": ticket?.createdBy,
      "Apartment": ticket?.apartment ? (ticket?.apartment) : ("-"),
      "Priority": ticket?.priority,
      "Status": ticket?.status,
      "Assignee": ticket?.assignee ? (ticket?.assignee) : ("-"),
    };
    return extractedTicket;
  };

  // Create a new list with objects containing only the desired attributes
  const modifiedTicketList = tickets.map(ticket => extractAttributes(ticket));


  return (
    <div className="body ">
      <AppLoading isLoading={isLoading} />
      <h2>Reports</h2>

      <div>
        <Navbar hideSettings={true} hideAdd={true} hideFilter={true}>
          <Link to="/reports/invoiceAgingReport">Invoice Aging Report</Link>
          <Link to="/reports/facilityUsageReport">Facility Usage Report</Link>
          <Link to="/reports/visitorReports">Visitor Reports</Link>
          <Link to="#" className="active">Support Tickets Report</Link>
        </Navbar>
      </div>

      <div className="filter-area">
        <AppLoading isLoading={isLoading} />
        <div className="filter-area-item">
          <span>From : </span>
          <DatePicker defaultValue={moment().add("-1", "days")}
            onChange={(date) => {
              if (date) {
                setFromDate(date);
              }
            }} format={"DD/MM/YYYY"} allowClear={false} />
        </div>

        <div className="filter-area-item">
          <span>To : </span>
          <DatePicker defaultValue={moment()}
            onChange={(date) => {
              if (date) {
                setToDate(date);
              }
            }} format={"DD/MM/YYYY"}
            disabledDate={(date) => {
              let isd = moment(fromDate);
              return !date || date.isBefore(isd);
            }} allowClear={false} />
        </div>

        <div className="filter-area-item">
          <AppButton onClick={() => { filterData(fromDate, toDate); }}>
            Filter
          </AppButton>
        </div>

        <div className="filter-area-item">
          <ExcelExport excelData={modifiedTicketList} fileName={"SupportTicketsReport"} />
        </div>
      </div>

      <div style={{ maxWidth: "76rem", overflow: "auto" }}>
        <Table responsive="lg" borderless={true} id="support-tickets-table">
          <thead>
            <tr>
              <th>Date Reported</th>
              <th>Date Completed</th>
              <th>Title</th>
              <th>Reported By</th>
              <th>Apartment</th>
              <th>Priority</th>
              <th>Status</th>
              <th>Assignee</th>
            </tr>
          </thead>

          <tbody>
            {tickets.map((ticket, i) => {
              return (
                <tr key={i}>
                  <td>{moment.utc(ticket?.createdDate?.seconds * 1000).local().format("LLL")}</td>
                  <td>{ticket?.completedDate
                    ? (moment.utc(ticket?.completedDate?.seconds * 1000).local().format("LLL"))
                    : ("-")
                  }</td>
                  <td>{ticket?.title}</td>
                  <td>{ticket?.createdBy}</td>
                  <td>{ticket?.apartment
                    ? (ticket?.apartment)
                    : ("-")
                  }</td>
                  <td>{ticket?.priority}</td>
                  <td>{ticket?.status}</td>
                  <td>{ticket?.assignee
                    ? (ticket?.assignee)
                    : ("-")
                  }</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
