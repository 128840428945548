import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import AppButton from "../../components/AppButton/AppButton";
import ApiService from "../../services/ApiService";
import { useHistory } from "react-router-dom";

export default function VerificationResult() {
    const apiService = new ApiService();
    const [status, setStatus] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        checkStatus();
    }, []);

    return (
        <div className="section signup-wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="body">
                            <div className="sign-up-box">
                                <h2>Verification Status</h2>

                                <div>
                                    {status == 1 ? (
                                        "Your account is not active yet!"
                                    ) : (
                                        <ClipLoader
                                            size={20}
                                            color={"#8acb87"}
                                        />
                                    )}

                                    <div className="mt-3">
                                        <AppButton
                                            onClick={checkStatus}
                                            isLoading={isLoading}
                                        >
                                            Check again
                                        </AppButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    async function checkStatus() {
        setIsLoading(true);
        try {
            let result = await apiService.appAuthTempToken();
            history.push("/dashboard");
        } catch (error) {
            if (
                error.response?.data?.error?.errorType ==
                "user_building_not_active"
            ) {
                setStatus(1);
            }
        }
        setIsLoading(false);
    }
}
