import { Fragment, useEffect, useState } from "react";
import ApiService from "../../services/ApiService";
import AppLoading from "../../components/AppLoading/AppLoading";
import AppButton from "../../components/AppButton/AppButton";
import React from "react";
import { FaTimesCircle, FaSave } from "react-icons/fa";
import { showNotification } from "../../services/HelperMethods";

export default function MarketplaceAdminView() {

    const apiService = new ApiService();

    const [password, setPassword] = useState("");
    const [showForm, setShowForm] = useState(false);
    const [condominiums, setCondominiums] = useState([] as any);
    const [isLoading, setIsLoading] = useState(false);

    const [apartmentSelectedCondominiumId, setApartmentSelectedCondominiumId] = useState(0);
    const [apartments, setApartments] = useState([{
        floor: "", doorNumber: "", type: "", bedrooms: 0,
        location: "", totalSqFt: 0
    },] as any[]);

    useEffect(() => {
        getCondominiums()
    }, [])

    useEffect(() => {
        if (password.trim() == "TDIAD") {
            setShowForm(true);
        }
    }, [password])

    useEffect(() => {
        if (apartmentSelectedCondominiumId > 0) {
            getApartments();
        }
    }, [apartmentSelectedCondominiumId])

    function getCondominiums() {

        setIsLoading(true);

        apiService.getCondominiumsAdmin().then((result) => {

            let data = result.data

            if (data?.isSuccessful) {
                setCondominiums(data?.buildings)
            }
        }).catch((err) => {
            showNotification(err.response.data.error.errorMessage, err.response.data.error.errorMessage, "error")
        })

        setIsLoading(false);
    }

    function getApartments() {

        setIsLoading(true);

        let data = {
            buildingId: apartmentSelectedCondominiumId
        }

        apiService.getApartmentsAdmin(data).then((result) => {

            let data = result.data

            if (data?.isSuccessful) {

                var tempApartments = data?.apartments

                tempApartments.sort(function (a, b) {
                    if (a.doorNumber < b.doorNumber) { return -1; }
                    if (a.doorNumber > b.doorNumber) { return 1; }
                    return 0;
                })

                tempApartments.sort(function (a, b) {
                    return a.floor - b.floor;
                })

                setApartments(tempApartments)
            }
        }).catch((err) => {
            showNotification(err.response.data.error.errorMessage, err.response.data.error.errorMessage, "error")
        })

        setIsLoading(false);
    }

    function edit(apartmentId, floor, doorNumber, type, bedrooms, location, totalSqFt) {

        setIsLoading(true);

        let data = {
            buildingId: apartmentSelectedCondominiumId,
            apartmentId: apartmentId,
            floor: floor,
            doorNumber: doorNumber,
            type: type,
            bedrooms: bedrooms,
            location: location,
            totalSqFt: totalSqFt
        }

        apiService.updateApartmentAdmin(data).then((result) => {

            let data = result.data
            console.log(data);

            if (data?.isSuccessful) {
                showNotification("Apartment Updated Successfully", "Apartment Updated Successfully", "success")

                getApartments();
            } else {
                showNotification("Error Updating Apartment", "Error Updating Apartment", "error")
            }
        }).catch((err) => {
            showNotification(err.response.data.error.errorMessage, err.response.data.error.errorMessage, "error")
        })

        setIsLoading(false);
    }

    return (
        <>
            {showForm
                ? (isLoading
                    ? (<div style={{ minWidth: "100vw", minHeight: "100vh" }}>
                        <AppLoading isLoading={isLoading} />
                    </div>)
                    : (<div style={{
                        display: "flex", flexDirection: "column", minWidth: "100vw", minHeight: "100vh",
                        padding: "4rem 0rem"
                    }}>
                        {viewUI()}
                    </div>)
                )
                : (<div style={{
                    display: "flex", flexDirection: "column", alignItems: "center",
                    justifyContent: "center", minWidth: "100vw", minHeight: "100vh"
                }}>
                    <div>
                        <input className="form-control" style={{ borderRadius: "0.5rem" }} type="text"
                            onChange={(e) => { setPassword(e.target.value); }} placeholder="Enter Password" />
                    </div>
                </div>)
            }
        </>
    )

    function viewUI() {
        return (
            <div style={{ marginBottom: "2rem" }}>
                <h1 style={{ textAlign: "center" }}>View Apartments</h1>
                <div style={{ display: "flex", justifyContent: "space-evenly", marginBottom: "1rem" }}>
                    <select className="form-control" style={{ maxWidth: "14rem" }}
                        onChange={(e) => { setApartmentSelectedCondominiumId(parseInt(e.target.value)); }}
                        value={apartmentSelectedCondominiumId}>
                        <option value={0}>Select Condominium</option>
                        {condominiums?.map((condominium, index) => {
                            return (
                                <option key={index} value={condominium?.buildingId}>{condominium?.name}</option>
                            )
                        })}
                    </select>
                </div>
                {apartmentSelectedCondominiumId != 0
                    ? (apartments?.map((apartment, index) => {
                        return (
                            <div key={index} style={{ display: "flex", justifyContent: "space-evenly", marginBottom: "1rem" }}>
                                <input className="form-control" type="text" style={{ maxWidth: "14rem" }} placeholder="Floor"
                                    value={apartment.floor}
                                    onChange={(e) => {
                                        apartment.floor = e.target.value
                                        setApartments([...apartments]);
                                    }} />

                                <input className="form-control" type="text" style={{ maxWidth: "14rem" }} placeholder="Door Number"
                                    value={apartment.doorNumber}
                                    onChange={(e) => {
                                        apartment.doorNumber = e.target.value
                                        setApartments([...apartments]);
                                    }} />

                                <input className="form-control" type="text" style={{ maxWidth: "14rem" }} placeholder="Type"
                                    value={apartment.type}
                                    onChange={(e) => {
                                        apartment.type = e.target.value
                                        setApartments([...apartments]);
                                    }} />

                                <input className="form-control" type="text" style={{ maxWidth: "14rem" }}
                                    placeholder="Number of Bedrooms" value={apartment.bedrooms}
                                    onChange={(e) => {
                                        apartment.bedrooms = parseInt(e.target.value)
                                        setApartments([...apartments]);
                                    }} />

                                <input className="form-control" type="text" style={{ maxWidth: "14rem" }} placeholder="Location"
                                    value={apartment.location}
                                    onChange={(e) => {
                                        apartment.location = e.target.value
                                        setApartments([...apartments]);
                                    }} />

                                <input className="form-control" type="text" style={{ maxWidth: "14rem" }} placeholder="Square Feet"
                                    value={apartment.totalSqFt}
                                    onChange={(e) => {
                                        apartment.totalSqFt = parseFloat(e.target.value)
                                        setApartments([...apartments]);
                                    }} />

                                <AppButton style={{
                                    maxWidth: "4rem", justifyContent: "center",
                                    alignItems: "center", display: "flex", marginRight: "0.8rem"
                                }} onClick={() => {
                                    edit(apartment?.id, apartment?.floor, apartment?.doorNumber, apartment?.type,
                                        apartment?.bedrooms, apartment?.location, apartment?.totalSqFt)
                                }}>
                                    <FaSave />
                                </AppButton>
                            </div>
                        )
                    }))
                    : (<Fragment />)
                }
            </div>
        )
    }
}